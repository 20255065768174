
export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
      authority: process.env.REACT_APP_MSAL_TENANT_AUTHORITY_URI,
      redirectUri: process.env.REACT_APP_MSAL_LOGIN_REDIRECT_URI,
    },
    cache: {
        cacheLocation: process.env.REACT_APP_MSAL_CACHE_LOCATION,
        storeAuthStateInCookie: false,
    }
};
  
export const loginRequest = {
    scopes: process.env.REACT_APP_MSAL_CLIENT_SCOPE.split(","),
    forceRefresh: false,
    prompt: process.env.REACT_APP_MSAL_LOGIN_REQUEST_PROMPT,
};

export const apiRequest = {
    scopes: process.env.REACT_APP_MSAL_CLIENT_SCOPE.split(","),
    forceRefresh: false,
}