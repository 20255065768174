import React from 'react';
import { Button } from "reactstrap";

export function Layout(props) {

  const [ backToTopVisible, setBackToTopVisible ] = React.useState(false);

  const onScroll = (event) => setBackToTopVisible(window.scrollY > 0);
  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href)
    ) {
      document.getElementById(href).scrollIntoView();
    }
    
    document.addEventListener("scroll", onScroll);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.removeEventListener("scroll", onScroll)
    };
  });

  return (
    //  <MsalAuthenticationTemplate 
    //          interactionType={InteractionType.Silent} 
    //          authenticationRequest={loginRequest} 
    //          //errorComponent={ErrorComponent} 
    //          loadingComponent={Loader}
    //      > 
          <div className="wrapper">
            
              <Button
                className={`btn-icon-only back-to-top ${backToTopVisible ? "show" : "hide"} px-0`}
                color="primary"
                name="button"
                type="button"
                onClick={() => {
                  window.scrollTo(0, 0);
                  document.body.scrollTop = 0;
                }}
              >
                <i className="ni ni-bold-up"></i>
              </Button>
            {props.children}
          </div>  

    // </MsalAuthenticationTemplate>

  );
  
}
