/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from "../../../services/api";
import {
    Container, Row, Col, TabContent, TabPane, Card, Alert, Button
 } from "reactstrap"; 
import Loader from "../../loaders";
import { Interview, Profile, Recruitment, Skills, Requests, AllocationHistory, Flights, Identifiers, Events, InactiveDates, Documents } from "./Tabs";
import { v4 } from "uuid";
import jsonFormData from "json-form-data";
import { formatDateZeroTime, hexToRgb } from "../../../utils";
import EditorSMS from "./EditorSMS";
import EditorEmail from "./EditorEmail";
import ProjectAttributes from './Tabs/ProjectAttributes';
import Positions from './Tabs/Positions';
import DispatchHistory from './Tabs/DispatchHistory';
import SiteAccess from './Tabs/SiteAccess'
import { ErrorNotification, ErrorResponseValidation } from '../../alerts';
import TrainingHistory from './Tabs/TrainingHistory';
import { useParams } from 'react-router-dom';
import NavbarPage from '../../navbars/NavbarPage';
import TabList from './TabList';

function EditorPage(props) {
    const tabs = [...TabList];

    const [ itemInEdit, setItemInEdit ] = React.useState({});
    const [ loading, setLoading ] = React.useState(false);
    const [ activeTab, setActiveTab ] = React.useState(0);
    const [ footerStyle, setFooterStyle ] = useState({});
    const [ backgroundStyle, setBackgroundStyle ] = useState({});
    const [ titleStyle, setTitleStyle ] = useState({});
    const [ smsFromContact, setSmsFromContact ] = useState([]);
    const [ emailFromContact, setEmailFromContact ] = useState([]);
    const [ lookups, setLookups ] = useState(null);
    
    const [ emailInvalid, setEmailInvalid ] = React.useState(false);
    const {id} = useParams();
    const tab_id_template = "talent-tab-";

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Candidate Profile Error`);
    const [errorDetails, setErrorDetails] = React.useState(null);
    const [errorMessageClassSubfix, setErrorMessageClassSubfix] = useState("");

    const [getProfileData, setGetProfileData] = useState(false);
    const [closeOnSave, setCloseOnSave] = useState(false);

    const loadData = (id) => {
        setLoading(true);

        const apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/candidateeventstatus`),
            api.get(`/lookup/candidatecommenttype`),
            api.get(`/lookup/agency`),
            api.get(`candidate/${id}`),
            api.get(`/contractingcompany/all`)
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                setLookups({
                    skillPositions: responses[0].data,
                    candidateTypes: responses[1].data,
                    salutations: responses[2].data,
                    preferences: responses[3].data,
                    industries: responses[4].data,
                    genders: responses[5].data,
                    maritalStatuses: responses[6].data,
                    candidateStatuses: responses[7].data,
                    candidateFlags: responses[8].data,
                    residencyStatuses: responses[9].data,
                    visaTypes: responses[10].data,
                    communicationTypes: responses[11].data,
                    countries: responses[12].data,
                    states: responses[13].data,
                    relationshipTypes: responses[14].data,
                    candidatePlatformOrigin: responses[15].data,
                    messagesMaster: responses[16].data,
                    emailTemplate: responses[17].data.data,
                    recruitmentPeople: responses[18].data,
                    candidateDisciplines: responses[19].data,
                    companies: responses[20].data,
                    identifiers: responses[21].data,
                    events: responses[22].data,
                    eventStatus: responses[23].data,
                    candidateCommentTypes: responses[24].data,
                    agencies: responses[25].data,
                    contractingCompanies: responses[27].data
                });
                
                const {
                    candidateNotes,
                    ...rest
                } = responses[26].data;
                
                setItemInEdit({
                    ...rest,
                    candidateNotes: candidateNotes && candidateNotes.length > 0
                        ? candidateNotes.map((item) => ({ ...item, uid: v4() }))
                        : [],
                    candidateInactiveDates: [],
                    candidateDocuments: [],
                    candidateSkills: [],
                });
            })
            .catch((error) => {
                console.error(error);
                var errorMsg = ErrorResponseValidation(error);
                setErrorMessage(`${errorMessageOpening}${errorMsg}`);

            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!id)
            return;

        loadData(id);
    }, [id]);

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }
    
    const handleDateChanged = (value, name) => {
        setItemInEdit({ 
            ...itemInEdit, 
            [name]: !value ? null : formatDateZeroTime(value) //moment(value).format("YYYY-MM-DDT00:00:00") 
        });
    }

    const handleSave = (item, close = false) => {
        setLoading(true);

        try {

            // Validate email
            let emailFields = [];
            for (const [key] of Object.entries(item)) {
                if (key.includes("email")) {
                    emailFields.push(key)
                }
            }
            var validator = require("email-validator");
            var allEmailValid = true;
            for (let i = 0; i < emailFields.length; i++) {

                // Validate email address
                if (emailFields[i].toLowerCase() == "email" && !item[emailFields[i]]) {
                    setLoading(false);
                    setErrorTitle(errorTitleOnGenerate);
                    setErrorMessageOpening("");
                    setErrorMessage("Please input Email Address");
                    return;
                }
                else if (item[emailFields[i]] === "" || item[emailFields[i]] === null) {
                    continue;
                }
                else if (!validator.validate(item[emailFields[i]])) {

                    setEmailInvalid(true);
                    setLoading(false);
                    allEmailValid = false;
                    break;
                }
            }

            // Validate mobile phone number
            if (!item["mobilePhoneNumber"]) {
                setLoading(false);
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessageOpening("");
                setErrorMessage("Please input Mobile Phone Number");
                return;
            }


            // Validate inclusion and exclusion
            if (item.candidateClientInclusions && item.candidateClientExclusions &&
                item.candidateClientExclusions.some(w => item.candidateClientInclusions.some(w2=>w2.clientId == w.clientId))) {
                var errMessage = "Client Exclusion Preference can't contains item which already in Client Inclusion Preference";
                setLoading(false);
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessage(errMessage);
                return;
            }
        }
        catch (error) {
            setLoading(false);
            console.error(error, error.message);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            return;
        }

        if (allEmailValid) {
            try {
                var form = jsonFormData(item, {
                    initialFormData: new FormData(),
                    showLeafArrayIndexes: true,
                    includeNullValues: false,
                    mapping: function (value) {
                        return value;
                    }
                });
            }
            catch (error) {
                setLoading(false);
                console.error(error, error.message);
                setErrorMessage(`${errorMessageOpening}${error.message}`);
                return;
            }

            const formData = new FormData();

            form.forEach((value, key, parent) => {

                if (value instanceof File || value instanceof Blob) {
                    formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2").replace(/\[\d+\]$/, ""), value, value.name)
                }
                else {
                    formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2"), value)
                }
            })

            let url = `${process.env.REACT_APP_WEB_API_BASE_URI}/candidate`;
            let method = "POST";

            if (item.candidateId > 0) {
                url += `/${item.candidateId}`;
                method = "PUT";
            }

            const save = api({ url, method, data: formData })

            save
                .then((response) => {
                    setEmailInvalid(false);
                    setLoading(false);

                    if (close) {
                        props.onSaved();
                        return;
                    }

                    if (!close && !item.candidateId) {
                        const newId = response.data.candidateId;
                        loadData(newId);
                    }
                    else if (!close && item.candidateId) {
                        loadData(item.candidateId);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setEmailInvalid(false);
                    console.error({ error });

                    // Compile error message from server
                    var errorMsg = ErrorResponseValidation(error);
                    var part = errorMsg ? errorMsg.split('||') : [];

                    // If using error message from server
                    if (part.length >= 3) {
                        var errorMsgStr = part[0];
                        var errorType = part[1];
                        var errorData = part[2];

                        // Display error message
                        setErrorMessage(`${errorMessageOpening}${errorMsgStr}`);

                        // Error type contains email and contains error
                        if (errorType.includes("duplicate") && errorType.includes("error")) {
                            var errorDataObj = JSON.parse(errorData);
                            var errorDetails = <div>
                                <ol>
                                    {errorDataObj.map((item, index) => (
                                        <li key={`cd-${index}`}><a target="_blank" href={`talents/${item.CandidateId}`}><u>{item.CandidateName}</u></a></li>
                                    ))}
                                </ol>
                            </div>;
                            //setErrorMessageClassSubfix("text-left");
                            setErrorDetails(errorDetails);

                            return;
                        }
                        return;
                    }

                    // Display error message
                    setErrorMessage(`${errorMessageOpening}${errorMsg}`);
                    return;
                })
            ;
        }
    }

    useEffect(() => {
        if (!itemInEdit || !itemInEdit?.candidateTypeModel || !itemInEdit?.candidateTypeModel?.themeColor) {
            //setBorderStyle({});
            //setHeaderStyle({});
            setFooterStyle({});
            setBackgroundStyle({});
            setTitleStyle({});
            return;
        }


        const { candidateTypeModel } = itemInEdit;
        const { themeColor } = candidateTypeModel;
        const { r, g, b } = hexToRgb(themeColor);

        console.log((r + g + b) / 3);

        if ((r + g + b) / 3 <= 150)
            setTitleStyle({ color: "#FFF" });
        else
            setTitleStyle({});

        //setBorderStyle({ border: `2px solid ${themeColor}` });
        //setHeaderStyle({ backgroundColor: themeColor });
        setFooterStyle({ backgroundColor: themeColor });
        setBackgroundStyle({ backgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)` });
    }, [itemInEdit, itemInEdit?.candidateTypeModel, itemInEdit?.candidateTypeModel?.colorTheme]);

    //useEffect(() => {
    //    if (!itemInEdit || !itemInEdit.defaultProject || !itemInEdit.defaultProject.themeColor)
    //    {
    //        setFooterStyle({});
    //        setBackgroundStyle({});
    //        setTitleStyle({});
    //        return;
    //    }
        
    //    const {defaultProject} = itemInEdit;
    //    const {themeColor} = defaultProject;
    //    const {r, g, b} = hexToRgb(themeColor);

    //    if ((r + g + b) / 3 <= 150)
    //        setTitleStyle({color: "#FFF"});
    //    else
    //        setTitleStyle({});

    //    setFooterStyle({backgroundColor: themeColor});
    //    setBackgroundStyle({backgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)`});
    //}, [itemInEdit?.defaultProject]);

    useEffect(() => {
        if (!getProfileData)
            return;
        
        setGetProfileData(false);
        handleSave(itemInEdit, closeOnSave);
    }, [itemInEdit]);

    const tabProps = {
        itemInEdit, 
        setItemInEdit,
        handleTextChanged,
        handleDateChanged,
        lookups: lookups ?? {},
        isPage: true,
    };

    /*
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);
    */

    // BEGIN ERROR MESSAGE HANDLER --------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorDetails(null);
                setErrorMessage(null);
                setErrorTitle(null);
            },
            errorDetails: errorDetails,
            message: errorMessage,
            messageOpening: errorMessageOpening,
            title: errorTitle ?? errorTitleOnGenerate,
            messageClassSubfix: errorMessageClassSubfix
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle, errorDetails, errorMessageClassSubfix, errorMessageOpening, errorTitleOnGenerate]);
    // END ERROR MESSAGE HANDLER -----------------------------------------------------------------------------------------------------------------------

    return (
        <section className="main">
            <NavbarPage
                name='talent'
                onTabClick={(t) => {
                    setActiveTab(t.tabIndex);
                }}
                tabs={tabs}
            />
            <Container fluid className="d-flex flex-wrap justify-content-between align-items-center py-4">
                <span><h5><strong>{itemInEdit?.candidateName ?? ""}</strong></h5></span>
                <Button
                    color="info"
                    onClick={() => {
                        // handleSave(itemInEdit, false);
                        setCloseOnSave(false);
                        setGetProfileData(true);
                    }}
                    type="button"
                >
                    Save
                </Button>
            </Container>
            <Container fluid className="px-3" style={{overflowY: "auto", height: "74.5vh"}}>
                {(loading) && <Loader />}
                {errorNotification.length > 0 && errorNotification}
                {emailInvalid &&
                <Alert
                    color="warning" className="mb-0 d-flex mx-auto justify-content-between" 
                >
                    <ul className="mb-0 px-0 mx-auto">
                        Please check email(s) have been entered correctly
                    </ul>
                </Alert>
                }
                <Row className='h-100'>
                    <Col md={12}>
                        <div className="nav-wrapper pt-0 h-100 d-flex flex-column">
                            <Card className="card-plain no-transition">
                                <TabContent className="mt-2" activeTab={activeTab}>
                                    <TabPane tabId={0}> {/* Profile */}
                                        <Profile
                                            {...tabProps}
                                            setSmsFromContact={setSmsFromContact}
                                            setEmailFromContact={setEmailFromContact}
                                            isSaving={getProfileData}
                                        />
                                    </TabPane>
                                    <TabPane tabId={4}> {/* Career / Skills */}
                                        <Skills {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={5}> {/* Recruitment */}
                                        <Recruitment {...tabProps} /> 
                                    </TabPane>
                                    <TabPane tabId={6}> {/* Interview */}
                                        <Interview {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={8}> {/* Interview */}
                                        <Documents {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={9}> {/* Requests */}
                                        <Requests {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={10}> {/* Requests */}
                                        <ProjectAttributes {...tabProps} project={null} />
                                    </TabPane>
                                    <TabPane tabId={17}> {/* Requests */}
                                        <DispatchHistory {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={11}> {/* Allocation History */}
                                        <AllocationHistory {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={16}> {/* Flights */}
                                        <Flights {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={12}> {/* Identifiers */}
                                        <Identifiers {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={13}> {/* Events */}
                                        <Events {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={14}> {/* Inactive Dates */}
                                        <InactiveDates {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={15}> {/* Positions */}
                                        <Positions {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={18}> {/* Site Access */}
                                        <SiteAccess {...tabProps} />
                                    </TabPane>
                                    <TabPane tabId={19}> {/* Site Access */}
                                        <TrainingHistory {...tabProps} />
                                    </TabPane>
                                </TabContent>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        {
            smsFromContact?.length > 0 &&
            <EditorSMS
                items={smsFromContact} 
                onClose={() => setSmsFromContact([])}
                onSaved={() => setSmsFromContact([])}
                lookups={{
                    ...lookups
                }}
            />
        }
        {
            emailFromContact?.length > 0 &&
            <EditorEmail
                items={emailFromContact} 
                onClose={() => setEmailFromContact([])}
                onSaved={() => setEmailFromContact([])}
                lookups={{
                    ...lookups
                }}
            />
        }
        </section>
    )
}

export default EditorPage;
