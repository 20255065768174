const rules = {
    manager: {
        static: [
            "lrf:list",
            "lrf:edit",
            "lrf:create",
            "lrf:delete"
        ]
    },
    recruiter: {
        static: [
            "lrf:list",
            "lrf:edit",
            "lrf:create",
            "lrf:delete"
        ]
    },
    admin: {
        static: [
            "lrf:list",
            "lrf:edit",
            "lrf:create",
            "lrf:delete"
        ]
    }
}

export default rules;