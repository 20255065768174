/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
import React, { useEffect, useRef, useState, useMemo, useCallback, forwardRef, createContext, useContext, useReducer, Fragment } from 'react';
import api from "../../../services/api";
import { useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { requiresRoles } from "../../Can";
import 'ag-grid-enterprise';
import qs from "qs";
import moment from "moment";
import { CandidateEditor, PositionEditor, ScheduleCellEditor } from './editors';
import Loader from "../../loaders";
import _ from "lodash";
import ContextMenuHelper from "./helpers/ContextMenuHelper";
import CellEditHelper from "./helpers/CellEditHelper";
import { ConfigPanel, SetupPanel, LegendPanel, StatusPanelLeft, StatusPanelRight, ExportPanel } from "./panels";
import DateColumnTooltip from "./tooltips/DateColumnTooltip";
import FatigueTooltip from "./tooltips/FatigueTooltip";
import debounce from 'lodash.debounce';
import useRoster from "./contexts/RosterContext";
import PositionStepUpEditor from '../Roster/Editors/PositionStepUpEditor';
import CandidateDocumentsEditor from '../Roster/Editors/CandidateDocumentsEditor';
import RosterAttributeEditor from '../Roster/Editors/RosterAttributeEditor';
import TalentEditor from '../Candidates/Editor';
import EditorSMS from '../Candidates/EditorSMS';
import CandidateConfirmationEditor from "../Roster/Editors/CandidateConfirmationEditor";
import BulkAvailabilityConfirmation from '../Roster/Editors/BulkAvailabilityConfirmation';
import DispatchUnitEditor from '../Roster/Editors/DispatchUnitEditor';
import DeleteSlotDialog from './dialogs/DeleteSlotDialog';
import Contingency from './dialogs/Contingency';
import ConflictDialog from '../Roster/ConflictDialog';
import ImportDialog from '../Roster/ImportDialog';
import LogisticEditor from "../Roster/Editors/LogisticEditor";
import { ScheduleTypeValues, ScheduleTypeInvalid } from "./helpers/enums";
import { MakeWords } from "../../../helpers"
import {
    Col,
    Container,
    Modal,
    Row
} from "reactstrap";

import { BulkConfirmationMode, formatDate, KEY_BACKSPACE, KEY_DELETE, ViewAccess } from '../../../utils'
import { roles } from "../../../auth/roles";
import { useMsal } from "@azure/msal-react";

import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Slide } from "@progress/kendo-react-animation";

import "../../../assets/css/ag-grid.css";
import "../../../assets/css/ag-theme-alpine.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import { ErrorNotification } from '../../alerts';
import { excelStyles } from './helpers/util';
import { StringToBoolean } from '../../../helpers/StringHelper';
import CandidateDASEditor from '../Roster/Editors/CandidateDASEditor';
import BulkDASConfirmation from '../Roster/Editors/BulkDASConfirmation';
import CandidateTrainingDialog from './dialogs/CandidateTrainingDialog';
import StatusTooltip from './tooltips/StatusTooltip';
import ManualConfirmationEditor from './editors/ManualConfirmationEditor';
import CommunicationDialog from './dialogs/CommunicationDialog';
import AuditDialog from './dialogs/AuditDialog';
import CandidateSearchDialog from './dialogs/CandidateSearchDialog';
import SpvRatingDialog from './dialogs/SpvRating';
import SupervisorFeedbackDialog from './dialogs/SpvRating/SupervisorFeedbackDialog';
import AddSlotDialog from './dialogs/AddSlotDialog';
import RosterCandidateNoteEditor from '../Roster/Editors/RosterCandidateNoteEditor';
import ComboboxEditor from '../../ag-grid/ComboboxEditor';
import ImportAccommodationFlightDialog from '../Roster/ImportAccommodationFlight/ImportAccommodationFlightDialog';
import PrepopulateWorkSequence from './dialogs/PrepopulateWorkSequence';
import ImportRosterSheetDialog from './dialogs/ImportRosterSheetDialog';


export default function Grid(props) {

    const {
        dispatchUnit,
        startDate,
        endDate,
        trimStartDate,
        trimEndDate,
        project,
        setDataLoaded,
        dataLoaded,
        setDataExists,
        dataExists,
        dispatchEditorOpen,
        setDispatchEditorOpen,
        setDispatchUnit,
        notification,
        setNotification,
        setStartDate,
        setEndDate,
        setContingencyOpen,
        contingencyOpen,
    } = useRoster();

    const location = useLocation();
    const {
        projectid,
        dispatchunitid,
        rosterid,
        //startdatedefault,
        start: startDate_FromParam,
        end: endDate_FromParam,
    } = qs.parse(location.search, { ignoreQueryPrefix: true });

    //console.log('projectid', projectid);
    //console.log('startdatedefault', startdatedefault);
    //console.log('enddatedefault', enddatedefault);
    //console.log('trimstartdatedefault', trimstartdatedefault);
    //console.log('trimenddatedefault', trimenddatedefault);

    var startDateDefault_Date = !startDate_FromParam ? null : moment(startDate_FromParam, 'YYYY-MM-DD').toDate();
    var endDateDefault_Date = !endDate_FromParam ? null : moment(endDate_FromParam, 'YYYY-MM-DD').toDate();

    const [disableTrimStartDateDefault, setDisableTrimStartDateDefault] = useState(null);
    const [disableTrimEndDateDefault, setDisableTrimEndDateDefault] = useState(null);

    const [loading, setLoading] = React.useState(false);
    const [loadingSchedule, setLoadingSchedule] = React.useState(false);
    const [lookups, setLookups] = useState({});
    // const [ sortActive, setSortActive ] = useState(false);
    // const [ filterActive, setFilterActive ] = useState(false);

    const gridRef = useRef();
    const [rowData, setRowData] = useState(null);
    const [columnDefs, setColumnDefs] = useState();

    const [stepUpInEdit, setStepUpInEdit] = useState(null);
    const [candidateContractInEdit, setCandidateContractInEdit] = useState(null);
    const [rosterAttributeInEdit, setRosterAttributeInEdit] = useState(null);
    const [candidateInEdit, setCandidateInEdit] = useState(null);
    const [logisticInEdit, setLogisticInEdit] = useState(null);

    const [candidateMessageList, setCandidateMessageList] = useState(null);

    //const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [confirmationMessageTemplateForAvailability, setConfirmationMessageTemplateForAvailability] = useState(null);
    const [confirmationMessageTemplateForLogistic, setConfirmationMessageTemplateForLogistic] = useState(null);
    const [confirmationMessageTemplateForDAS, setConfirmationMessageTemplateForDAS] = useState(null);

    const [candidateConfirmationList, setCandidateConfirmationList] = useState(null);
    const [candidateBulkLogisticList, setCandidateBulkLogisticList] = useState(null);
    const [candidateBulkAvailabilityList, setCandidateBulkAvailabilityList] = useState(null);
    const [candidateDASList, setCandidateDASList] = useState(null);
    const [candidateBulkDASList, setCandidateBulkDASList] = useState(null);

    const [deletedData, setDeletedData] = useState(null);
    const [checkConflict, setCheckConflict] = useState(null);

    const [openRosterImport, setOpenRosterImport] = useState(false);
    const [openAccommodationFlightImport, setOpenAccommodationFlightImport] = useState(false);
    const [openRosterSheetImport, setOpenRosterSheetImport] = useState(false);

    //const [bulkAvailabilityConfirmationIsOpen, setBulkAvailabilityConfirmationIsOpen] = React.useState(false);
    //const [bulkConfirmationMode, setBulkConfirmationMode] = React.useState(BulkConfirmationMode.AVAILABILITY);

    const [suppressColumnVirtualisation, setSuppressColumnVirtualisation] = useState(false);
    const [suppressRowVirtualisation, setSuppressRowVirtualisation] = useState(false);
    const [rowBuffer, setRowBuffer] = useState(20);
    const [debounceVerticalScrollbar, setDebounceVerticalScrollbar] = useState(false);
    const [confirmCandidateChange, setConfirmCandidateChange] = useState(null);
    const [trainingData, setTrainingData] = useState(null);
    const [candidateManualConfirmationList, setCandidateManualConfirmationList] = useState(null);
    const [rosterPositionAlignmentConfirmation, setRosterPositionAlignmentConfirmation] = useState(null);
    const [showMessageHistory, setShowMessageHistory] = useState(null);
    const [showAuditHistory, setShowAuditHistory] = useState(null);
    const [showTalentsSearch, setShowTalentsSearch] = useState(null);
    const [showPrepopulateWorkSequence, setShowPrepopulateWorkSequence] = useState(null);
    const [showCopyConfirmation, setShowCopyConfirmation] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(null);
    const [slotCountObj, setSlotCountObj] = useState(null);
    const [rosterCandidateNoteParam, setRosterCandidateNoteParam] = useState(null);
    // const [selectedProjectId, setSelectedProjectId] = useState(project?.projectId ?? null);
    // const [selectedDispatchUnitId, setSelectedDispatchUnitId] = useState(dispatchUnit?.lookupId ?? null);

    // Grid states 
    const [selectedCandidateNames, setSelectedCandidateNames] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);


    const [showSpvRatingRequest, setShowSpvRatingRequest] = useState(false);
    const [showSpvFeedbackReport, setShowSpvFeedbackReport] = useState(false);
    

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Roster Error`);


    // Access Right 
    const [viewAccess, setViewAccess] = React.useState(ViewAccess.FULLACCESS);
    const { instance } = useMsal();
    const user = instance.getActiveAccount();
    const isEditable = useMemo(() => { return viewAccess == ViewAccess.READONLY ? false : true }, [viewAccess]);
    const isReadonly = useMemo(() => { return viewAccess == ViewAccess.READONLY ? true : false }, [viewAccess]);
    const isManager = _.intersection([roles.Manager], user.idTokenClaims.roles).length > 0 && user.idTokenClaims.roles?.length === 1;
    const editableCellClass = useMemo(() => { return viewAccess == ViewAccess.READONLY ? `roster-cell` : `editable-cell roster-cell` }, [viewAccess]);
    const getContentCellClass = (content) => {
        if (!content) {
            return "grid-empty-roster";
        }
        return "";
    }

    //const [immutableStore, setImmutableStore] = useState(null)

    //const [shifts, setShifts] = useState([]);
    //useEffect(() => {
    //    api.get(`/lookup/shift`)
    //        .then(({ data }) => setShifts(data))
    //        .catch((error) => console.log(error.response));
    //}, []);
    const {
        shifts,
        siteTravelTypes,
        pointOfHires,
    } = props;
    // console.log('props', {props, shifts, siteTravelTypes, pointOfHires});

    // useEffect(() => {
    //     setSelectedProjectId(project?.projectId ?? null);
    // }, [project]);

    // useEffect(() => {
    //     setSelectedDispatchUnitId(dispatchUnit?.lookupId ?? null);
    // }, [dispatchUnit]);

    // useEffect(() => {
    //     console.log('viewAccess', viewAccess);
    // }, [viewAccess])

    useEffect(() => {

        // console.log('Check SPC Readonly');
        // console.log('user', user);
        requiresRoles([roles.Spc], user, () => {
            // console.log('Allowed SPC Readonly');
            setViewAccess(ViewAccess.READONLY);
        });

        //setLoading(true);
        // const queryObj = qs.parse(location.search, { ignoreQueryPrefix: true });
        // const projectId = queryObj.projectid;
        // //const workorderId = qs.parse(location.search, { ignoreQueryPrefix: true }).workorderid;
        // const dispatchUnitId = queryObj.dispatchunitid;
        // const rosterId = queryObj.rosterid;
        // // console.log('first load 0', { projectId, dispatchUnitId, rosterId })

        const apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/rosterflighttype`),
            api.get(`/lookup/flightstatus`),
            api.get(`/lookup/accommodationstatus`),
            api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Availability" }] }, { allowDots: true })}`),
            api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Accommodation" }] }, { allowDots: true })}`),
            api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "DAS" }] }, { allowDots: true })}`),
            api.get(`/lookup/RosterApprovalStatus`),
            api.get(`/lookup/WorkSequence`),
            api.get(`/lookup/agency`),
            api.get(`/contractingcompany/all`),
            api.get(`/lookup/candidatecommenttype`),
            api.get(`/lookup/candidateeventstatus`),
        ];

        // if (projectId) {
        //     apiCalls.push(api.get(`/project/${projectId}`));
        // }
        // if (dispatchUnitId) {
        //     apiCalls.push(api.get(`/dispatchunit/id/${dispatchUnitId}`));
        // }
        // if (rosterId) {
        //     apiCalls.push(api.get(`/roster/id/${rosterId}`));
        // }

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    skillPositions: data[0].data,
                    candidateTypes: data[1].data,
                    salutations: data[2].data,
                    preferences: data[3].data,
                    industries: data[4].data,
                    genders: data[5].data,
                    maritalStatuses: data[6].data,
                    candidateStatuses: data[7].data,
                    candidateFlags: data[8].data,
                    residencyStatuses: data[9].data,
                    visaTypes: data[10].data,
                    communicationTypes: data[11].data,
                    countries: data[12].data,
                    states: data[13].data,
                    relationshipTypes: data[14].data,
                    candidatePlatformOrigin: data[15].data,
                    messagesMaster: data[16].data,
                    emailTemplate: data[17].data.data,
                    recruitmentPeople: data[18].data,
                    candidateDisciplines: data[19].data,
                    companies: data[20].data,
                    identifiers: data[21].data,
                    events: data[22].data,
                    rosterFlightTypes: data[23].data,
                    rosterFlightStatuses: data[24].data,
                    rosterAccommodationStatuses: data[25].data,
                    rosterApprovalStatus: data[29].data,
                    workSequence: data[30].data,
                    agencies: data[31].data,
                    contractingCompanies: data[32].data,
                    candidateCommentTypes: data[33].data,
                    eventStatus: data[34].data,
                });

                setConfirmationMessageTemplateForAvailability(data[26].data.data[0]);
                setConfirmationMessageTemplateForLogistic(data[27].data.data[0]);
                setConfirmationMessageTemplateForDAS(data[28].data.data[0]);
                //setConfirmationMessage(data[26].data.data[0]);

                // if (projectId) {
                //     setSelectedProject(data[29].data);
                // }

                // if (dispatchUnitId) {
                //     setDispatchUnit({
                //         lookupId: data[30].data.dispatchUnitId,
                //         lookupValue: data[30].data.dispatchUnitName,
                //         lookupLabel: data[30].data.quoteNumber,
                //         locationName: data[30].data.location?.lookupValue
                //     });
                // }

                // if (rosterId) {
                //     setRoster({
                //         lookupId: data[31].data.rosterId,
                //         lookupValue: data[31].data.rosterName,
                //         lookupLabel: data[31].data.rosterName
                //     });
                // }
            }).catch((error) => {
                console.error(error.response);
            }).finally(() => {
                //setLoading(false);
            });
    }, []);

    // use memo get selected candidates here
    //const selectedCandidates = useMemo(() => {
    //    if (!candidates)
    //        return [];

    //    return candidates.filter((candidate) => candidate.selected);
    //}, [candidates]);

    // use effect selectedCandidateNames console log
    // useEffect(() => {
    //     console.log('selectedCandidateNames', selectedCandidateNames);
    // }, [selectedCandidateNames]);

    // console log use effect selectedRows
    // useEffect(() => {
    //     console.log('selectedRows', selectedRows);
    // }, [selectedRows]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        cellClass: 'roster-cell',
        tooltipComponent: DateColumnTooltip,
        autoHeight: false,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
        menuTabs: ['filterMenuTab'],
        suppressPaste: true,
        suppressFillHandle: true,
    }), []);

    useEffect(() => {
        // console.log({confirmCandidateChange});

        if (!confirmCandidateChange?.confirmed)
            return;

        CellEditHelper.setField("candidateName").update({ ...confirmCandidateChange.event }, null, { dispatchUnit, startDate, endDate, trimStartDate, trimEndDate, project });
        setConfirmCandidateChange(null);
    }, [confirmCandidateChange]);

    // useEffect(() => {
    //     console.log('candidateContractInEdit', candidateContractInEdit);
    // }, [candidateContractInEdit]);

    const staticColumnDefs = useMemo(() => {
        const result = [
            {
                headerName: "",
                children: [
                    {
                        field: "slot",
                        headerName: "Slot",
                        width: 55,
                        pinned: true,
                        sort: "asc",
                        sortable: true,
                        filter: false,
                        suppressMenu: true,
                        editable: false,
                    },
                    {
                        field: "candidateName",
                        headerName: "Employee Name",
                        width: 150,
                        cellEditor: CandidateEditor,
                        cellEditorPopup: true,
                        editable: isEditable,
                        pinned: true,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.candidateName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        suppressKeyboardEvent: (params) => {
                            if (params.editing)
                                return false;

                            if (isReadonly)
                                return true;

                            const isBackspaceKey = params.event.keyCode === 8;
                            const isDeleteKey = params.event.keyCode === 46;

                            if (isBackspaceKey || isDeleteKey) {
                                CellEditHelper.setField("candidateName - check candidate change").get(params, null, null, (data) => {
                                    setConfirmCandidateChange({ event: { ...params }, confirmed: data.messageLogs?.length > 0 || data.rosterAttributes?.length > 0 ? false : true, rosterCandidate: params.data, messageLogs: data.messageLogs ? [...data.messageLogs] : null, rosterAttributes: data.rosterAttributes ? [...data.rosterAttributes] : null });
                                });
                                return true;
                            }

                            return false;
                        },
                    },
                    {
                        field: "hrispositionMasterName",
                        headerName: "Roster Position",
                        width: 200,
                        cellEditor: PositionEditor,
                        cellEditorPopup: true,
                        editable: isEditable,
                        //cellClass: editableCellClass,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.hrispositionMasterName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        sortable: true,
                    },
                    {
                        field: "positionName",
                        headerName: "Employed Position",
                        width: 200,
                        sortable: true,
                        columnGroupShow: 'closed',
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.positionName);
                            return `roster-cell ${contentCellClass}`;
                        },
                    },
                    {
                        field: "candidateTypeName",
                        headerName: "Candidate Type",
                        width: 150,
                        sortable: true,
                        columnGroupShow: 'closed',
                    },
                    {
                        field: "shiftName",
                        headerName: "Shift",
                        width: 70,
                        cellEditor: ComboboxEditor,
                        cellEditorParams: {
                            keyField: "shiftId",
                            textField: "shiftName",
                            dataKeyField: "lookupId",
                            dataTextField: "lookupValue",
                            className: "w-100",
                            endpoint: "/lookup/shift",
                        },
                        cellEditorPopup: true,
                        editable: isEditable,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.shiftName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        sortable: true,
                    },
                    {
                        field: "siteTravelTypeName",
                        headerName: "Site Travel Type",
                        width: 100,
                        cellEditor: ComboboxEditor,
                        cellEditorParams: {
                            keyField: "siteTravelTypeId",
                            textField: "siteTravelTypeName",
                            dataKeyField: "lookupId",
                            dataTextField: "lookupValue",
                            className: "w-100",
                            endpoint: "/lookup/sitetraveltype",
                        },
                        cellEditorPopup: true,
                        editable: isEditable,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.siteTravelTypeName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        sortable: true,
                        filter: false,
                        suppressMenu: true,
                    },
                    {
                        field: "pointOfHireName",
                        headerName: "Point of Hire",
                        width: 90,
                        cellEditor: ComboboxEditor,
                        cellEditorParams: {
                            keyField: "pointOfHireId",
                            textField: "pointOfHireName",
                            dataKeyField: "lookupId",
                            dataTextField: "lookupValue",
                            className: "w-100",
                            endpoint: "/lookup/pointofhire",
                        },
                        cellEditorPopup: true,
                        editable: isEditable,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.pointOfHireName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        sortable: true,
                        filter: false,
                        suppressMenu: true,
                    },
                    {
                        field: "accommodations",
                        headerName: "Accommodations",
                        width: 180,
                        editable: false,
                        cellClass: (params) => `roster-cell ${getContentCellClass(params.data?.accommodations)}`,
                        sortable: false,
                        filter: false,
                        suppressMenu: true,
                    },
                    // {
                    //     field: "shiftName",
                    //     headerName: "Shift",
                    //     width: 70,
                    //     cellEditor: "agRichSelectCellEditor",
                    //     cellEditorPopup: true,
                    //     editable: isEditable,
                    //     cellEditorParams: {
                    //         values: [...shifts],
                    //         formatValue: value => typeof value === "object" ? value?.lookupValue : value,
                    //         filterList: true,
                    //         highlightMatch: true,
                    //     },
                    //     cellClass: editableCellClass,
                    //     sortable: true,
                    //     columnGroupShow: 'closed',
                    // },
                    // {
                    //     field: "siteTravelTypeName",
                    //     headerName: "Site Travel Type",
                    //     width: 70,
                    //     cellEditor: "agRichSelectCellEditor",
                    //     cellEditorPopup: true,
                    //     editable: isEditable,
                    //     cellEditorParams: {
                    //         values: lookups?.siteTravelTypes ?? [],
                    //         formatValue: value => typeof value === "object" ? value?.lookupValue : value,
                    //         allowTyping: true,
                    //         filterList: true,
                    //         highlightMatch: true,
                    //         valueListMaxHeight: 220,
                    //     },
                    //     cellClass: editableCellClass,
                    //     sortable: true,
                    //     columnGroupShow: 'closed',
                    // },
                    // {
                    //     field: "pointOfHireName",
                    //     headerName: "Point of Hire",
                    //     width: 70,
                    //     cellEditor: "agRichSelectCellEditor",
                    //     cellEditorPopup: true,
                    //     editable: isEditable,
                    //     cellEditorParams: {
                    //         values: lookups?.pointOfHires ?? [],
                    //         formatValue: value => typeof value === "object" ? value?.lookupValue : value,
                    //         allowTyping: true,
                    //         filterList: true,
                    //         highlightMatch: true,
                    //         valueListMaxHeight: 220,
                    //     },
                    //     cellClass: editableCellClass,
                    //     sortable: true,
                    //     columnGroupShow: 'closed',
                    // },
                    // { 
                    //     field: "confirmationStatusName", 
                    //     headerName: "Confirmation Status", 
                    //     width: 120,
                    //     onCellClicked: (ev) => {
                    //         console.log(ev)

                    //         if (ev.data.confirmationStatusName == "LRF Created") {
                    //             window.open(`/lrf/${ev.data.recruitmentRequestId}`, '_blank')
                    //             //window.alert(`Open LRF ${ev.data.recruitmentRequestId}`);
                    //         } else if (ev.data.confirmationStatusName == "Job Order Created") {
                    //             window.open(`/joborder/${ev.data.recruitmentRequestId}`, '_blank')
                    //             //window.alert(`Open JO ${ev.data.recruitmentRequestId}`);
                    //         }
                    //     },
                    //     cellClass: (params) => {
                    //         if (params.value == "LRF Created" || params.value == "Job Order Created") {
                    //             return `roster-cell status-link cursor-pointer`;
                    //         }
                    //         return 'roster-cell';
                    //     },
                    //     sortable: true,
                    // },
                    {
                        field: "availabilityStatusName",
                        headerName: "Availability Status",
                        width: 120,
                        sortable: true,
                        editable: false,
                        cellClass: (params) => {
                            return `roster-cell ${params.data?.availabilityStatusClassName ?? ""}`;
                        },
                        tooltipComponent: (props) => props.node.data.availabilityStatusName ? StatusTooltip({ ...props, statusType: "availability" }) : null,
                        tooltipField: `availabilityStatusName`,
                    },
                    {
                        field: "logisticStatusName",
                        headerName: "Mobilisation Status",
                        width: 120,
                        sortable: true,
                        editable: false,
                        cellClass: (params) => {
                            return `roster-cell ${params.data?.logisticStatusClassName ?? ""}`;
                        },
                        tooltipComponent: (props) => props.node.data.availabilityStatusName ? StatusTooltip({ ...props, statusType: "accommodation" }) : null,
                        tooltipField: `logisticStatusName`,
                    },
                    {
                        field: "dasstatusName",
                        headerName: "DAS Status",
                        width: 120,
                        sortable: true,
                        editable: false,
                        cellClass: (params) => {
                            return `roster-cell ${params.data?.dasstatusClassName ?? ""}`;
                        },
                        tooltipComponent: (props) => props.node.data.availabilityStatusName ? StatusTooltip({ ...props, statusType: "das" }) : null,
                        tooltipField: `dasstatusName`,
                    },
                    {
                        field: "recruitmentStatusName",
                        headerName: "Recruitment Status",
                        width: 120,
                        sortable: true,
                        editable: false,
                        onCellClicked: (ev) => {
                            console.log(ev)

                            if (ev.data.recruitmentStatusName == "LRF Created") {
                                window.open(`/lrf/${ev.data.recruitmentRequestId}`, '_blank')
                                //window.alert(`Open LRF ${ev.data.recruitmentRequestId}`);
                            } else if (ev.data.recruitmentStatusName == "Job Order Created") {
                                window.open(`/joborder/${ev.data.recruitmentRequestId}`, '_blank')
                                //window.alert(`Open JO ${ev.data.recruitmentRequestId}`);
                            }
                        },
                        cellClass: (params) => {
                            const additionalClassName = params.data?.recruitmentStatusClassName ? ` ${params.data?.recruitmentStatusClassName}` : "";
                            if (params.value == "LRF Created" || params.value == "Job Order Created") {
                                return `roster-cell status-link cursor-pointer${additionalClassName}`;
                            }
                            return `roster-cell${additionalClassName}`;
                        },
                        sortable: true,
                        columnGroupShow: 'closed',
                        tooltipComponent: (props) => props.node.data.availabilityStatusName ? StatusTooltip({ ...props, statusType: "recruitment" }) : null,
                        tooltipField: `recruitmentStatusName`,
                    },
                    {
                        field: "rosterCandidateCompanyName",
                        headerName: "Entity",
                        width: 150,
                        sortable: true,
                        cellClass: (params) => {
                            if (params.data.rosterCompanyId !== params.data.rosterCandidateCompanyId) {
                                return `roster-cell text-danger`;
                            }
                            return 'roster-cell'
                        },
                        columnGroupShow: 'closed',
                    },
                    {
                        field: "overlapStatus",
                        headerName: "Overlap Status",
                        width: 80,
                        editable: false,
                        valueFormatter: () => '',
                        onCellClicked: (ev) => {
                            if (ev.data.candidateId && !ev.data.overlapStatus) {
                                // window.alert(`Open conflicts dialog for candidate ${ev.data.candidateId}`);

                                var slotDates = Object.keys(ev.data.dates);
                                const startDate = moment(slotDates[0], "YYYYMMDD").format("YYYY-MM-DD");
                                const endDate = moment(slotDates[slotDates.length - 1], "YYYYMMDD").format("YYYY-MM-DD");

                                setCheckConflict({
                                    rosterCandidateId: ev.data.rosterCandidateId,
                                    rosterCandidate: ev.data,
                                    candidateId: ev.data.candidateId,
                                    startDate,
                                    endDate
                                });
                            }
                        },
                        sortable: true,
                        cellClass: (params) => {
                            if (params.data.candidateId) {
                                return `roster-cell text-center fas ${params.value ? 'fa-check-circle text-success' : 'fa-times-circle text-danger cursor-pointer link'}`;
                            }
                            return 'roster-cell'
                        },
                        columnGroupShow: 'closed',
                    },
                    {
                        field: "fatigueFlag",
                        headerName: "Fatigue Flag",
                        width: 150,
                        sortable: true,
                        tooltipComponent: FatigueTooltip,
                        tooltipField: `fatigueDetails`,
                        cellClass: (params) => {
                            var fatigueStatus = params.data.fatigueFlag;
                            if (!fatigueStatus) {
                                return `roster-cell`;
                            }
                            else if (fatigueStatus.toLowerCase().includes('breach of fatigue')) {
                                return 'roster-cell bg-danger text-white';
                            }
                            else if (fatigueStatus.toLowerCase().includes('no breach')) {
                                return 'roster-cell bg-green-light text-dark';
                            }
                            else if (fatigueStatus.toLowerCase().includes('review required')) {
                                return 'roster-cell bg-yellow text-dark';
                            }
                            return `roster-cell`;
                        },
                        columnGroupShow: 'closed',
                    },
                    {
                        field: "note",
                        headerName: "Notes",
                        width: 40,
                        onCellClicked: (ev) => {
                            console.log({ev, viewAccess});
                            if (viewAccess === ViewAccess.READONLY)
                                return;
                            
                            setRosterCandidateNoteParam(ev.data);
                        },
                        valueFormatter: () => '',
                        cellClass: (params) => `roster-cell ${params.value && `${viewAccess !== ViewAccess.READONLY && "cursor-pointer "}link fas fa-comment text-center${viewAccess === ViewAccess.READONLY ? " text-grey" : ""}`}`,
                        sortable: true,
                        filter: false,
                        suppressMenu: true,
                        columnGroupShow: 'closed',
                    },
                ]
            },
        ];

        if (!isManager)
        {
            const insertedIndex = result[0].children.indexOf(result[0].children.filter(c => c.field === "candidateName")[0]);
            result[0].children.splice(insertedIndex, 0, {
                field: "info",
                headerName: "Info",
                width: 40,
                onCellClicked: (ev) => {
                    if (ev.data.candidateId && viewAccess !== ViewAccess.READONLY) {
                        //window.alert(`Open candidate profile dialog for candidate ${ev.data.candidateId}`);
                        setCandidateInEdit({ candidateId: ev.data.candidateId });
                    }
                },
                valueFormatter: () => '',
                cellClass: (params) => `roster-cell ${params.value && `${viewAccess !== ViewAccess.READONLY && "cursor-pointer "}link fas fa-info-circle text-center${viewAccess === ViewAccess.READONLY ? " text-grey" : ""}`}`,
                sortable: true,
                filter: false,
                suppressMenu: true,
                columnGroupShow: 'closed',
            });
        }

        return result;
    }, [shifts, CellEditHelper, viewAccess, isManager]);

    const loadSchedule = useCallback(query => {
        if (loadingSchedule)
            return;
        
        setLoadingSchedule(true);
    
        if (!loading)
            setLoading(true);

        gridRef.current.api.showLoadingOverlay();
        gridRef.current.api.clearRangeSelection();

        // console.log('loadSchedule');
        // console.log('query', query);

        const queryString = qs.stringify(query, { allowDots: true });

        api.get(`/rostergrid?${queryString}`)
            .then((response) => {

                try {
                    //console.log('response', response)
                    const {
                        schedules,
                        filterStartDate,
                        filterEndDate,
                        dispatchUnit
                    } = response.data;

                    if (schedules && schedules.length == 0) {
                        setColumnDefs([...staticColumnDefs]);
                        setRowData([]);
                        setLoading(false);
                        setLoadingSchedule(false);
                        return;
                    }

                    const dateColumns = [];

                    // console.log('response.data', response.data);

                    const start = moment(filterStartDate, 'YYYY-MM-DD');
                    const end = moment(filterEndDate, 'YYYY-MM-DD');
                    setStartDate(new Date(start.year(), start.month(), start.date()));
                    setEndDate(new Date(end.year(), end.month(), end.date()));
                    //setStartDate(new Date(start.year(), '01', '01'));
                    //setEndDate(new Date(end.year(), '01', '01'));

                    let current = moment(start);

                    while (current <= end) {
                        dateColumns.push(moment(current));
                        current.add(1, 'd');
                    }

                    //console.log('dateColumns', dateColumns)
                    setColumnDefs([
                        ...staticColumnDefs,
                        ...dateColumns.map((m) => ({
                            headerName: m.format('ddd'),
                            marryChildren: true,
                            children: [
                                {
                                    field: `dates.${m.format('YYYYMMDD')}`,
                                    tooltipField: `dates.${m.format('YYYYMMDD')}`,
                                    headerName: m.format('DD/MM/YYYY'),
                                    autoHeight: false,
                                    resizable: false,
                                    width: 30,
                                    editable: isEditable,
                                    headerClass: 'date-header',
                                    cellEditor: ScheduleCellEditor,
                                    cellEditorPopup: true,
                                    suppressMovable: true,
                                    lockPosition: 'right',
                                    filter: false,
                                    suppressMenu: true,
                                    suppressPaste: isReadonly,
                                    suppressFillHandle: isReadonly,
                                    suppressKeyboardEvent: isReadonly,
                                    valueFormatter: (params) => {
                                        return '';
                                    },
                                    valueSetter: (params) => {
                                        params.data.name = params.newValue;
                                        return true;
                                    },
                                    cellClass: (params) => {
                                        return params.value && params.value.value ? `date-cell roster-icon ${params.value?.className} amount amount${params.value?.value.length}` : 'date-cell';
                                    },
                                }
                            ]
                        }))
                    ]);

                    //console.log('schedules', {schedules, gridRef, dispatchUnit})
                    setRowData(schedules);

                    gridRef.current.api.closeToolPanel();


                    var elInfo = document.querySelector("#du-info");
                    elInfo.innerHTML = `${dispatchUnit.projectName} | ${dispatchUnit.dispatchUnitName} | ${dispatchUnit.quoteNumber} | ${dispatchUnit.locationName}`;

                    const dateExists = schedules.filter(s => {
                        if (!s.dates)
                            return false;

                        if (!Object.keys(s.dates).length)
                            return false;

                        return true;
                    }).length > 0;

                    setDisableTrimStartDateDefault(!dateExists);
                    setDisableTrimEndDateDefault(!dateExists);
                    setLoading(false);
                    setLoadingSchedule(false);
                }
                catch (error) {
                    setRowData(null);
                    console.error(error, error.message);

                    setErrorMessage(`${errorMessageOpening}${error.message}`);
                    setErrorTitle(errorTitleOnGenerate);

                    setLoading(false);
                    setLoadingSchedule(false);
                }
            }).catch((error) => {
                setRowData(null);
                console.error(error, error.response);

                setErrorMessage(`${errorMessageOpening}${error.response}`);
                setErrorTitle(errorTitleOnGenerate);

                setLoading(false);
                setLoadingSchedule(false);
            }).finally(() => {

            });

    }, [loadingSchedule, loading, staticColumnDefs, isManager]);

    const autoSelectResources = useCallback((gridApi) => {
        setLoading(true);
        const gridModel = gridApi.getModel();
        const filters = [];
        const exclude = [];
        gridModel.forEachNode((rowNode, index) => {

            if (
                rowNode.data.candidateId == null &&
                rowNode.data.requestDetailId == null &&
                rowNode.data.startDate &&
                rowNode.data.endDate &&
                parseInt(moment(rowNode.data.startDate, "YYYY-MM-DD").format("YYYY")) > 1900 &&
                parseInt(moment(rowNode.data.endDate, "YYYY-MM-DD").format("YYYY")) > 1900
            ) {
                filters.push(
                    {
                        projectId: rowNode.data.projectId,
                        dispatchUnitId: rowNode.data.dispatchUnitId,
                        rosterId: rowNode.data.rosterId,
                        positionId: rowNode.data.hrispositionMasterId,
                        slotNo: rowNode.data.slot,
                        index: [index],
                        startDate: rowNode.data.startDate,
                        endDate: rowNode.data.endDate,
                    }
                )
            }
            else if (rowNode.data.candidateId != null) {
                exclude.push(rowNode.data.candidateId);
            }

        });



        if (filters.length > 0) {
            api.post(`/rosterwizard/roster-talents`, filters.map(m => ({ ...m, exclude })))
                .then((response) => {
                    if (response.data && response.data.length) {

                        const apiCalls = [];

                        response.data.forEach((value) => {
                            const [index] = value.index;
                            const rowNode = gridApi.getDisplayedRowAtIndex(index);

                            const queryString = qs.stringify({
                                startDate: trimStartDate ? undefined : formatDate(startDate, 'YYYY-MM-DD'),
                                endDate: trimEndDate ? undefined : formatDate(endDate, 'YYYY-MM-DD'),
                            }, { allowDots: true });

                            apiCalls.push(api.post(`/rostergrid/rostercandidate/${rowNode.data.rosterCandidateId}/candidate/${value.candidate.candidateId}?${queryString}`));
                        });

                        Promise.all(apiCalls).then((allResponses) => {
                            const update = [];

                            allResponses.forEach((r) => {
                                update.push({ ...r.data[0] })
                            });

                            const tx = {
                                update
                            };

                            gridApi.applyTransaction(tx);
                        })
                            .catch((error) => {
                                // TODO: Display Error message in UI
                                console.log(error.response)
                            })
                            .finally(() => setLoading(false))
                            ;
                    }
                    else
                        setLoading(false);
                })
                .catch((error) => {
                    console.log(error.response);
                    setLoading(false);
                })
        }
        else
            setLoading(false);
    }, [gridRef.current])

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'setupPanel',
                    labelDefault: 'Setup',
                    labelKey: 'setupPanel',
                    iconKey: 'fa-gear',
                    toolPanel: SetupPanel,
                    width: 280,
                    toolPanelParams: {
                        today: new Date(),
                        loadSchedule,
                        autoSelectResources,
                        setLoading,
                        projectid,
                        dispatchunitid,
                        viewAccess,
                        startDateDefault: startDateDefault_Date,
                        endDateDefault: endDateDefault_Date,
                        disableTrimStartDateDefault: disableTrimStartDateDefault,
                        disableTrimEndDateDefault: disableTrimEndDateDefault,
                        setShowTalentsSearch,
                        setShowSpvRatingRequest,
                        setShowSpvFeedbackReport,
                    },
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: null,
                    toolPanel: 'agFiltersToolPanel',
                    width: 280,
                },
                {
                    id: 'configPanel',
                    labelDefault: 'Configuration',
                    labelKey: 'configPanel',
                    iconKey: 'fa-config',
                    toolPanel: ConfigPanel,
                    width: 280,
                    toolPanelParams: {

                    }
                },
                {
                    id: 'exportPanel',
                    labelDefault: 'Import / Export',
                    labelKey: 'exportPanel',
                    iconKey: 'fa-legend',
                    toolPanel: ExportPanel,
                    width: 280,
                    toolPanelParams: {
                        setLoading,
                        setOpenRosterImport,
                        setOpenAccommodationFlightImport,
                        setOpenRosterSheetImport,
                        viewAccess,
                        // exportDataCallback: () => loadSchedule({
                        //     projectId: projectid,
                        //     dispatchUnitId: dispatchunitid,
                        //     a: 0,
                        // }),
                    }
                },
                {
                    id: 'legendPanel',
                    labelDefault: 'Legend',
                    labelKey: 'legendPanel',
                    iconKey: 'fa-legend',
                    toolPanel: LegendPanel,
                    width: 280,
                    toolPanelParams: {

                    }
                }
            ],
            defaultToolPanel: 'setupPanel',
            position: "left"
        };
    }, [/*loadSchedule, projectid, dispatchunitid, viewAccess, disableTrimStartDateDefault, disableTrimEndDateDefault*/]);

    const statusBar = useMemo(() => ({
        statusPanels: [
            {
                statusPanel: StatusPanelLeft,
                align: 'left',
            },
            {
                statusPanel: StatusPanelRight,
                align: 'right',
            },
        ],
    }), []);

    useEffect(() => {
        if (process.env.REACT_APP_HRIS_ROSTER_GRID_COLUMN_STATE in localStorage && !!gridRef.current.columnApi) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(localStorage.getItem(process.env.REACT_APP_HRIS_ROSTER_GRID_COLUMN_STATE)),
                applyOrder: true,
                defaultState: {
                    aggFunc: null,
                    flex: null,
                    hide: false,
                    pinned: null,
                    pivot: false,
                    pivotIndex: null,
                    rowGroup: false,
                    rowGroupIndex: null,
                    sort: null,
                    sortIndex: null
                }
            });
        }
        //setDataExists(rowData && rowData.length > 0);
        //setDataLoaded(rowData != null);
        refreshDataExistence();
        setDataLoaded(rowData != null);
    }, [rowData]);

    useEffect(() => {
        console.log('useEffect', 'rowData', rowData);
    }, [rowData]);

    //const refreshDataStatus = useCallback(() => {
    //    console.log('refreshDataStatus', 'rowData', rowData);
    //    setDataExists(rowData && rowData.length > 0);
    //    setDataLoaded(rowData != null);
    //}, [rowData]);

    const refreshDataExistence = (rowCount = null) => {
        //const rowData = _rowData ?? gridRef.current.api?.getDisplayedRowCount();
        //const rowCountLoaded = gridRef.current.api?.getDisplayedRowCount();
        const rowCountLoaded = rowCount != null ? rowCount : gridRef.current.api?.getModel().getRowCount();

        console.log('refreshDataStatus 2', 'rowData', rowData);
        setDataExists(rowCountLoaded > 0);
    };


    useEffect(() => {
        if (notification) {
            window.setTimeout(() => {
                setNotification(null);
            }, 5000)
        }
    }, [notification])

    const getContextMenuItems = useCallback((params) => {
        return ContextMenuHelper.getContextMenu({
            ...params,
            setLoading,
            setStepUpInEdit,
            setCandidateContractInEdit,
            setRosterAttributeInEdit,
            setCandidateInEdit,
            setCandidateMessageList,
            setCandidateConfirmationList,
            setCandidateBulkLogisticList,
            setCandidateBulkAvailabilityList,
            setCandidateDASList,
            setCandidateBulkDASList,
            setDeletedData,
            setLogisticInEdit,
            setShowPrepopulateWorkSequence,
            dispatchUnit,
            startDate,
            endDate,
            trimStartDate,
            trimEndDate,
            project,
            staticColumnDefs,
            loadSchedule: (param) => {
                if (param)
                {
                    loadSchedule({
                        ...param,
                        projectId: project.projectId,
                        dispatchUnitId: dispatchUnit.lookupId,
                    });
                    return;
                }

                loadSchedule({
                    projectId: project.projectId,
                    dispatchUnitId: dispatchUnit.lookupId,
                    a: 0,
                });
            },
            setContingencyOpen,
            setErrorMessage,
            setErrorTitle,
            setTrainingData,
            setCandidateManualConfirmationList,
            setRosterPositionAlignmentConfirmation,
            lookups,
            setShowMessageHistory,
            setShowAuditHistory,
            setShowTalentsSearch,
            setShowDeleteConfirmation,
            setDisableTrimStartDateDefault,
            setDisableTrimEndDateDefault,
            viewAccess,
            setSlotCountObj,
            refreshDataExistence
        });
    }, [dispatchUnit, startDate, endDate, trimStartDate, trimEndDate, project, staticColumnDefs, loadSchedule, viewAccess, lookups]);

    const getRowId = useMemo(() => (params) => params?.data?.rosterCandidateId, []);

    const onCellEditRequest = useCallback((event) => {
        console.log('onCellEditRequest', event)
        const [field, date] = event.colDef.field.split('.');
        const { newValue, data: eventData } = event;

        if (field === "candidateName" && (eventData?.candidateId ?? 0) > 0 && newValue?.candidateId !== eventData.candidateId) {
            CellEditHelper.setField("candidateName - check candidate change").get(event, null, null, (data) => {
                setConfirmCandidateChange({ event, confirmed: data.messageLogs?.length > 0 || data.rosterAttributes?.length > 0 ? false : true, rosterCandidate: eventData, messageLogs: data.messageLogs ? [...data.messageLogs] : null, rosterAttributes: data.rosterAttributes ? [...data.rosterAttributes] : null });
            });
            return;
        }

        if (field === "candidateName" && (eventData?.candidateId ?? 0) === 0 && (newValue?.candidateId ?? 0) === 0)
            return;

        CellEditHelper.setField(field).update(event, date, {
            dispatchUnit,
            startDate,
            endDate,
            trimStartDate,
            trimEndDate,
            project,
            setErrorMessage,
            setErrorTitle,
            errorMessageOpening,
            errorTitleOnGenerate,
            setTrainingData,
            setShowDeleteConfirmation,
        });
    }, [dispatchUnit, startDate, endDate, trimStartDate, trimEndDate, project]);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const onRangeSelectionChanged = React.useCallback(
        debounce((event) => {
            var cellRanges = event.api.getCellRanges();
            var elCounter = document.querySelector("#agg-info")
            elCounter.innerHTML = "";

            if (cellRanges && cellRanges.length > 0) {
                const firstCellRange = cellRanges[0];
                const column = firstCellRange.columns[0];
                const columns = firstCellRange.columns;

                const startRow = Math.min(firstCellRange.startRow.rowIndex, firstCellRange.endRow.rowIndex);
                const endRow = Math.max(firstCellRange.startRow.rowIndex, firstCellRange.endRow.rowIndex);

                const map = new Map();
                // example #1
                if (!staticColumnDefs[0].children.filter(f => f.field === column.colId).length) {

                    let empty = 0;
                    for (let i = startRow; i <= endRow; i++) {
                        const rowNode = event.api.getDisplayedRowAtIndex(i);
                        firstCellRange.columns.forEach(column => {
                            const cellValue = event.api.getValue(column, rowNode);
                            if (cellValue) {
                                Object.keys(cellValue.schedules).forEach(code => {
                                    map.set(code, (map.get(code) ?? 0) + 1)
                                })
                            } else {
                                empty++;
                            }
                        });
                    }
                    let result = "";
                    map.forEach((value, key) => {
                        result += `${ScheduleTypeValues[key]}: ${value} | `
                    })
                    elCounter.innerHTML = result + `Empty: ${empty}`;
                } else {
                    for (let i = startRow; i <= endRow; i++) {
                        const rowNode = event.api.getDisplayedRowAtIndex(i);
                        const cellValue = column.colId === "candidateName" ? !!event.api.getValue(column, rowNode) ? "Filled" : "Empty" : event.api.getValue(column, rowNode) ?? "Empty";
                        const cellCandidateRow = column.colId === "candidateName" ? !!event.api.getValue(column, rowNode) ? "Filled" : "Empty" : event.api.getValue(column, rowNode) ?? "Empty";
                        map.set(cellValue, (map.get(cellValue) ?? 0) + 1)
                    }
                    let result = "";
                    map.forEach((value, key) => {
                        result += `${key}: ${value} | `
                    })
                    elCounter.innerHTML = result + `Total: ${endRow - startRow + 1}`;
                }

                // Get all selectedRows to variable
                //var selectedCandidateNames = [];
                //for (let ii = startRow; ii <= endRow; ii++) {
                //    var newRow = {};

                //    for (let i = 0; i < columns.length; i++) {
                //        var col = columns[i];
                //        const rowNode = event.api.getDisplayedRowAtIndex(ii);


                //        const cellValue = col.colId === "candidateName" ? !!event.api.getValue(col, rowNode) ? event.api.getValue(col, rowNode) : null : event.api.getValue(col, rowNode) ?? null;
                //        if (cellValue != null) {
                //            selectedCandidateNames.push(cellValue);
                //        }

                //    }
                //}
                //setSelectedCandidateNames([...selectedCandidateNames])


                // Get all selectedRows to variable
                //var selectedRows = [];
                //var columnsForGetRows = [...columns];
                //if (columnsForGetRows.some(w => w.colId == "slot") == false) {
                //    var newSlotCol = columns.map(s => ({ ...s, colId: "slot" })).find(f => f);
                //    columnsForGetRows.push(newSlotCol);
                //    console.log('')
                //}
                //for (let ii = startRow; ii <= endRow; ii++) {
                //    var newRow = {};

                //    for (let i = 0; i < columns.length; i++) {
                //        var col = columns[i];
                //        const rowNode = event.api.getDisplayedRowAtIndex(ii);
                //        console.log('rowNode.data', rowNode.data);
                //        newRow[col.colId] = event.api.getValue(col, rowNode);
                //    }
                //    selectedRows.push(newRow);
                //}

                // Get all selectedRows to variable
                var selectedRows = [];
                var columnsForGetRows = [...columns];
                for (let ii = startRow; ii <= endRow; ii++) {
                    var newRow = {};
                    const rowNode = event.api.getDisplayedRowAtIndex(ii);
                    const { data } = rowNode;
                    newRow = { ...data };
                    selectedRows.push(newRow);
                }

                //console.log('event.api', event.api);
                setSelectedRows([...selectedRows])


            }

            //console.log('elCounter.innerHTML', elCounter.innerHTML)
        }, 150), [staticColumnDefs]);

    const processCellForClipboard = useCallback((params) => {
        // console.log('processCellForClipboard', !!staticColumnDefs[0].children.find(f => f.field === params.column.colId), params)
        return !!staticColumnDefs[0].children.find(f => f.field === params.column.colId) ? params.value : params.value?.value;
    }, []);

    const processDataFromClipboard = useCallback((params) => {
        console.log('processDataFromClipboard', params);
        setLoading(true);
        const [header, ...copiedData] = params.data;
        const trainingExists = copiedData.filter(c => c.includes("T")).length > 0;
        const data = copiedData.map(c => c.map(d => d.replace("T", "")));

        const action = () => {
            setLoading(true);
            const firstDateIndex = _.findIndex(header, str => str.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/));
            const scheduleData = firstDateIndex >= 0 ? data.map(d => d.slice(firstDateIndex)) : [...params.data];
            if (!scheduleData.length) {
                return;
            }
            const selectedRange = params.api.getCellRanges()[0];

            if (selectedRange.columns.find(f => staticColumnDefs[0].children.some(s => s.field === f.colId))) { // INVALID RANGE SELECTED
                setLoading(false);
                return;
            }

            const selectedRowCount = Math.max(selectedRange.startRow.rowIndex, selectedRange.endRow.rowIndex) - Math.min(selectedRange.startRow.rowIndex, selectedRange.endRow.rowIndex);
            if (
                selectedRowCount > 0 &&
                selectedRange.columns.length === header.length &&
                scheduleData.length === 1
            ) {
                const firstData = [...scheduleData[0]];
                for (let i = 0; i < selectedRowCount; i++) {
                    scheduleData.push([...firstData])
                }

                //console.log('expand data 1', firstData);
            }
            else if (
                selectedRowCount > 0 &&
                (selectedRowCount + 1) === scheduleData.length &&
                scheduleData.every(f => f.length === 1)
            ) {
                scheduleData.forEach(d => {
                    const firstData = d[0];
                    for (let i = 1; i < selectedRange.columns.length; i++) {
                        d.push(firstData)
                    }
                })

                //console.log('expand data 2', scheduleData);
            }
            else if (
                selectedRowCount > 0 &&
                selectedRange.columns.length > scheduleData[0].length &&
                (selectedRange.columns.length / scheduleData[0].length) % 1 === 0 &&
                (selectedRowCount + 1) > scheduleData.length &&
                ((selectedRowCount + 1) / scheduleData.length) % 1 === 0
            ) {
                const timeToExpandAcross = (selectedRange.columns.length / scheduleData[0].length) - 1;

                scheduleData.forEach(d => {
                    const clonedData = [...d]
                    for (let i = 0; i < timeToExpandAcross; i++) {
                        d.push(...clonedData);
                    }
                })

                const timeToExpandDown = ((selectedRowCount + 1) / scheduleData.length) - 1;
                const clonedData = _.cloneDeep(scheduleData);
                for (let i = 0; i < timeToExpandDown; i++) {
                    scheduleData.push(...clonedData);
                }

                //console.log('expand data 3', scheduleData);
            }
            else if (
                selectedRowCount > 0 &&
                selectedRange.columns.length > scheduleData[0].length &&
                (selectedRange.columns.length / scheduleData[0].length) % 1 === 0
            ) {
                const timeToExpand = (selectedRange.columns.length / scheduleData[0].length) - 1;

                scheduleData.forEach(d => {
                    const clonedData = [...d]
                    for (let i = 0; i < timeToExpand; i++) {
                        d.push(...clonedData);
                    }
                })

                //console.log('expand data 4', scheduleData);
            }
            else if (
                selectedRowCount > 0 &&
                (selectedRowCount + 1) > scheduleData.length &&
                ((selectedRowCount + 1) / scheduleData.length) % 1 === 0
            ) {
                const timeToExpand = ((selectedRowCount + 1) / scheduleData.length) - 1;
                const clonedData = _.cloneDeep(scheduleData);
                for (let i = 0; i < timeToExpand; i++) {
                    scheduleData.push(...clonedData);
                }

                //console.log('expand data 5', scheduleData);
            }

            const startRow = Math.min(selectedRange.startRow.rowIndex, selectedRange.endRow.rowIndex);
            let endRow = startRow + scheduleData.length;

            const allColumns = [...params.columnApi.getColumns()];
            const startCol = _.findIndex(allColumns, c => c.colId === selectedRange.columns[0].colId);
            const endCol = startCol + scheduleData[0].length;
            let columns;
            let isSingle = false;
            if (scheduleData.length == 1 && scheduleData[0].length == 1) {
                columns = selectedRange.columns;
                endRow = Math.max(selectedRange.startRow.rowIndex, selectedRange.endRow.rowIndex) + 1;
                isSingle = true;
            } else {
                columns = allColumns.slice(startCol, endCol);
            }

            console.log('selectedRange', { selectedRange, allColumns, startCol, endCol, columns, scheduleData, firstDateIndex, header, data, startRow, endRow })

            let rowIndex = 0;
            let deleteSchedules = [];
            let createSchedules = [];
            //let update = [];
            let nodes = [];
            for (let i = startRow; i < endRow; i++) {

                const rowNode = params.api.getDisplayedRowAtIndex(i);
                //console.log('rowNode', {i, rowIndex, rowNode})
                if (!rowNode) {
                    continue;
                }
                nodes.push(rowNode);
                const data = rowNode.data;
                columns.forEach((col, colIndex) => {
                    const value = isSingle ? scheduleData[0][0] : scheduleData[rowIndex][colIndex];
                    //console.log('value', {value, col, colIndex, rowIndex, chars: process.env.REACT_APP_ROSTER_VALID_SCHEDULE_CHARS})
                    if (!value) {
                        return;
                    }
                    const [, date] = col.colId.split('.');

                    const arr = _.remove(
                        _.uniq(
                            value.split('')
                        )
                        , (code) => {
                            return process.env.REACT_APP_ROSTER_VALID_SCHEDULE_CHARS.includes(code)
                        }
                    );

                    if (data.dates[date]) {
                        const currentKeys = Object.keys(data.dates[date].schedules);
                        const remove = _.difference(currentKeys, arr);
                        const add = _.difference(arr, currentKeys);
                        remove.forEach(r => {
                            deleteSchedules.push(data.dates[date].schedules[r]);
                            delete data.dates[date].schedules[r];
                            data.dates[date].value = data.dates[date].value.replace(r, '');
                            data.dates[date].className = data.dates[date].className.replace(r, '').trim();
                        });

                        add.forEach(a => {
                            createSchedules.push({ rosterCandidateId: data.rosterCandidateId, code: a, date: moment(date, 'YYYYMMDD').format('YYYY-MM-DD') });
                            data.dates[date].schedules[a] = 0;
                            data.dates[date].value += a;
                            data.dates[date].className += ` ${a}`;
                        })

                    } else {
                        arr.forEach(a => {
                            createSchedules.push({ rosterCandidateId: data.rosterCandidateId, code: a, date: moment(date, 'YYYYMMDD').format('YYYY-MM-DD') });
                        })
                        const schedules = arr.reduce((acc, curr) => (acc[curr] = 0, acc), {});
                        data.dates[date] = {
                            schedules,
                            value: arr.join(''),
                            className: arr.join(' '),
                        }
                    }
                });
                rowIndex++;
                //update.push(data);
            }

            // console.log('processDataFromClipboard', {
            //     params,
            //     header,
            //     data,
            //     firstDateIndex,
            //     scheduleData,
            //     selectedRange,
            //     deleteSchedules,
            //     createSchedules,
            // });

            api.post(`/rostergrid/rostercandidateclipboardupdate`, {
                schedulesToRemove: deleteSchedules,
                schedulesToCreate: createSchedules,
                dispatchUnitId: params.context.dispatchUnit.lookupId,
                startDate: params.context.trimStartDate ? null : formatDate(params.context.startDate, 'YYYY-MM-DD'),
                endDate: params.context.trimEndDate ? null : formatDate(params.context.endDate, 'YYYY-MM-DD')
            })
                .then((response) => {
                    //console.log('response', response)
                    params.api.applyTransactionAsync({
                        update: response.data
                    }, () => {
                        // params.api.refreshCells({
                        //     force: true,
                        //     rowNodes: nodes,
                        //     columns
                        // });
                    });
                    setShowCopyConfirmation(null);
                })
                .catch((error) => {
                    // TODO: Display Error message in UI
                    console.log(error.response)
                })
                .finally(() => {
                    setLoading(false)
                });
        };

        if (trainingExists) {
            // console.log({copiedData, data});
            setLoading(false);
            setShowCopyConfirmation({
                message: "Training data will not be copied. Continue?",
                action,
            });
            return;
        }

        action();
    }, []);


    //customAlert = { customAlertForManualConfirmationEditor }
    //customValidation = { customValidationForManualConfirmationEditor }

    // Custom Alert for Manual Confirmation Editor
    const customAlertForManualConfirmationEditor = useCallback((props) => {

        // Import vars
        var {
            flatRows,
            selectedFlatRows,
            setValidationMessage,
            validationMessage,
            invalidRosterPos
        } = props;


        if (!flatRows.length) {
            setValidationMessage([]);
            return;
        }

        var newErrorMessage = [];

        // Get invalid roster position
        const invalidHrisPostitionList = flatRows.filter(s => !s.original.hrispositionMasterName);

        // Show invalid HRIS roster position
        if (invalidHrisPostitionList.some(s => s)) {
            var slotNoList = invalidHrisPostitionList.map(m => m.original.slotNo);
            var slotNos = MakeWords(slotNoList);
            var invalidMsg = `${invalidRosterPos} ${slotNos}`;
            newErrorMessage.push(invalidMsg);
        }

        setValidationMessage(newErrorMessage);

    }, []);

    // Custom Validation for Manual Confirmation Editor
    const customValidationForManualConfirmationEditor = useCallback((props) => {

        // Import vars
        var {
            flatRows,
            selectedFlatRows,
            invalidRosterPos,
            errorMessage,
            setErrorMessage,
            errorTitle,
            setErrorTitle
        } = props; 

        // Get invalid roster position
        const invalidHrisPostitionList = selectedFlatRows.filter(s => !s.original.hrispositionMasterName);

        // Validate HRIS Position
        if (invalidHrisPostitionList && invalidHrisPostitionList.length > 0) {
            const slotNoList = invalidHrisPostitionList.map((item) => item.original.slotNo);
            const slotNoWords = MakeWords(slotNoList);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${invalidRosterPos} ${slotNoWords}.`);
            return true;
        }

        return false;
    },[])
    
    // BEGIN ERROR MESSAGE HANDLER -----------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);
    // END ERROR MESSAGE HANDLER -------------------------------------------------------------------------------------------------------------------------------------------

    const alignRosterPosition = useCallback(() => {
        if (!rosterPositionAlignmentConfirmation?.rosterCandidateIds?.length)
            return;

        setLoading(true);

        api.post(`rostergrid/roster/position/align`, JSON.stringify(rosterPositionAlignmentConfirmation.rosterCandidateIds))
            .then(() => {
                setRosterPositionAlignmentConfirmation(null);
                setLoading(false);
                loadSchedule({
                    projectId: project.projectId,
                    dispatchUnitId: dispatchUnit.lookupId,
                });
            })
            .catch((error) => {
                console.log({ error });
                setLoading(false);
            })
            ;
    }, [rosterPositionAlignmentConfirmation, project, dispatchUnit, loadSchedule]);

    return (<>
        <section className="main">
            {loading && <Loader />}
            {errorNotification.length > 0 && errorNotification}

            <div className="ag-theme-alpine px-2" style={{ width: '100%', height: 'calc(100vh - 130px)' }}>

                <AgGridReact
                    ref={gridRef}
                    context={{ dispatchUnit, startDate, endDate, trimStartDate, trimEndDate, project }}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    suppressColumnVirtualisation={suppressColumnVirtualisation}
                    suppressRowVirtualisation={suppressRowVirtualisation}
                    rowBuffer={rowBuffer}
                    debounceVerticalScrollbar={debounceVerticalScrollbar}
                    allowContextMenuWithControlKey={true}
                    getContextMenuItems={getContextMenuItems}
                    suppressClipboardPaste={isReadonly}
                    suppressKeyboardEvent={isReadonly}
                    headerHeight={70}
                    groupHeaderHeight={22}
                    rowHeight={22}
                    onGridReady={({ api }) => {
                        api.hideOverlay();
                    }}
                    readOnlyEdit={true}
                    onCellEditRequest={onCellEditRequest}
                    getRowId={getRowId}
                    enableRangeSelection={true}
                    loadingOverlayComponent={loadingOverlayComponent}
                    sideBar={sideBar}
                    statusBar={statusBar}
                    tooltipShowDelay={0}
                    tooltipHideDelay={20000}
                    onRangeSelectionChanged={onRangeSelectionChanged}
                    suppressDragLeaveHidesColumns={true}
                    suppressColumnMoveAnimation={true}
                    multiSortKey='ctrl'
                    processCellForClipboard={processCellForClipboard}
                    processDataFromClipboard={processDataFromClipboard}
                    copyHeadersToClipboard={true}
                    excelStyles={excelStyles()}
                    onCellEditingStarted={(params) => {

                        //console.log('onCellEditingStarted');

                        // Abort cell editing if readonly
                        if (isReadonly) {
                            //console.log('isReadonly', isReadonly);
                            return;
                        }

                        //console.log('params.event.key', params.event.key);

                        // If key backspace or delete
                        if (params.event.key === KEY_BACKSPACE || params.event.key === KEY_DELETE) {
                            //console.log('onCellEditingStarted', params)
                            const ranges = params.api.getCellRanges();
                            if (ranges.length > 1 || ranges[0].columns.length > 1 || ranges[0].startRow.rowIndex != ranges[0].endRow.rowIndex) {
                                params.api.stopEditing(true);
                                ContextMenuHelper.clearCellRanges(ranges, params.api, staticColumnDefs, setShowDeleteConfirmation);
                            }
                        }
                    }}
                    enableCellChangeFlash={true}
                    // undoRedoCellEditing={true}
                    // undoRedoCellEditingLimit={5}
                    // onCellValueChanged={(params) => {
                    //     console.log('onCellValueChanged', params)
                    // }}
                    animateRows={true}
                />
            </div>

            {
                contingencyOpen &&
                <Contingency
                    onClose={() => setContingencyOpen(false)}
                    dispatchUnit={dispatchUnit}
                    rosterGrid={gridRef}
                    node={contingencyOpen}
                />
            }

            {
                stepUpInEdit &&
                <PositionStepUpEditor
                    rosterCandidateId={stepUpInEdit.rosterCandidateId}
                    candidate={stepUpInEdit.candidate}
                    position={stepUpInEdit.position}
                    company={stepUpInEdit.company}
                    startDate={stepUpInEdit.startDate}
                    rowNode={stepUpInEdit.rowNode}
                    onClose={() => setStepUpInEdit(null)}
                    onSave={(data, rowNode) => {
                        setStepUpInEdit(null);
                        rowNode.setData({ ...rowNode.data, rosterCandidateCompanyId: data.companyId, rosterCandidateCompanyName: data.companyName, positionId: data.positionId, positionName: data.positionName })
                    }}
                />
            }

            {
                candidateContractInEdit &&
                <CandidateDocumentsEditor
                    project={candidateContractInEdit.project}
                    company={candidateContractInEdit.company}
                    candidate={candidateContractInEdit.candidate}
                    locationId={candidateContractInEdit.location?.locationId}
                    positionId={candidateContractInEdit.position?.positionId}
                    rosterCandidateId={candidateContractInEdit.rosterCandidateId}
                    onClose={() => {
                        setCandidateContractInEdit(null);
                    }}
                />
            }

            {
                rosterAttributeInEdit &&
                <RosterAttributeEditor
                    rosterCandidateId={rosterAttributeInEdit.rosterCandidateId}
                    project={rosterAttributeInEdit.project}
                    candidate={rosterAttributeInEdit.candidate}
                    onClose={() => {
                        setRosterAttributeInEdit(null);
                    }}
                />
            }

            {
                candidateInEdit &&
                <TalentEditor
                    item={candidateInEdit}
                    onClose={() => {
                        setCandidateInEdit(null);
                    }}
                    onSaved={() => {
                        setCandidateInEdit(null);
                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                            b: 0,
                            // startDate: !startDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                            // endDate: !endDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                        });
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }

            {
                candidateMessageList && candidateMessageList.candidates && candidateMessageList.candidates.length > 0 &&
                <EditorSMS
                    items={candidateMessageList.candidates}
                    refId={candidateMessageList.rosterCandidateId}
                    project={project}
                    projectId={project.projectId}
                    onClose={() => {
                        setCandidateMessageList(null);
                    }}
                    onSaved={() => {
                        setCandidateMessageList(null);
                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                            c: 0,
                            // startDate: !startDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                            // endDate: !endDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                        });
                    }}
                    lookups={{ ...lookups }}
                    sourcePage="roster"
                />
            }

            {
                <Modal
                    isOpen={(candidateConfirmationList && candidateConfirmationList.length > 0)}
                    className="modal-xl"
                    modalClassName="db-example-modal-xl"
                >
                    <CandidateConfirmationEditor
                        project={project}
                        dispatchUnits={dispatchUnit}
                        rosters={null}
                        rosterCandidates={candidateConfirmationList}
                        confirmationMessage={confirmationMessageTemplateForAvailability}
                        onClose={() => setCandidateConfirmationList(null)}
                        onSaved={() => {
                            setCandidateConfirmationList(null);
                            loadSchedule({
                                projectId: project.projectId,
                                dispatchUnitId: dispatchUnit.lookupId,
                                d: 0,
                                // startDate: !startDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                                // endDate: !endDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                            });
                        }}
                        lookups={{...lookups}}
                        customTitle={`Confirmation - ${candidateConfirmationList?.map(c => c.candidateName).join(", ")}`}
                    />
                </Modal>
            }

            {
                <Modal
                    isOpen={(candidateBulkAvailabilityList && candidateBulkAvailabilityList.length > 0)}
                    className="modal-xxl"
                    modalClassName="db-example-modal-xl"
                >
                    <BulkAvailabilityConfirmation
                        project={project}
                        dispatchUnits={dispatchUnit}
                        //dispatchUnitData={dispatchUnitData}
                        rosters={null}
                        rosterCandidates={candidateBulkAvailabilityList}
                        confirmationMessage={confirmationMessageTemplateForAvailability}
                        onClose={() => setCandidateBulkAvailabilityList(null)}
                        onSaved={() => {
                            setCandidateBulkAvailabilityList(null);
                            loadSchedule({
                                projectId: project.projectId,
                                dispatchUnitId: dispatchUnit.lookupId,
                                f: 0,
                                // startDate: !startDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                                // endDate: !endDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                            });
                        }}
                        lookups={{ ...lookups }}
                        bulkConfirmationMode={BulkConfirmationMode.AVAILABILITY}
                    />
                </Modal>
            }

            {
                <Modal
                    isOpen={(candidateBulkLogisticList && candidateBulkLogisticList.length > 0)}
                    className="modal-xxl"
                    modalClassName="db-example-modal-xl"
                >
                    <BulkAvailabilityConfirmation
                        project={project}
                        dispatchUnits={dispatchUnit}
                        //dispatchUnitData={dispatchUnitData}
                        rosters={null}
                        rosterCandidates={candidateBulkLogisticList}
                        confirmationMessage={confirmationMessageTemplateForLogistic}
                        onClose={() => setCandidateBulkLogisticList(null)}
                        onSaved={() => {
                            setCandidateBulkLogisticList(null);
                            loadSchedule({
                                projectId: project.projectId,
                                dispatchUnitId: dispatchUnit.lookupId,
                                g: 0,
                                // startDate: !startDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                                // endDate: !endDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                            });
                        }}
                        lookups={{ ...lookups }}
                        bulkConfirmationMode={BulkConfirmationMode.LOGISTIC}
                    />
                </Modal>
            }

            {
                deletedData &&
                <DeleteSlotDialog
                    //dispatchUnitData={dispatchUnitData}
                    rosterCandidates={deletedData.data}
                    //setDispatchUnitData={setDispatchUnitData}
                    //onSort={sortSlot}
                    onClose={() => setDeletedData(null)}
                    onDelete={(data) => {
                        deletedData.deleteAction(data).then(() => setDeletedData(null));
                    }}
                />
            }

            {
                checkConflict?.rosterCandidateId && checkConflict?.candidateId &&
                <ConflictDialog
                    data={checkConflict}
                    rosterCandidate={checkConflict.rosterCandidate}
                    close={() => {
                        setCheckConflict(null);
                    }}
                />
            }

            {
                dispatchEditorOpen &&
                <DispatchUnitEditor
                    dispatchUnitId={dispatchEditorOpen}
                    onClose={() => setDispatchEditorOpen(false)}
                    onSaved={(data) => {
                        setDispatchEditorOpen(false);

                        console.log('DispatchUnitEditor Saved', data)

                        var updatedDispatchUnit = {
                            lookupId: data.dispatchUnitId,
                            lookupValue: data.dispatchUnitName,
                            lookupLabel: data.quoteNumber,
                            locationName: data.location.lookupValue
                        };

                        if (process.env.REACT_APP_HRIS_ROSTER_GRID_SETUP_STATE in localStorage) {
                            const savedState = JSON.parse(localStorage.getItem(process.env.REACT_APP_HRIS_ROSTER_GRID_SETUP_STATE));
                            localStorage.setItem(process.env.REACT_APP_HRIS_ROSTER_GRID_SETUP_STATE, JSON.stringify({
                                ...savedState,
                                dispatchUnit: updatedDispatchUnit,
                            }));
                        }
                        setDispatchUnit(updatedDispatchUnit);

                        const start = moment(data.startDate, 'DD-MM-YYYY');
                        const end = moment(data.endDate, 'DD-MM-YYYY');

                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                            startDate: start.format('YYYY-MM-DD'),
                            endDate: end.format('YYYY-MM-DD'),
                            h: 0,
                        });

                        var elInfo = document.querySelector("#du-info");
                        elInfo.innerHTML = `${project.projectName} | ${data.dispatchUnitName} | ${data.quoteNumber} | ${data.location.lookupValue}`;
                    }}
                />
            }

            {
                openRosterImport && rowData && rowData.length > 0 &&
                <ImportDialog
                    onClose={() => {
                        setOpenRosterImport(false);
                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                            i: 0,
                            // startDate: !startDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                            // endDate: !endDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                        });
                    }}
                    rosterId={rowData[0].rosterId}
                />
            }

            {
                openAccommodationFlightImport && rowData && rowData.length > 0 &&
                <ImportAccommodationFlightDialog
                    onClose={() => {
                        setOpenAccommodationFlightImport(false);
                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                            i: 0,
                            // startDate: !startDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                            // endDate: !endDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                        });
                    }}
                    rosterId={rowData[0].rosterId}
                    dispatchUnitId={ rowData[0].dispatchUnitId }
                />
            }

            {
                logisticInEdit &&
                <LogisticEditor
                    project={project}
                    dispatchUnits={logisticInEdit.dispatchUnits}
                    rosters={logisticInEdit.rosters}
                    startDate={startDate}
                    endDate={endDate}
                    rosterCandidates={logisticInEdit.rosterCandidates}
                    rosterFlightTypes={lookups.rosterFlightTypes}
                    rosterFlightStatuses={lookups.rosterFlightStatuses}
                    rosterAccommodationStatuses={lookups.rosterAccommodationStatuses}
                    selectedRows={selectedRows}
                    onClose={() => {
                        setLogisticInEdit(null);
                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                            j: 0,
                            // startDate: !startDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                            // endDate: !endDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                        })
                    }}
                    onSaved={() => {
                        setLogisticInEdit(null);
                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                            k: 0,
                            // startDate: !startDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                            // endDate: !endDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                        })
                    }}
                    lookups={lookups}
                />
            }
            {
                confirmCandidateChange && !confirmCandidateChange.confirmed && (confirmCandidateChange.messageLogs || confirmCandidateChange.rosterAttributes) ?
                    <SweetAlert
                        title="Change Candidate Confirmation"
                        warning
                        showCancel
                        cancelBtnText="No"
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="warning"
                        confirmBtnBsStyle="default"
                        onConfirm={() => setConfirmCandidateChange({ ...confirmCandidateChange, confirmed: true })}
                        onCancel={() => setConfirmCandidateChange(null)}
                        customClass="modal-xl-mandatory"
                    >
                        <Container fluid>
                            <Row>
                                <Col lg="12" className="my-3">
                                    <h5 className="text-justify">
                                        Confirmation Messages/Roster Attributes are detected for this candidate. Are you sure you want to remove the candidate assignment and the attributes?
                                    </h5>
                                </Col>
                            {
                                confirmCandidateChange.messageLogs &&
                                <>
                                    <Col lg="12">
                                        <h6 className="text-justify font-weight-bold">
                                            Message Logs:
                                        </h6>
                                    </Col>
                                    <Col lg="12">
                                        <Row className={confirmCandidateChange.rosterAttributes ? "border rounded-lg" : ""} style={{ maxHeight: confirmCandidateChange.rosterAttributes ? 180 : 400, overflowY: "scroll" }}>
                                            {
                                                confirmCandidateChange.messageLogs.map((m, idx) => {
                                                    return (
                                                        <Col lg="12" key={`candidate_change_confirm_${idx}`}>
                                                            <p className={`text-left${idx < confirmCandidateChange.messageLogs.length - 1 ? " pb-2 border-bottom" : ""}`} style={{fontSize: "0.8rem"}}>
                                                                <span style={{ fontWeight: "bold" }}>{moment(m.sentDate).format("DD-MM-YYYY HH:mm:ss")} - </span>{m.messageText}
                                                            </p>
                                                        </Col>
                                                    );
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </>
                            }
                            {
                                confirmCandidateChange.rosterAttributes &&
                                <>
                                    <Col lg="12">
                                        <h6 className={`text-justify font-weight-bold${confirmCandidateChange.messageLogs ? " mt-4" : ""}`}>
                                            Roster Attributes:
                                        </h6>
                                    </Col>
                                    <Col lg="12">
                                        <Row className={confirmCandidateChange.messageLogs ? "border rounded-lg" : ""} style={{ maxHeight: confirmCandidateChange.messageLogs ? 180 : 400, overflowY: "scroll" }}>
                                            {
                                                confirmCandidateChange.rosterAttributes.map((m, idx) => {
                                                    return (
                                                        <Fragment key={`candidate_change_confirm_ra_${idx}`}>
                                                            <Col lg="3" className="border-right">
                                                                <p className="text-left" style={{fontSize: "0.8rem"}}>
                                                                    <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>{m.name}</span>
                                                                </p>
                                                            </Col>
                                                            <Col lg="9">
                                                                <p className="text-left" style={{fontSize: "0.8rem"}}>
                                                                    <span>{m.value}</span>
                                                                </p>
                                                            </Col>
                                                        </Fragment>
                                                    );
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </>
                            }
                            </Row>
                        </Container>
                    </SweetAlert>
                    :
                    null
            }

            {
                <Modal
                    isOpen={(candidateDASList && candidateDASList.length > 0)}
                    className="modal-xl"
                    modalClassName="db-example-modal-xl"
                >
                    <CandidateDASEditor
                        project={project}
                        dispatchUnits={dispatchUnit}
                        rosters={null}
                        rosterCandidates={candidateDASList}
                        confirmationMessage={confirmationMessageTemplateForDAS}
                        onClose={() => setCandidateDASList(null)}
                        onSaved={() => {
                            setCandidateDASList(null);
                            loadSchedule({
                                projectId: project.projectId,
                                dispatchUnitId: dispatchUnit.lookupId,
                                l: 0,
                            });
                        }}
                        lookups={{ ...lookups }}
                    />
                </Modal>
            }

            {
                <Modal
                    isOpen={(candidateBulkDASList && candidateBulkDASList.length > 0)}
                    className="modal-xl"
                    modalClassName="db-example-modal-xl"
                >
                    <BulkDASConfirmation
                        project={project}
                        dispatchUnits={dispatchUnit}
                        rosters={null}
                        rosterCandidates={candidateBulkDASList}
                        confirmationMessage={confirmationMessageTemplateForDAS}
                        onClose={() => setCandidateBulkDASList(null)}
                        onSaved={() => {
                            setCandidateBulkDASList(null);
                            loadSchedule({
                                projectId: project.projectId,
                                dispatchUnitId: dispatchUnit.lookupId,
                                m: 0,
                            });
                        }}
                        lookups={{ ...lookups }}
                    />
                </Modal>
            }

            {
                trainingData &&
                <CandidateTrainingDialog
                    candidateId={trainingData.candidateId}
                    startDate={trainingData.startDate}
                    endDate={trainingData.endDate}
                    onClose={() => setTrainingData(null)}
                    onSaved={(params) => {
                        trainingData.onSaved({
                            ...params,
                            successCallback: () => {
                                params.successCallback();
                                setTrainingData(null);
                                loadSchedule({
                                    projectId: project.projectId,
                                    dispatchUnitId: dispatchUnit.lookupId,
                                    n: 0,
                                });
                            }
                        })
                    }}
                    onDelete={(params) => {
                        trainingData.onDelete({
                            ...params,
                            successCallback: () => {
                                params.successCallback();
                                setTrainingData(null);
                                loadSchedule({
                                    projectId: project.projectId,
                                    dispatchUnitId: dispatchUnit.lookupId,
                                    o: 0,
                                });
                            }
                        })
                    }}
                />
            }

            {
                candidateManualConfirmationList && candidateManualConfirmationList.data?.length && candidateManualConfirmationList?.type ?
                    <ManualConfirmationEditor
                        rosterCandidateList={candidateManualConfirmationList.data}
                        confirmationType={candidateManualConfirmationList.type}
                        status={candidateManualConfirmationList.status}
                        onClose={() => setCandidateManualConfirmationList(null)}
                        onSave={() => {
                            setCandidateManualConfirmationList(null);
                            loadSchedule({
                                projectId: project.projectId,
                                dispatchUnitId: dispatchUnit.lookupId,
                            });
                        }}
                        customAlert={customAlertForManualConfirmationEditor}
                        customValidation={customValidationForManualConfirmationEditor}
                    />
                    :
                    null
            }

            {
                rosterPositionAlignmentConfirmation?.rosterCandidateIds?.length > 0 &&
                <SweetAlert
                    title="Roster Position Alignment Confirmation"
                    warning
                    showCancel
                    cancelBtnText="No"
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => alignRosterPosition()}
                    onCancel={() => setRosterPositionAlignmentConfirmation(null)}
                >
                    <Container fluid>
                        <Row>
                            <Col lg="12" className="my-3">
                                <h5 className="text-justify">
                                    Roster positions will be aligned with employed positions. Continue?
                                </h5>
                            </Col>
                        </Row>
                    </Container>
                </SweetAlert>
            }

            {
                showMessageHistory !== null &&
                <CommunicationDialog
                    onClose={() => setShowMessageHistory(null)}
                    data={showMessageHistory}
                />
            }

            {
                showAuditHistory !== null &&
                <AuditDialog
                    onClose={() => setShowAuditHistory(null)}
                    data={showAuditHistory}
                />
            }

            {
                dispatchUnit?.lookupId && showTalentsSearch !== null &&
                <CandidateSearchDialog
                    dispatchUnitId={dispatchUnit.lookupId}
                    rosterCandidateIds={showTalentsSearch.rosterCandidateIds}
                    lookups={lookups}
                    onClose={() => {
                        setShowTalentsSearch(null);
                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                        });
                    }}
                    onSave={() => {
                        setShowTalentsSearch(null);
                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                        });
                    }}
                />
            }
            {
                !!showPrepopulateWorkSequence &&
                <PrepopulateWorkSequence
                    onClose={() => setShowPrepopulateWorkSequence(null)}
                    dates={showPrepopulateWorkSequence.dates}
                    loadSchedule={loadSchedule}
                    projectId={project.projectId}
                    dispatchUnitId={dispatchUnit.lookupId}


                    onSave={() => {
                        setShowPrepopulateWorkSequence(null);
                        //loadSchedule({
                        //    projectId: project.projectId,
                        //    dispatchUnitId: dispatchUnit.lookupId,
                        //});
                    }}
                ></PrepopulateWorkSequence>
            }

            {
                showCopyConfirmation ?
                    <SweetAlert
                        title="Schedule Copy Confirmation"
                        warning={true}
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="danger"
                        onConfirm={() => showCopyConfirmation.action(() => {
                            loadSchedule({
                                projectId: project.projectId,
                                dispatchUnitId: dispatchUnit.lookupId,
                            });
                        })}
                        showCancel={true}
                        cancelBtnText="No"
                        onCancel={() => setShowCopyConfirmation(null)}
                    >
                        {
                            showCopyConfirmation.message
                        }
                    </SweetAlert>
                    :
                    null
            }

            {
                showDeleteConfirmation ?
                    <SweetAlert
                        title="Schedule Removal Confirmation"
                        warning={true}
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="danger"
                        onConfirm={() => showDeleteConfirmation.action(() => {
                            loadSchedule({
                                projectId: project.projectId,
                                dispatchUnitId: dispatchUnit.lookupId,
                            });
                        })}
                        showCancel={true}
                        cancelBtnText="No"
                        onCancel={() => {
                            setShowDeleteConfirmation(null);
                            loadSchedule({
                                projectId: project.projectId,
                                dispatchUnitId: dispatchUnit.lookupId,
                            });
                        }}
                    >
                        <span>
                            {
                                showDeleteConfirmation.message
                            }
                        </span><br />
                        {
                            showDeleteConfirmation.details.map((d, key) => {
                                return (
                                    <span key={`delete_detail_${key}`}>
                                        {d}
                                        <br />
                                    </span>
                                );
                            })
                        }
                    </SweetAlert>
                    :
                    null
            }

            {
                showSpvRatingRequest &&
                <SpvRatingDialog
                    dispatchUnitId={dispatchUnit.lookupId}
                    onClose={() => setShowSpvRatingRequest(false)}
                />
            }

            {
                showSpvFeedbackReport &&
                <SupervisorFeedbackDialog
                    dispatchUnitId={dispatchUnit.lookupId}
                    onClose={() => setShowSpvFeedbackReport(false)}
                />
            }

            {
                slotCountObj && gridRef?.current ?
                <AddSlotDialog
                    onClose={() => setSlotCountObj(null)}
                    gridApi={gridRef?.current?.api}
                    data={slotCountObj}
                />
                :
                null
            }

            {
                rosterCandidateNoteParam ?
                <RosterCandidateNoteEditor
                    data={rosterCandidateNoteParam}
                    onClose={() => setRosterCandidateNoteParam(null)}
                />
                :
                null
            }
            {
                openRosterSheetImport && rowData && rowData.length > 0 ?
                <ImportRosterSheetDialog
                    onClose={() => {
                        setOpenRosterSheetImport(false);
                        loadSchedule({
                            projectId: project.projectId,
                            dispatchUnitId: dispatchUnit.lookupId,
                        });
                    }}
                    rosterId={rowData[0].rosterId}
                />
                :
                null
            }
        </section>

        <NotificationGroup
            style={{
                right: 8,
                bottom: 0,
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
            }}
        >
            <Slide direction={notification ? "up" : "down"}>
                {
                    notification &&
                    <Notification
                        type={{
                            style: notification.type,
                            icon: true,
                        }}
                        closable={true}
                        onClose={() => setNotification(null)}
                    >
                        <span>{notification.message}</span>
                    </Notification>
                }
            </Slide>
        </NotificationGroup>

    </>);
}