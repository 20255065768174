/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
    Modal, Container, Row, Col, Button, Label
 } from "reactstrap"; 
import api from "../../../services/api";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import Loader from "../../loaders";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community';
import readXlsxFile from 'read-excel-file';
import _ from 'lodash';
import "../../../assets/css/ag-grid.css";
import "../../../assets/css/ag-theme-alpine.css";
import { TooltipButton } from '../../inputs';
import moment from 'moment';

const UPLOAD_MODE = "upload";
const VALIDATION_MODE = "validation";

export default function ImportDialog(props) {
    const [ loading, setLoading ] = useState(false);
    const [ importFiles, setImportFiles ] = useState([]);
    const [ alert, setAlert ] = useState('');
    const [ data, setData ] = useState([]);
    const [ validationData, setValidationData ] = useState([]);
    const [ mode, setMode ] = useState(UPLOAD_MODE);
    const [ validationAlert, setValidationAlert ] = useState([]);
    const [ openValidationAlert, setOpenValidationAlert ] = useState(false);
    const [ canSave, setCanSave ] = useState(false);
    const [ validated, setValidated ] = useState(false);
    const [ duplicateDataValidationAlert, setDuplicateDataValidationAlert ] = useState([]);
    const [ projectAttributes, setProjectAttributes ] = useState([]);

    const defaultColumns = [
        {
            name: "rosterCandidateId",
            title: "ID"
        },
        {
            name: "position",
            title: "Natural Position"
        },
        {
            name: "rosterPosition",
            title: "Roster Position"
        },
        {
            name: "firstName",
            title: "First Name"
        },
        {
            name: "surname",
            title: "Surname"
        },
        {
            name: "pointOfHire",
            title: "Point of Hire"
        },
        {
            name: "mobilePhoneNumber",
            title: "Mobile No."
        },
        {
            name: "shift",
            title: "Shift"
        },
        {
            name: "accommodationName",
            title: "Accommodation"
        },
        {
            name: "[Roster Attributes]",
            title: "Roster Attributes"
        },
        {
            name: "mobe",
            title: "Mobe Date"
        },
        {
            name: "mobeShift",
            title: "Mobe AM/PM"
        },
        {
            name: "workStart",
            title: "Start Work Date"
        },
        {
            name: "workStartTime",
            title: "Start Work Time"
        },
        {
            name: "mobeTerminal",
            title: "Terminal"
        },
        {
            name: "mobeFlightInfo",
            title: "Mobe Flight Details"
        },
        {
            name: "mobeFlightComment",
            title: "Mobe Flight Notes"
        },
        {
            name: "workEnd",
            title: "End Work Date"
        },
        {
            name: "workEndTime",
            title: "End Work Time"
        },
        {
            name: "demobe",
            title: "Demobe Date"
        },
        {
            name: "demobeShift",
            title: "Demobe AM/PM"
        },
        {
            name: "demobeFlightInfo",
            title: "Demobe Flight Details"
        },
        {
            name: "demobeFlightComment",
            title: "Demobe Flight Notes"
        },
        {
            name: "rosterCandidateStatus",
            title: "Confirmation Status"
        },
    ];

    const [ columnDefs, setColumnDefs ] = useState([...defaultColumns.map(c => ({
        field: c.name,
        headerName: c.title,
    }))]);
    const gridRef = useRef();

    useEffect(() => {
        setLoading(true);

        api.get(`projectattribute/roster/${props.rosterId}`)
            .then(response => {
                setProjectAttributes([...response.data]);
            })
            .catch((e) => console.error({e}))
            .finally(() => setLoading(false))
        ;
    }, [props.rosterId]);

    useEffect(() => {
        if (!projectAttributes.length)
            return;

        const newColumnDefs = JSON.parse(JSON.stringify(columnDefs));
        const rosterPositionColumn = newColumnDefs.filter(c => c.field === "rosterPosition")[0];
        const startingIndex = newColumnDefs.indexOf(rosterPositionColumn);
        newColumnDefs.splice(startingIndex, 0, ...projectAttributes.map(pa => ({ field: `[${pa.projectAttributeName}]`, headerName: pa.projectAttributeName })));
        // console.log({projectAttributes, newColumnDefs});
        setColumnDefs([...newColumnDefs]);
    }, [projectAttributes]);

    const onDrop = useCallback(acceptedFiles => setImportFiles([...acceptedFiles]), [importFiles]);

    const handleFileSelected = useCallback(() => {
        setLoading(true);
        const file = importFiles[0];

        readXlsxFile(file)
            .then((rows) => {
                // console.log({rows});
                
                if (rows?.length < 8 || rows[6][0]?.toLowerCase() !== defaultColumns[0].title.toLowerCase())
                {
                    setAlert({type: "error", message: "Invalid data format."});
                    setImportFiles([]);
                    setData([]);
                    setValidationData([]);
                    
                    return;
                }

                const columns = [...rows[6]];
                const newRows = [...rows];
                newRows.splice(0, 7);

                const gridColumns = [];
                const newData = [];
                const newValidationData = [];

                columns.forEach(c => {
                    const column = {
                        field: defaultColumns.filter(d => d.title === c)?.length ? defaultColumns.filter(d => d.title === c)[0].name : c.replace(/\s+/g, "").replace(/[^A-Za-z]+/g, ""),
                        headerName: c,
                        editable: false
                    };

                    // if (column.headerName === "Natural Position")
                    // {
                    //     column.cellEditor = (props, ref) => <DefaultComboboxEditor {...props} endpoint="/position/searchname" keyField="positionId" textField="positionName" {...ref} />;
                    //     column.cellEditorPopup = false;
                    //     column.editable = true;
                    //     column.cellClass = "editable-cell roster-cell";
                    // }

                    // if (column.headerName === "Roster Position")
                    // {
                    //     column.cellEditor = (props, ref) => <DefaultComboboxEditor {...props} endpoint="/hrispositionmaster/searchname" keyField="hrisPositionMasterId" textField="hrisPositionMasterName" {...ref} />;
                    //     column.cellEditorPopup = false;
                    //     column.editable = true;
                    //     column.cellClass = "editable-cell roster-cell";
                    // }

                    // if (column.headerName === "Accommodation")
                    // {
                    //     column.cellEditor = (props, ref) => <DefaultComboboxEditor {...props} endpoint="/accommodation/searchname" keyField="accommodationId" textField="accommodationName" {...ref} />;
                    //     column.cellEditorPopup = true;
                    //     column.editable = true;
                    //     column.cellClass = "editable-cell roster-cell";
                    // }

                    gridColumns.push(column);
                });

                const validationColumns = [
                    "id",
                    "drivers license",
                    "msic",
                    "erms",
                    "start work date",
                    "mobe date",
                    "mobe flight details",
                    "end work date",
                    "demobe date",
                    "demobe flight details",
                ]

                newRows.forEach((r, r_idx) => {
                    const row = {};
                    const validationRow = {
                        rosterAttributeNames: []
                    };
                    let rosterAttributeStart = false;

                    r.forEach((c, idx) => {
                        const columnName = defaultColumns.filter(d => d.title.toLowerCase() === columns[idx].toLowerCase())?.length ? defaultColumns.filter(d => d.title.toLowerCase() === columns[idx].toLowerCase())[0].name : columns[idx].replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "");
                        // console.log(`row[${r_idx}].${columns[idx].replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "")} = ${c}; real column name: ${columns[idx]}`);
                        row[columnName] = c;

                        if (validationColumns.includes(columns[idx].toLowerCase()))
                        {
                            // console.log(`validation row: ${columns[idx]}`);
                            validationRow[columnName] = c;
                        }

                        if (rosterAttributeStart)
                            validationRow.rosterAttributeNames.push({
                                key: columns[idx],
                                value: c
                            });

                        if (columns[idx].toLowerCase() === "accommodation")
                            rosterAttributeStart = true;

                        if (columns[idx].toLowerCase() === "mobe am/pm")
                            rosterAttributeStart = false;
                    });

                    // console.log(`row[${r_idx}] = `, {...row});
                    newData.push({...row});
                    newValidationData.push({...validationRow});
                });

                // console.log({gridColumns, newRows, newData, newValidationData});
                setColumnDefs([...gridColumns]);
                setValidationData([...newValidationData]);
                setData([...newData]);
                setValidated(false);
            })
            .finally(() => setLoading(false))
        ;
    }, [importFiles]);

    useEffect(() => {
        if (!importFiles?.length)
            return;

        handleFileSelected();
    }, [importFiles]);

    useEffect(() => {
        const rosterCandidateDates = [];
        const newValidationData = [];

        if (!data?.length)
        {
            setColumnDefs([...defaultColumns.map(c => ({
                field: c.name,
                headerName: c.title,
            }))]);
            setCanSave(false);
            setValidationAlert([...newValidationData]);
            setDuplicateDataValidationAlert([...newValidationData]);
            return;
        }

        let newCanSave = true;
        let i = 0;

        while (i < data.length)
        {
            if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === data[i].rosterCandidateId)?.length)
            {
                rosterCandidateDates.push({
                    rosterCandidateId: data[i].rosterCandidateId,
                    startDate: data[i].workStart,
                    endDate: data[i].workEnd,
                });
                i++;
                continue;
            }

            if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === data[i].rosterCandidateId && rc.startDate === data[i].workStart && rc.endDate === data[i].workEnd)?.length)
            {
                i++;
                continue;
            }

            if (newCanSave)
                newCanSave = false;
            
            if (!newValidationData.filter(d => d.id === data[i].rosterCandidateId)?.length)
                newValidationData.push({
                    id: data[i].rosterCandidateId,
                    messages: []
                });
            
            const validation = newValidationData.filter(d => d.id === data[i].rosterCandidateId)[0];

            if (!validation.messages?.length)
                validation.messages.push("Start Date/End Date for each row with the same ID should be different.");
            
            i++;
        }

        // console.log({newCanSave, rosterCandidateDates});

        setValidationAlert([...newValidationData]);
        setDuplicateDataValidationAlert([...newValidationData]);
        setCanSave(newCanSave);
    }, [data]);

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ 
        onDrop,
        maxFiles: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const dropzoneClasses = useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        resizable: true,
        filter: true,
        editable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
    }), []);

    const files = useMemo(() => 
        importFiles.map(
            file => (
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
            )
        
    ), [importFiles]);

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    const onCellEditRequest = useCallback((event) => {
        // console.log("on cell edit request: ", {event});
    }, []);

    const handleValidateData = useCallback(() => {
        if (!validationData?.length)
            return;

        setLoading(true);

        api.post(`/roster/validateimport`, JSON.stringify(validationData))
            .then((response) => {
                // console.log({response});

                if (mode !== VALIDATION_MODE)
                    setMode(VALIDATION_MODE);

                // console.log("check result: ", {check: response.data
                //     .filter(d => !d.messages?.length || !d.messages.filter(m => {
                //         console.log(m.toLowerCase().includes("start work date"), m.toLowerCase().includes("end work date"));
                //         return m.toLowerCase().includes("start work date") || m.toLowerCase().includes("end work date");
                //     }))?.length === 0}
                // );

                const newValidationData = [...duplicateDataValidationAlert];

                if (response.data?.length)
                    response.data.forEach(d => {
                        if (!newValidationData.filter(v => v.id === d.id)?.length)
                        {
                            newValidationData.push({...d});
                            return;
                        }

                        const validation = newValidationData.filter(v => v.id === d.id)[0];
                        validation.messages = [...validation.messages, d.messages];
                    });
                
                setValidationAlert([...newValidationData]);
                setOpenValidationAlert(true);
                setValidated(true);
                setLoading(false);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
    }, [validationData, mode, duplicateDataValidationAlert]);

    const handleImport = useCallback(() => {
        setLoading(true);
        
        const rosterAttributes = [];
        
        columnDefs.forEach(c => {
            if (defaultColumns.filter(d => d.name === c.field)?.length)
                return;

            rosterAttributes.push({fieldName: c.field, attributeName: c.headerName});
        });

        const postData = [];
        
        data.forEach(d => {
            const row = {
                ...d,
                mobe: d.mobe && d.mobe !== "" ? moment(d.mobe, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
                workStart: d.workStart && d.workStart !== "" ? moment(d.workStart, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
                demobe: d.demobe && d.demobe !== "" ? moment(d.demobe, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
                workEnd: d.workEnd && d.workEnd !== "" ? moment(d.workEnd, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
            };

            rosterAttributes.forEach(r => {
                row.rosterAttributeName = r.attributeName;
                row.rosterAttributeValue = d[r.fieldName];
                postData.push({...row});
            });
        });

        // console.log({rosterAttributes, postData});

        api.post(`roster/importraw/${props.rosterId}`, {data: postData, fileName: importFiles[0].name})
            .then(response => {
                // console.log('roster import', response);
                setAlert({type: "success"});
            })
            .catch((error) =>{ 
                console.error({error});
                setAlert({type: "error", message: JSON.stringify(error.response?.data)});
            })
            .finally(() => setLoading(false));
    }, [data, columnDefs, importFiles]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
            {loading && <Loader />}
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Import Roster
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div {...getRootProps({className: dropzoneClasses})}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop a file here, or click to select a file</p>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <aside className="dropzone-list pt-3">
                                <Label>File</Label>
                                <ul>{files}</ul>
                            </aside>
                        </Col>
                    </Row>
                    <Row className="w-100 mx-0 mt-3">
                    {
                        validationAlert?.length > 0 &&
                        <Col xs={12} className={`d-flex flex-wrap justify-content-end align-items-center px-0 mb-${validated ? "2" : "1"}`}>
                            <TooltipButton
                                id="show-validation-result"
                                title="Show Validation Errors"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setOpenValidationAlert(true)}
                            >
                                <i className="fas fa-exclamation" />
                            </TooltipButton>
                        </Col>
                    }
                    {
                        data?.length > 0 && !validated &&
                        <Col xs={12} className="d-flex flex-wrap justify-content-end align-items-center px-0 mb-2">
                            <span className="text-warning font-italic">Please do the validation process before upload the data.</span>
                        </Col>
                    }
                        <Col xs={12} className="d-flex flex-wrap justify-content-center align-items-center px-0">
                            <div className="ag-theme-alpine" style={{width: '100%', height: data.length ? (mode === UPLOAD_MODE ? "44vh" : "40vh") : 75}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={data}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    loadingOverlayComponent={loadingOverlayComponent}
                                    suppressColumnVirtualisation={false}
                                    suppressRowVirtualisation={false}
                                    debounceVerticalScrollbar={false}
                                    rowBuffer={20}
                                    headerHeight={40}
                                    groupHeaderHeight={22}
                                    rowHeight={22}
                                    // readOnlyEdit={true}
                                    onRowDataUpdated={() => resizeColumns()}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressColumnMoveAnimation={true}
                                    animateRows={false}
                                    onCellEditRequest={onCellEditRequest}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap justify-content-between align-items-center w-100"
            >
                <div className="d-flex flex-wrap justify-content-start align-items-center">
                    <Button
                        color="secondary"
                        onClick={props.onClose}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
                <div className="d-flex flex-wrap justify-content-end align-items-center">
                <Button
                    color="default"
                    onClick={() => {
                        handleValidateData();
                    }}
                    type="button"
                    disabled={!data || data.length === 0}
                    className="mr-2"
                >
                    <i className="fas fa-check-square mr-2" />Validate
                </Button>
                <Button
                    color="default"
                    onClick={() => {
                        handleImport();
                    }}
                    type="button"
                    disabled={!data || data.length === 0 || !validated || !canSave}
                >
                    <i className="fas fa-paper-plane mr-2" />Upload to Roster
                </Button>
                </div>
            </div>
        {
            alert &&
            <SweetAlert
                title={alert.type.toLowerCase() === 'error' ? 'Error' : 'Success'}
                error={alert.type.toLowerCase() === 'error'}
                confirmBtnText="OK"
                confirmBtnBsStyle={alert.type.toLowerCase() === 'error' ? "danger" : "success"}
                onConfirm={() => {
                    if (alert.type.toLowerCase() === "error")
                    {
                        setAlert(null);
                        return;
                    }

                    props.onClose();
                }}
            >
            {
                alert.type.toLowerCase() == 'error' ? alert.message : 'File successfully uploaded'
            }
            </SweetAlert>
        }
        {
            openValidationAlert &&
            <SweetAlert
                title={validationAlert.length ? "Warning" : "Validation Success!"}
                warning={validationAlert.length > 0}
                success={validationAlert.length === 0}
                confirmBtnText="OK"
                confirmBtnBsStyle={validationAlert.length > 0 ? "danger" : "success"}
                onConfirm={() => {
                    setOpenValidationAlert(false);
                }}
            >
                <Container fluid style={{maxHeight: "30vh", overflowY: "scroll"}}>
                {
                    validationAlert.length ?
                    validationAlert.map((v, v_idx) => {
                        return (
                            <Container fluid key={`import_validation_${v_idx}`}>
                                <Row className="w-100 m-0">
                                    <Col xs={12} className="p-0 w-100">
                                        <p className="w-100 text-justify" style={{fontSize: "0.9rem"}}><u>Invalid data on Id <span style={{fontWeight: "bold"}}>{v.id}</span>:</u></p>
                                    </Col>
                                </Row>
                                <Row className="w-100 m-0 mb-1">
                                    <Col xs={12} className="p-0">
                                        <ol type="1">
                                        {
                                            v.messages.map((m, m_idx) => {
                                                return (
                                                    <li className="w-100 text-justify" style={{fontSize: "0.85rem"}} key={`import_validation_${v_idx}_${m_idx}`}>{m}</li>
                                                );
                                            })
                                        }
                                        </ol>
                                    </Col>
                                </Row>
                            </Container>
                        );
                    })
                    :
                    ""
                }
                </Container>
            </SweetAlert>
        }
        </Modal>
    )
}