import rules from "../auth/rbacRules";
import _ from "lodash";

const check = (rules, roles, action, data) => {
    let permissions = {};

    _.forEach(roles, (role) => {
        permissions = _.merge(permissions, rules[role]);
    });

    if (!permissions) {
      // role is not present in the rules
      return false;
    }
  
    const staticPermissions = permissions.static;
  
    if (staticPermissions && staticPermissions.includes(action)) {
      // static rule not provided for action
      return true;
    }
  
    const dynamicPermissions = permissions.dynamic;
  
    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action];
      if (!permissionCondition) {
        // dynamic rule not provided for action
        return false;
      }
  
      return permissionCondition(data);
    }
    return false;
  };
const Can = props =>
  check(rules, props.roles, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

const requiresRoles = (roles, user, Component) => {

    const allowed = _.intersection(roles, user.idTokenClaims.roles).length > 0

    // console.log("requiresRoles", {
    //     allowed,
    //     roles,
    //     user,
    // })




    if (allowed && typeof Component === 'function') {
        return Component();
    } else if (allowed) {
        return Component;        
    } else {
        return null;
    }
    
}

export {
    Can,
    requiresRoles
};