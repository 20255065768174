import axios from "axios";
import { apiRequest } from "../auth/authConfig";
import { msalInstance } from "../index";

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WEB_API_BASE_URI}/`
})

axiosInstance.interceptors.request.use(
    async (config) => {
        const activeAccount = {...msalInstance.getActiveAccount()};
        const accessToken = await msalInstance
            .acquireTokenSilent({ ...apiRequest, account: msalInstance.getActiveAccount() })
            .then((response) => response.accessToken);

        config.headers["Authorization"] = `Bearer ${accessToken}`;
        config.headers["Content-Type"] = "application/json";
        config.headers["X-Timezone-Offset"] = -new Date().getTimezoneOffset();

        if (activeAccount?.idTokenClaims?.roles?.length)
            config.headers["Roles"] = activeAccount.idTokenClaims.roles.sort().join(";");

        return config;
    },
    (error) => {
        return Promise.reject(error)
    }
);

export default axiosInstance;

export async function apiGet (endpoint, token) {
    
    const response = await fetch(`${process.env.REACT_APP_WEB_API_BASE_URI}${endpoint}`,
    {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token.accessToken}`
        }
    });

    return response;
} 

export async function apiGetJson (endpoint, token) {

    const response = await apiGet(endpoint, token)

    return response.json();
} 

export async function apiDelete (endpoint, token) {
    const response = await fetch(`${process.env.REACT_APP_WEB_API_BASE_URI}${endpoint}`,
    {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token.accessToken}`
        }
    });

    return response;
}

export async function apiCreate (endpoint, payload, token) {
    const response = await fetch(`${process.env.REACT_APP_WEB_API_BASE_URI}${endpoint}`,
    {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token.accessToken}`
        }
    });

    return response;
}

export async function apiUpdate (endpoint, id, payload, token) {
    const response = await fetch(`${process.env.REACT_APP_WEB_API_BASE_URI}${endpoint}/${id}`,
    {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token.accessToken}`
        }
    });

    return response;
}

export async function apiSave(endpoint, payload, idField, token) {
    return payload[idField] > 0 ? apiUpdate(endpoint, payload[idField], payload, token) : apiCreate(endpoint, payload, token);
}

