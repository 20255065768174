/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";

const AppContext = React.createContext();

export function useAppContext() {
    return React.useContext(AppContext);
}   


export function AppProvider({ children }) {
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [breadcrumbItemOverrides, setBreadcrumbItemOverrides] = useState([]);

    // BEGIN LINKED VARS ------------------------------------------------------------------------------------------------------------------------------------------
    const breadcrumbsRef = useRef(breadcrumbs);
    const breadcrumbItemsRef = useRef(breadcrumbItems);
    const breadcrumbItemOverridesRef = useRef(breadcrumbItemOverrides);
    useEffect(() => {
        breadcrumbsRef.current = breadcrumbs;
    }, [breadcrumbs]);
    useEffect(() => {
        breadcrumbItemsRef.current = breadcrumbItems;
    }, [breadcrumbItems]);
    useEffect(() => {
        breadcrumbItemOverridesRef.current = breadcrumbItemOverrides;
    }, [breadcrumbItemOverrides]);

    // END LINKED VARS --------------------------------------------------------------------------------------------------------------------------------------------

    return (
        <AppContext.Provider value={{
            breadcrumbs, setBreadcrumbs, breadcrumbsRef,
            breadcrumbItems, setBreadcrumbItems, breadcrumbItemsRef,
            breadcrumbItemOverrides, setBreadcrumbItemOverrides, breadcrumbItemOverridesRef,
        }}>
            {children}
        </AppContext.Provider>
    );
}