import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
} from "reactstrap";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
//import { useMsal } from "../../auth/msalContext";

// import transparent_logo from "../../assets/img/transparent/Services_transparent.png";
// import red_logo from "../../assets/img/transparent/Services_transparent.png";
import transparent_logo from "../../assets/img/Services_CMYK.jpg";
// import red_logo from "../../assets/img/Services_CMYK.jpg";
import red_logo from "../../assets/img/logo_services_sm2.png";
import { getWindowDimensions } from "../../utils/index";

// import { AppContext } from "../../App";

function NavbarMain(props) {
    //const { isAuthenticated, user } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { instance } = useMsal();

    const user = instance.getActiveAccount();

    // console.log('instance', instance);
    // console.log('user', user);

    // const { appState, setAppState } = React.useContext(AppContext);
    // const [ companies, setCompanies ] = React.useState([]);
    // const [ projects, setProjects ] = React.useState([]);

    // React.useEffect(() => {
    //     fetch(`${process.env.REACT_APP_WEB_API_BASE_URI}/lookup/company`)
    //     .then(response => response.json())
    //     .then(data => setCompanies(data))
    // }, []);

    const [collapseOpen, toggleCollapse] = React.useState(false);

    const [logo, setLogo] = useState(red_logo);



    React.useEffect(() => {
        let headroom = new Headroom(document.getElementById("navbar-main"), {
            onPin: () => setLogo(red_logo),
            onUnpin: () => setLogo(transparent_logo)
        });
        headroom.init();
    });

    let navbarType = "";
    switch (props.type) {
        case "dark":
            navbarType = "bg-default navbar-dark";
            break;
        case "transparent":
            navbarType = "navbar-transparent";
            break;
        case "primary":
            navbarType = "bg-primary navbar-dark";
            break;
        case "white":
            navbarType = "bg-white";
            break;
        default:
            navbarType = "bg-default navbar-dark";
            break;
    }

    const dimension = getWindowDimensions();
    const authFontSize = dimension.width <= 580 ? "0.6rem" : "1rem";

    return (
        <>
            <Navbar
                className={`navbar-main headroom ${navbarType} navbar-height`}
                expand="lg"
                id="navbar-main"
            >
                <Container style={{ justifyContent: 'flex-start' }}>
                    <NavbarBrand className="mr-lg-5 navbar-brand-custom" to="/" tag={Link}>
                        <img alt="...." src={logo}></img>
                    </NavbarBrand>

                    <h2 className="text-uppercase title mb-0 d-none d-md-block navbar-title-custom">HRIS Extension</h2>

                    <div className="ml-auto">
                        {
                            isAuthenticated && user && <>
                                <span className="mb-0 h6">
                                    {`${user.name} - `}
                                </span>
                                <small className="mb-0 text-uppercase" style={{ fontSize: authFontSize }}>
                                    {user.idTokenClaims.roles && user.idTokenClaims.roles.length > 0 && user.idTokenClaims.roles.sort().join(", ")}
                                </small>
                                <a href="#" className="ml-3" title="Logout" onClick={() => {
                                    //var currentUser = instance.getAccountByHomeId(user.homeAccountId);
                                    //const account = instance.getActiveAccount();
                                    ////account.
                                    //instance.logoutRedirect({
                                    //    account: currentUser,
                                    //    postLogoutRedirectUri: '/'
                                    //});
                                    instance.logout();
                                }}><span className="fas fa-sign-out-alt"></span></a>
                            </>
                        }
                    </div>

                    {!props.noMenu && <>
                        <button
                            className="navbar-toggler"
                            type="button"
                            onClick={() => toggleCollapse(!collapseOpen)}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Collapse
                            id="navbar_global"
                            navbar
                            toggler="#navbar_global"
                            isOpen={collapseOpen}
                        >
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <Link to="/index">
                                            <img
                                                alt="..."
                                                src={red_logo}
                                            ></img>
                                        </Link>
                                    </Col>
                                    <Col className="collapse-close" xs="6">
                                        <button
                                            className="navbar-toggler"
                                            onClick={() => toggleCollapse(!collapseOpen)}
                                        >
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav
                                className="navbar-nav-hover align-items-lg-center ml-lg-auto"
                                navbar
                            >
                                <UncontrolledDropdown nav>
                                    <DropdownToggle
                                        tag={NavLink}
                                        data-toggle="dropdown"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        role="button"
                                    >
                                        <i className="ni ni-tablet-button d-lg-none"></i>
                                        <span className="nav-link-inner--text ">App Pages</span>
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink">
                                        <DropdownItem to="/account-settings" tag={Link}>
                                            <i className="ni ni-lock-circle-open text-muted"></i>
                                            Account Settings
                                        </DropdownItem>
                                        <DropdownItem to="/login-page" tag={Link}>
                                            <i className="ni ni-tv-2 text-danger"></i>
                                            Login Page
                                        </DropdownItem>
                                        <DropdownItem to="/register-page" tag={Link}>
                                            <i className="ni ni-air-baloon text-pink"></i>
                                            Register Page
                                        </DropdownItem>
                                        <DropdownItem to="/reset-page" tag={Link}>
                                            <i className="ni ni-atom text-info"></i>
                                            Reset Page
                                        </DropdownItem>
                                        <DropdownItem to="/invoice-page" tag={Link}>
                                            <i className="ni ni-bullet-list-67 text-success"></i>
                                            Invoice Page
                                        </DropdownItem>
                                        <DropdownItem to="/checkout-page" tag={Link}>
                                            <i className="ni ni-basket text-orange"></i>
                                            Checkout Page
                                        </DropdownItem>
                                        <DropdownItem to="/chat-page" tag={Link}>
                                            <i className="ni ni-chat-round text-primary"></i>
                                            Chat Page
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>
                    </>}

                </Container>


            </Navbar>
        </>
    );
}

NavbarMain.defaultProps = {
    type: "dark",
};

NavbarMain.propTypes = {
    type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
};

export default NavbarMain;