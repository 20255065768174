import moment from "moment";
import { useEffect, useState } from "react";

const formats = [
    "DD/MM/YYYY",
    "YYYY-MM-DDT00:00:00",
    "YYYY-MM-DDTHH:mm:ss",
    "MMM, DD YYYY",
    "YYYY-MM-DD",
    "DD-MM-YYYY",
    "YYYY-MM-DD HH:mm",
    "MM/DD/YYYYTHH:mm:ss",
    "DDD MMM DD YYYY HH:mm:ss",
    "YYYY/MM/DD",
    "DD-MM-YYYY HH:mm:ss",
]

export const BulkConfirmationMode = {
    AVAILABILITY: 'AVAILABILITY',
    LOGISTIC: 'LOGISTIC',
}
export const ViewAccess = {
    READONLY: "READONLY",
    FULLACCESS: "FULLACCESS"
}

export const Mode = {
    NONE: "NONE",
    ADD: "ADD",
    EDIT: "EDIT",
    VIEW: "VIEW",
    COPY: "COPY",
}
export const TermValueSources = [
    {key: 1, value: "Manual Input"},
    {key: 2, value: "Document"}
];

export function formatDate(date, format = formats[0]) {
    
    // console.log("formatDate: ", {
    //     date, 
    //     format, 
    //     isMoment: moment.isMoment(date), 
    //     moment: moment(date, formats, true),
    //     valid: !(moment(date, formats, true) === "Invalid date"),
    //     isValid: moment(date, formats, true).isValid()
    // });

    if (!date) return null;
    
    var original = date;
    if(!moment.isMoment(date)) {
        date = moment(date, formats, true);
        if (!date.isValid()){ 
            return original;
        }
    }
    return date.format(format)
    
}
export function formatDateZeroTime(date) {
    return formatDate(date, formats[1]);
}
export function formatDateTime(date) {
    return formatDate(date, formats[2]);
}
export function formatLongDateTime(date) {
    return formatDate(date, formats[3]);
}

/// <summary>
/// Convert moment to date only
/// </summary>
export function momentToDateOnly(datetime) {
    var m = moment(datetime);

    // Use Moment.js date part extraction methods and then construct new Moment.js object
    let utcDateOnly = moment.utc({ year: m.year(), month: m.month(), date: m.date() });

    // Convert to JS Date
    let jsDate = utcDateOnly.toDate();

    return jsDate;
}
export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function hexToRgb(hex) {
    let c = "";
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return {r: (c>>16)&255, g: (c>>8)&255, b: c&255};
    }
    throw new Error('Bad Hex');
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export function getDayNameOfDate(date, isShort = false) {
    if (!date)
        return;

    const weekDay = [
        {key: 0, short: "Sun", long: "Sunday"},
        {key: 1, short: "Mon", long: "Monday"},
        {key: 2, short: "Tue", long: "Tuesday"},
        {key: 3, short: "Wed", long: "Wednesday"},
        {key: 4, short: "Thu", long: "Thursday"},
        {key: 5, short: "Fri", long: "Friday"},
        {key: 6, short: "Sat", long: "Saturday"},
    ];

    return weekDay.filter(w => w.key === date.getDay()).map(w => isShort ? w.short : w.long)[0];
}

export const currencyFormatterAUD = (value) => {
    const formatter = new Intl.NumberFormat("en-AU", {
        style: 'currency',
        currency: "AUD",
    });

    return value ? formatter.format(value) : null;
};

// export function formatDate(date) {

//     if (!date) return null;

//     if(!moment.isMoment(date)) {
//         date = moment(date);
//         if (date === "Invalid date"){ 
//             return null;
//         }
//     }
//     return date.format("DD/MM/YYYY")
    
// }
  
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const KEY_BACKSPACE = 'Backspace';
export const KEY_DELETE = 'Delete';
export const KEY_F2 = 'F2';
export const KEY_ENTER = 'Enter';
export const KEY_TAB = 'Tab';
export const KEY_ARROW_LEFT = 'ArrowLeft';
export const KEY_ARROW_RIGHT = 'ArrowRight';

export const PNM_StatusPending = "Pending P&M Review";
export const PNM_StatusApproved = "P&M Approved";
export const PNM_StatusRejected = "P&M Rejected";













// export const Pivot = (source, columnSelector, rowSelector, rowPropertyName, dataSelector) 
// => {
//     var columns = Enumerable.from(source).select(columnSelector).distinct();
//     var cols = Enumerable.from([rowPropertyName]).concat(columns.select(s => s));
//     console.log("COLS", cols.toArray());

//     var rows = Enumerable.from(source)
//         .groupBy(rowSelector)
//         .select(rowGroup => {
//             var values = columns.groupJoin(rowGroup, c => c, r => columnSelector(r), (c, columnGroup) => dataSelector(columnGroup));
//             return {
//             key: rowGroup.key(),
//             values: [rowGroup.key(), ...values.toArray()]
//             };
//         }).toArray();

//     var dics = Enumerable.from(rows).select(s => {
//         var result = {};
//         cols.zip(s.values, (left, right) => ({left, right}))
//         .forEach(s => {result = {...result, [s.left]: s.right}});
//         return result;

//     }, {});

//     return dics;
// }

// Enumerable.prototype.pivot = function(columnSelector, rowSelector, rowPropertyName, dataSelector) {
//   return Pivot(this, columnSelector, rowSelector, rowPropertyName, dataSelector);
// }
