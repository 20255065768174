/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from "../../../services/api";
import {
    Button, Modal, Container, Row, Col, TabContent, TabPane, Nav, NavLink, NavItem, Card, Alert
 } from "reactstrap"; 
import Loader from "../../loaders";
import moment from "moment";
import { useMsal } from "@azure/msal-react";
import { Address, Contact, Emergency, Interview, Notes, Profile, Recruitment, Residency, Skills, Requests, NotificationHistory, AllocationHistory, Flights, Identifiers, Events, InactiveDates, Documents } from "./Tabs";
import { v4 } from "uuid";
import jsonFormData from "json-form-data";
import { formatDate, formatDateZeroTime, hexToRgb } from "../../../utils";
import EditorSMS from "./EditorSMS";
import EditorEmail from "./EditorEmail";
import ProjectAttributes from './Tabs/ProjectAttributes';
import Positions from './Tabs/Positions';
import DispatchHistory from './Tabs/DispatchHistory';
import SiteAccess from './Tabs/SiteAccess'
import { ErrorNotification, ErrorResponseValidation } from '../../alerts';
import TrainingHistory from './Tabs/TrainingHistory';
import { TooltipButton } from '../../inputs';
import TabList from './TabList';
import NavbarPage from '../../navbars/NavbarPage';

function Editor(props) {
    const tabs = [...TabList];
    const defaultActiveTab = props.item.project ? 10 : 0;
    
    const [itemInEdit, setItemInEdit] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(defaultActiveTab);
    const [borderStyle, setBorderStyle] = useState({});
    const [headerStyle, setHeaderStyle] = useState({});
    const [footerStyle, setFooterStyle] = useState({});
    const [backgroundStyle, setBackgroundStyle] = useState({});
    const [titleStyle, setTitleStyle] = useState({});
    const [smsFromContact, setSmsFromContact] = useState([]);
    const [emailFromContact, setEmailFromContact] = useState([]);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [errorDetails, setErrorDetails] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorTitle, setErrorTitle] = useState(null);
    const [errorNotification, setErrorNotification] = useState([]);
    const [errorMessageOpeningTemplate, setErrorMessageOpeningTemplate] = useState(`Error occured when processing the request: `);
    const [errorMessageOpening, setErrorMessageOpening] = useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = useState(`Candidate Profile Error`);
    const [errorMessageClassSubfix, setErrorMessageClassSubfix] = useState("");
    const [getProfileData, setGetProfileData] = useState(false);
    const [closeOnSave, setCloseOnSave] = useState(false);
    const [editMode, setEditMode] = useState();

    const loadData = (id) => {
        setLoading(true);

        api.get(`candidate/${id}`)
            .then((response) => {

                const {
                    candidateNotes,
                    candidatePhones,
                    ...rest
                } = response.data;

                setItemInEdit({
                    ...rest,
                    candidateNotes: candidateNotes && candidateNotes.length > 0
                        ? candidateNotes.map((item) => ({ ...item, uid: v4() }))
                        : [],
                    candidateInactiveDates: [],
                    candidateDocuments: [],
                    shouldRefreshDocuments: true,
                    candidateSkills: [],
                    candidatePhones: candidatePhones && candidatePhones.length > 0
                        ? candidatePhones.map((item) => ({ ...item, uid: v4() }))
                        : [],
                });
            })
            .catch((error) => {
                console.error(error);
                var errorMsg = ErrorResponseValidation(error);

                // Display error message
                setErrorMessageOpening(errorMessageOpeningTemplate);
                setErrorMessage(errorMsg);
            })
            .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        if (props.item.candidateId > 0) {
            loadData(props.item.candidateId);
        } else {
            setItemInEdit(props.item)
        }
    }, [props.item]);

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const handleDateChanged = (value, name) => {
        setItemInEdit({
            ...itemInEdit,
            [name]: !value ? null : formatDateZeroTime(value) //moment(value).format("YYYY-MM-DDT00:00:00") 
        });
    }



    const handleSave = (item, close = false) => {
        setLoading(true);

        try {

            // Validate email
            let emailFields = [];
            for (const [key] of Object.entries(item)) {
                if (key.includes("email")) {
                    emailFields.push(key)
                }
            }
            console.log('emailFields', emailFields);
            var validator = require("email-validator");
            var allEmailValid = true;
            for (let i = 0; i < emailFields.length; i++) {

                // Validate email address
                if (emailFields[i].toLowerCase() == "email" && !item[emailFields[i]]) {
                    setLoading(false);
                    setErrorTitle(errorTitleOnGenerate);
                    setErrorMessageOpening("");
                    setErrorMessage("Please input Email Address");
                    return;
                }
                else if (item[emailFields[i]] === "" || item[emailFields[i]] === null) {
                    continue;
                }
                else if (!validator.validate(item[emailFields[i]])) {

                    setEmailInvalid(true);
                    setLoading(false);
                    allEmailValid = false;
                    break;
                }
            }


            // Validate mobile phone number
            if (!item["mobilePhoneNumber"]) {
                setLoading(false);
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessageOpening("");
                setErrorMessage("Please input Mobile Phone Number");
                return;
            }

            // Validate inclusion and exclusion
            if (item.candidateClientInclusions && item.candidateClientExclusions &&
                item.candidateClientExclusions.some(w => item.candidateClientInclusions.some(w2 => w2.clientId == w.clientId))) {
                var errMessage = "Client Exclusion Preference can't contains item which already in Client Inclusion Preference";
                setLoading(false);

                // Display error message
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessageOpening(errorMessageOpeningTemplate);
                setErrorMessage(errMessage);
                return;
            }
        }
        catch (error) {
            setLoading(false);
            console.error(error, error.message);

            // Display error message
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessageOpening(errorMessageOpeningTemplate);
            setErrorMessage(error.message);
            return;
        }

        if (allEmailValid) {
            try {
                var form = jsonFormData(item, {
                    initialFormData: new FormData(),
                    showLeafArrayIndexes: true,
                    includeNullValues: false,
                    mapping: function (value) {
                        // if (typeof value === 'boolean') {
                        //     return +value ? '1': '0';
                        // }
                        return value;
                    }
                });
            }
            catch (error) {
                setLoading(false);
                console.error(error, error.message);

                setErrorMessageOpening(errorMessageOpeningTemplate);
                setErrorMessage(error.message);
                return;
            }

            // Handle file upload
            const formData = new FormData();
            form.forEach((value, key, parent) => {

                if (value instanceof File || value instanceof Blob) {
                    formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2").replace(/\[\d+\]$/, ""), value, value.name)
                }
                else {
                    formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2"), value)
                }
            })

            //const data = JSON.stringify(item);

            // const save = item.candidateId > 0 
            //     ? api.put(`candidate/${item.candidateId}`, formData, {headers: { 'content-type': 'multipart/form-data' }})
            //     : api.post(`candidate`, formData, {headers: { 'content-type': 'multipart/form-data' }})

            let url = `${process.env.REACT_APP_WEB_API_BASE_URI}/candidate`;
            let method = "POST";

            if (item.candidateId > 0) {
                url += `/${item.candidateId}`;
                method = "PUT";
            }

            const save = api({ url, method, data: formData })

            save
                .then((response) => {
                    setEmailInvalid(false);
                    setLoading(false);

                    if (close) {
                        props.onSaved();
                        return;
                    }

                    if (!close && !item.candidateId) {
                        // console.log("new save", response);
                        const newId = response.data.candidateId;
                        loadData(newId);
                    }
                    else if (!close && item.candidateId) {
                        loadData(item.candidateId);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setEmailInvalid(false);
                    console.error({ error });


                    // Compile error message from server
                    var errorMsg = ErrorResponseValidation(error);
                    var part = errorMsg ? errorMsg.split('||') : [];

                    // If using error message from server
                    if (part.length >= 3) {
                        var errorMsgStr = part[0];
                        var errorType = part[1];
                        var errorData = part[2];

                        // Display error message
                        setErrorMessageOpening(errorMessageOpeningTemplate);
                        setErrorMessage(errorMsgStr);

                        // Error type contains email and contains error
                        if (errorType.includes("duplicate") && errorType.includes("error")) {
                            var errorDataObj = JSON.parse(errorData);
                            var errorDetails = <div>
                                <ol>
                                    {errorDataObj.map((item, index) => (
                                        <li key={`cd-${index}`}><a target="_blank" href={`talents/${item.CandidateId}`}><u>{item.CandidateName}</u></a></li>
                                    ))}
                                </ol>
                            </div>;
                            //setErrorMessageClassSubfix("text-left");
                            setErrorDetails(errorDetails);

                            return;
                        }
                        return;
                    }


                    // Display error message
                    setErrorMessageOpening(errorMessageOpeningTemplate);
                    setErrorMessage(errorMsg);
                    return;
                })
            ;
        }
    }

    useEffect(() => {
        if (!itemInEdit || !itemInEdit?.candidateTypeModel || !itemInEdit?.candidateTypeModel?.themeColor) {
            setBorderStyle({});
            setHeaderStyle({});
            setFooterStyle({});
            setBackgroundStyle({});
            setTitleStyle({});
            return;
        }


        const { candidateTypeModel } = itemInEdit;
        const { themeColor } = candidateTypeModel;
        const { r, g, b } = hexToRgb(themeColor);

        console.log((r + g + b) / 3);

        if ((r + g + b) / 3 <= 150)
            setTitleStyle({ color: "#FFF" });
        else
            setTitleStyle({});

        setBorderStyle({ border: `2px solid ${themeColor}` });
        setHeaderStyle({ backgroundColor: themeColor });
        setFooterStyle({ backgroundColor: themeColor });
        setBackgroundStyle({ backgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)` });
    }, [itemInEdit, itemInEdit?.candidateTypeModel, itemInEdit?.candidateTypeModel?.colorTheme]);

    //useEffect(() => {
    //    if (!itemInEdit || !itemInEdit.defaultProject || !itemInEdit.defaultProject.themeColor)
    //    {
    //        setBorderStyle({});
    //        setHeaderStyle({});
    //        setFooterStyle({});
    //        setBackgroundStyle({});
    //        setTitleStyle({});
    //        return;
    //    }
        
    //    const {defaultProject} = itemInEdit;
    //    const {themeColor} = defaultProject;
    //    const {r, g, b} = hexToRgb(themeColor);
    //    console.log((r + g + b) / 3);

    //    if ((r + g + b) / 3 <= 150)
    //        setTitleStyle({color: "#FFF"});
    //    else
    //        setTitleStyle({});

    //    setBorderStyle({border: `2px solid ${themeColor}`});
    //    setHeaderStyle({backgroundColor: themeColor});
    //    setFooterStyle({backgroundColor: themeColor});
    //    setBackgroundStyle({backgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)`});
    //}, [itemInEdit?.defaultProject]);

    useEffect(() => {
        if (!getProfileData)
            return;
        
        setGetProfileData(false);
        handleSave(itemInEdit, closeOnSave);
    }, [itemInEdit]);

    const tabProps = {
        itemInEdit, 
        setItemInEdit,
        handleTextChanged,
        handleDateChanged,
        lookups: props.lookups,
        isPage: false,
    };

    // BEGIN ERROR MESSAGE HANDLER --------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorDetails(null);
                setErrorMessage(null);
                setErrorTitle(null);
            },
            errorDetails: errorDetails,
            message: errorMessage,
            messageOpening: errorMessageOpening,
            title: errorTitle ?? errorTitleOnGenerate,
            messageClassSubfix: errorMessageClassSubfix
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle, errorDetails, errorMessageClassSubfix, errorMessageOpening, errorTitleOnGenerate]);
    // END ERROR MESSAGE HANDLER -----------------------------------------------------------------------------------------------------------------------

    // console.log('candidate: ', itemInEdit)
    return (
        <Modal
            isOpen={true}
            fullscreen={'true'}
            className="modal-fullscreen modal-fullscreen-xl"
            modalClassName=""
        >
            <div
                className="modal-header my-0"
                style={headerStyle}
            >
                <div className="d-flex flex-wrap justify-content-start align-items-center">
                    <h5
                        className="modal-title"
                        style={titleStyle}
                    >
                        {itemInEdit.candidateId ? itemInEdit.candidateName : "Create Talent"}
                    </h5>
                {
                    itemInEdit?.candidateId ?
                    <TooltipButton
                        id={`open-new-window`}
                        title="Open in New Window"
                        className="btn-icon ml-2"
                        color="default"
                        type="button"
                        onClick={() => {
                            window.open(`/talent/${itemInEdit.candidateId}`, '_blank');
                            props.onClose();
                        }}
                    >
                        <i className="fas fa-external-link-alt" />
                    </TooltipButton>
                    :
                    null
                }
                </div>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true} style={titleStyle}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <NavbarPage
                name='talent'
                onTabClick={(t) => {
                    setActiveTab(t.tabIndex);
                }}
                tabs={tabs}
                defaultActiveTab={defaultActiveTab}
            />
            <div
                className="modal-body my-0 py-0 h-100"
                style={{...borderStyle, ...backgroundStyle}}
            >
                <Container fluid className="px-0 h-100">
                    {(loading) && <Loader />}
                    {errorNotification.length > 0 && errorNotification}
                    {emailInvalid &&
                    <Alert
                        color="warning" className="mb-0 d-flex mx-auto justify-content-between" 
                    >
                        <ul className="mb-0 px-0 mx-auto">
                            Please check email(s) have been entered correctly
                        </ul>
                    </Alert>
                    }
                    <Row className='h-100'>
                        <Col md={12}>
                            <div className="nav-wrapper pt-0 h-100 d-flex flex-column">
                                
                        {/* </Col>
                        <Col md={9}> */}
                                <Card className="card-plain no-transition">
                                    <TabContent className="mt-2" activeTab={activeTab}>
                                        <TabPane tabId={0}> {/* Profile */}
                                            <Profile
                                                {...tabProps}
                                                setSmsFromContact={setSmsFromContact}
                                                setEmailFromContact={setEmailFromContact}
                                                isSaving={getProfileData}
                                            />
                                        </TabPane>
                                        <TabPane tabId={4}> {/* Career / Skills */}
                                            <Skills {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={5}> {/* Recruitment */}
                                            <Recruitment {...tabProps} /> 
                                        </TabPane>
                                        <TabPane tabId={6}> {/* Interview */}
                                            <Interview {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={8}> {/* Interview */}
                                            <Documents {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={9}> {/* Requests */}
                                            <Requests {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={10}> {/* Requests */}
                                            <ProjectAttributes {...tabProps} project={props.item.project} />
                                        </TabPane>
                                        <TabPane tabId={17}> {/* Requests */}
                                            <DispatchHistory {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={11}> {/* Allocation History */}
                                            <AllocationHistory {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={16}> {/* Flights */}
                                            <Flights {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={12}> {/* Identifiers */}
                                            <Identifiers {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={13}> {/* Events */}
                                            <Events {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={14}> {/* Inactive Dates */}
                                            <InactiveDates {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={15}> {/* Positions */}
                                            <Positions {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={18}> {/* Site Access */}
                                            <SiteAccess {...tabProps} />
                                        </TabPane>
                                        <TabPane tabId={19}> {/* Site Access */}
                                            <TrainingHistory {...tabProps} />
                                        </TabPane>
                                    </TabContent>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer flex-row"
                style={footerStyle}
            >
                <Button
                    color="secondary"
                    onClick={() => {
                        props.onClose();
                        setEmailInvalid(false);
                    }}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => {
                        // handleSave(itemInEdit, true);
                        setCloseOnSave(true);
                        setGetProfileData(true);
                    }}
                    type="button"
                >
                    Save &amp; Close
                </Button>
            {
                itemInEdit?.candidateId ?
                <Button
                    color="info"
                    onClick={() => {
                        // handleSave(itemInEdit, false);
                        setCloseOnSave(false);
                        setGetProfileData(true);
                    }}
                    type="button"
                >
                    Save
                </Button>
                :
                null
            }
            </div>
            {
                smsFromContact?.length > 0 &&
                <EditorSMS
                    items={smsFromContact} 
                    onClose={() => setSmsFromContact([])}
                    onSaved={() => setSmsFromContact([])}
                    lookups={{
                        ...props.lookups
                    }}
                    defaultShowProjectAttribute={false}
                />
            }
            {
                emailFromContact?.length > 0 &&
                <EditorEmail
                    items={emailFromContact} 
                    onClose={() => setEmailFromContact([])}
                    onSaved={() => setEmailFromContact([])}
                    lookups={{
                        ...props.lookups
                    }}
                />
            }
        </Modal>
    )
}

export default Editor;
