/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import {
    Container, Row, Col, Label, FormGroup, Button, Alert, Badge, Input, Modal
} from "reactstrap";
import { v4, validate as uuidValidate } from "uuid";
import _ from "lodash";
import { DropdownBox, ComboBox } from "../../dropdowns";
import api from "../../../services/api";
import Loader from "../../loaders";
import LabourRequestDetailModal from "./LabourRequestDetailModal";
import LabourRequestDetailList from "./LabourRequestDetailList";
import LabourRequestDetailListV2 from "./LabourRequestDetailListV2";
import { labourRequestValidation } from "./validation";
import { PleaseCompleteAlert, MissingApproverAlert } from "../../alerts";
import moment from "moment";
import { DropzoneList, FileDropzone, MultipleDropzoneList } from '../../FileDropzone';
import fileDownload from 'js-file-download';
import { IndeterminateCheckbox } from '../../react-table/controls';
import { roles } from '../../../auth/roles';
import { useMsal } from '@azure/msal-react';
import { PNM_StatusApproved, PNM_StatusPending } from '../../../utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import qs from "qs";

const yesNoOptions = [{lookupId: true, lookupValue: "Yes"},{lookupId: false, lookupValue: "No"},];
const defaultAttachmentCaption = <span>Attachment</span>;
const pendingStatusName = "Pending";
const pendingRedZoneStatusName = "Pending Red Zone";
const pendingBudgetSightingStatusName = "Pending Budget Sighting";
const rejectedStatusName = "Rejected";
const redZoneRejectionStatusName = "Red Zone Rejected";
const hrDirectorRejectionStatusName = "HR Director Rejected";
const budgetSightingRejectionStatusName = "HR Director Rejected - Budget Disputed";
const approvedStatusName = "Approved";
const completedStatusName = "Completed";

function LabourRequestForm(props) {
    const {
        location: {search: locationSearch}
    } = props;
    const searchParams = qs.parse(locationSearch, { ignoreQueryPrefix: true });
    const isApproval = searchParams?.isapproval;
    // console.log({searchParams, isApproval});

    const { id = 0 } = useParams();
    const checkType = [{ key: 0, type: "none" }, { key: 1, type: "warning" }, { key: 2, type: "blocking" }];
    const history = useHistory();
    //const { token } = useMsal();

    const [request, setRequest] = React.useState({});
    const [initialApprover, setInitialApprover] = useState(null);
    const [approvalData, setApprovalData] = useState(null);
    const [loading, setLoading] = React.useState(false);

    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [validationAlert, setValidationAlert] = React.useState([]);
    const [alert, setAlert] = React.useState([]);
    const [projectStatus, setProjectStatus] = React.useState(null);
    const [approverAlert, setApproverAlert] = React.useState([]);
    const [requestValidation, setRequestValidation] = useState(false);
    const [rateCompleted, setRateCompleted] = useState(true);
    const [actualRosterRequired, setActualRosterRequired] = useState([]);
    const [readyToSave, setReadyToSave] = useState(false);
    const [showRejectReasonDialog, setShowRejectReasonDialog] = useState(false);
    const [rejectReason, setRejectReason] = useState(null);
    const [isApproverExists, setIsApproverExists] = useState(true);
    const [rejectedByHrDirectorTeam, setRejectedByHrDirectorTeam] = useState(false);
    const [rejectedByRedZone, setRejectedByRedZone] = useState(false);
    const [rejectionStatus, setRejectionStatus] = useState(null);
    const [showDateWarning, setShowDateWarning] = useState(false);
    const [attachmentCaption, setAttachmentCaption] = useState(defaultAttachmentCaption);
    const [workorderAlert, setWorkorderAlert] = useState(null);
    const [refreshInputValue, setRefreshInputValue] = useState(false);
    const [newOnBoardWorkorder, setNewOnBoardWorkorder] = useState(null);

    const [lookups, setLookups] = React.useState({
        recruitmentTypes: [],
        employmentTypes: [],
        payProcessTypes: [],
        newOrReplacements: [],
        shifts: [],
        siteTravelTypes: [],
        pointOfHires: [],
        rosters: [],
        rosterSequences: [],
        flightBooked: [],
        accomodationTypes: [],
        mealsProvider: [],
        transferTypes: [],
        computerRequirements: [],
        phoneRequirements: [],
        approvalStatus: [],
        costCodes: [],
        employmentCategories: [],
        companies: [],
        departments: [],
        itsystemAccesses: [],
    });

    const { instance } = useMsal();
    const user = instance.getActiveAccount();
    const isUserAdmin = user?.idTokenClaims.roles?.filter(r => r === roles.Admin).length > 0;

    React.useEffect(() => {

        setLoading(true);
        let apiCalls = [
            api.get(`/lookup/recruitmenttype`), // 0
            api.get(`/lookup/employmenttype`), // 1
            api.get(`/lookup/payprocesstype`), // 2
            api.get(`/lookup/neworreplacement`), // 3
            api.get(`/lookup/shift`), // 4
            api.get(`/lookup/sitetraveltype`), // 5
            api.get(`/lookup/pointofhire`), // 6
            api.get(`/lookup/roster`), // 7
            api.get(`/lookup/flightbooked`), // 8
            api.get(`/lookup/accomodationtype`), // 9
            api.get(`/lookup/mealsprovider`), // 10
            api.get(`/lookup/transfertype`), // 11
            api.get(`/lookup/computerrequirement`), // 12
            api.get(`/lookup/phonerequirement`), // 13
            api.get(`/lookup/ApprovalStatus`), // 14
            api.get(`/lookup/costcodes`), // 15
            api.get(`/lookup/employmentcategory`), // 16
            api.get(`/lookup/company`), // 17
            api.get(`/lookup/department`), // 18
            api.get(`/itsystemaccess/active`), //19
            api.get(`/lookup/recruitmentcategory`), //20
            api.get(`/lookup/sysconfig/LRF Justification Template URL`), //21
            api.get(`/rostersequence/search?filter=`), //22
        ];
        if (id > 0) apiCalls.push(api.get(`/recruitmentrequest/${id}`)); // 23
        if (searchParams?.approvalid) apiCalls.push(api.get(`/recruitmentrequest/approval/${searchParams.approvalid}`)); // 24
        Promise.all(apiCalls)
            //.then((response) => Promise.all(response.map((res) => res.json())))
            .then((data) => {
                setLookups({
                    recruitmentTypes: data[0].data,
                    employmentTypes: data[1].data,
                    payProcessTypes: data[2].data,
                    newOrReplacements: data[3].data,
                    shifts: data[4].data,
                    siteTravelTypes: data[5].data,
                    pointOfHires: data[6].data,
                    rosters: data[7].data,
                    rosterSequences: data[22].data,
                    flightBooked: data[8].data,
                    accomodationTypes: data[9].data,
                    mealsProvider: data[10].data,
                    transferTypes: data[11].data,
                    computerRequirements: data[12].data,
                    phoneRequirements: data[13].data,
                    approvalStatus: data[14].data,
                    costCodes: data[15].data,
                    employmentCategories: data[16].data,
                    companies: data[17].data,
                    departments: data[18].data,
                    itsystemAccesses: data[19].data,
                    recruitmentCategories: data[20].data,
                    lrfJustificationTemplateUrl: data[21].data,
                });

                if (id > 0) {
                    // console.log(data[20].data);
                    setRequest(data[23].data);
                }

                if (searchParams?.approvalid)
                    setApprovalData(data[24].data);
            })
            .catch((error) => console.error({ error }))
            .finally(() => setLoading(false));

    }, [id]);

    const validate = React.useCallback((checkDetails = false, isDraft = false) => {
        const errors = [...validationAlert];
        _.forEach(labourRequestValidation, (value) => {
            _.forEach(value.tests, (test) => {
                if (test.type === "details" && !checkDetails)
                    return;

                const exists = _.some(errors, (item) => item.key === value.field && item.type === test.type);
                const result = test.validate({...request, newOnBoardWorkorder});
                if (result && !exists) {
                    errors.push({ key: value.field, type: test.type, message: test.type === "details" ? `Details - ${test.validateDetail(request).join(", ")} must be filled.` : test.message });
                } else if (!result && exists) {
                    _.remove(errors, (item) => item.key === value.field && item.type === test.type);
                }
            })
        });

        if (errors.filter(e => e.key === "position").length > 0 && isDraft)
            _.remove(errors, (item) => item.key === "position");

        setValidationAlert([...errors]);
        setRequestValidation(false);
        if (errors.length > 0) {
            return false;
        }
        return true;
    }, [request, validationAlert, newOnBoardWorkorder]);

    const validateWorkorderNumber = useCallback((currentStatus, checkDetails = false, isRejection = false, approvalStatus = null) => {
        if (newOnBoardWorkorder && !workorderAlert?.confirmed)
        {
            setWorkorderAlert({
                title: "New Onboarding Cost Code Confirmation",
                message: `Onboarding Cost Code ${newOnBoardWorkorder} will be saved as a new cost code. Do you want to continue?`,
                confirmed: false,
                currentStatus,
                checkDetails,
                isRejection,
                approvalStatus,
            });
            return false;
        }

        return true;
    }, [request, workorderAlert, newOnBoardWorkorder]);

    const checkMinDate = useCallback(() => {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 15);
        
        return request?.requestDetails?.filter(r => r.startDateExpected && r.startDateExpected < moment(minDate).format("YYYY-MM-DD")).length > 0 ? false : true;
    }, [request]);

    React.useEffect(() => {
        // console.log("changed", {request});
        if (!request)
            return;

        if (!request.projectId || !request.requestDetails?.length) {
            setRequestValidation(true);
            return;
        }

        if (request.projectId && request.requestDetails?.length) {
            setLoading(true);

            const errors = [...validationAlert.filter(v => v.key !== "position")];
            const apiCalls = [];

            request.requestDetails.forEach(d => {
                if (!d.positionId)
                    return;

                apiCalls.push(
                    api.get(`/rates/checkrate?projectId=${request.projectId}&positionId=${d.positionId}`)
                );
            });

            Promise.all(apiCalls)
                .then(responses => {
                    responses.forEach(r => {
                        const { data } = r;
                        // console.log(data);
                        const itemKey = "position";
                        const itemType = `checkPosition${data.positionId}`;
                        const exists = _.some(errors, (item) => item.key === itemKey && item.type === itemType);
                        if (!data.checkResult && !exists)
                            errors.push({ key: itemKey, type: itemType, message: `Position ${data.positionName} for Project ${data.projectName} doesn't have any Rate.` });

                        if (data.checkResult && exists)
                            _.remove(errors, (item) => item.key === itemKey && item.type === itemType);

                        setRateCompleted(data.checkResult ? true : false);
                    });

                    setValidationAlert([...errors]);
                    setRequestValidation(true);
                })
                .catch(error => {
                    console.error({ error });
                })
                .finally(() => setLoading(false))
                ;
        }
    }, [request]);

    useEffect(() => {
        if (request && requestValidation && validationAlert.length)
            validate(request?.status?.lookupValue !== "Draft");
    }, [requestValidation]);

    useEffect(() => {
        if (!request.projectId) {
            setProjectStatus(null);
            return
        }
        setLoading(true);
        api.get(`${process.env.REACT_APP_WEB_API_BASE_URI}/recruitmentrequest/projectstatus/${request.projectId}`)
            .then(response => {
                setProjectStatus(response.data);
            })
            .catch((error) => {

            })
            .finally(() => setLoading(false));
    }, [request.projectId]);

    const updateStatus = useCallback((status, reason = "", callback = null) => {
        if (request?.recruitmentType?.lookupValue.toLowerCase().includes("white") && !request?.recruitmentCategoryId)
        {
            setValidationAlert([{
                key: "recruitmentCategory",
                type: "required",
                message: "Recruitment Category must be filled.",
            }]);
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("recruitmentRequestId", request?.recruitmentRequestId ?? 0);

        if (approvalData?.recruitmentApprovalId)
            formData.append("recruitmentApprovalId", approvalData.recruitmentApprovalId);
        
        formData.append("statusValue", status);
        formData.append("reason", reason);

        for (let key in request)
        {
            if (key === "recruitmentRequestAttachments" && request.recruitmentRequestAttachments) {
                // console.log(request.recruitmentRequestAttachments);
                request.recruitmentRequestAttachments.forEach((d, idx) => {
                    // console.log(d);
                    formData.append(`${key}[${idx}].recruitmentAttachmentId`, d.recruitmentAttachmentId);
                    formData.append(`${key}[${idx}].recruitmentRequestId`, d.recruitmentRequestId);
    
                    if (d.file) {
                        // console.log(d.file);
                        formData.append(`${key}[${idx}].file`, d.file);
                        formData.append(`${key}[${idx}].fileContent`, null);
                        formData.append(`${key}[${idx}].fileType`, "");
                        formData.append(`${key}[${idx}].fileSizeInBytes`, "");
                        formData.append(`${key}[${idx}].filename`, "");
                        return;
                    }
    
                    // console.log(d.file);
                    formData.append(`${key}[${idx}].fileContent`, d.fileContent);
                    formData.append(`${key}[${idx}].fileType`, d.fileType);
                    formData.append(`${key}[${idx}].fileSizeInBytes`, d.fileSizeInBytes);
                    formData.append(`${key}[${idx}].filename`, d.filename);
                })
                continue;
            }

            if (key === "deletedRecruitmentRequestAttachments" && request.deletedRecruitmentRequestAttachments) {
                // console.log(request.deletedRecruitmentRequestAttachments);
                request.deletedRecruitmentRequestAttachments.forEach((d, idx) => {
                    formData.append(`${key}[${idx}]`, d);
                })
                continue;
            }
        }

        api({
            url: "recruitmentrequest/status",
            method: "POST",
            data: formData,
        })
        // api.post("recruitmentrequest/status", JSON.stringify({
        //     recruitmentRequestId: request?.recruitmentRequestId ?? 0,
        //     recruitmentApprovalId: approvalData?.recruitmentApprovalId ?? null,
        //     statusValue: status,
        //     reason,
        // }))
            .then(() => {
                setLoading(false);

                if (callback)
                    callback();

                // if (status === approvedStatusName)
                // {
                //     if (!checkMinDate())
                //     {
                //         setShowDateWarning(true);
                //         return;
                //     }
                // }

                history.push(isApproval ? "/lrfapproval" : "/lrflist");
            })
            .catch((error) => {
                console.error({error});
                const info = error?.response?.data;
                setLoading(false);
                
                if (info && info.project && info.location) {
                    setApproverAlert([<MissingApproverAlert
                        onConfirm={() => setApproverAlert([])}
                        message={`Labour Request Approver not set for Project: ${info.project.projectName}, Location: ${info.location.locationName}`}
                    />]);
                    return;
                }
                
                if (info && info.failedEmails)
                {
                    setApproverAlert([
                        <SweetAlert
                            error
                            showCancel={false}
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            title={<h4 className="mb-0">System Error</h4>}
                            onConfirm={() => setApproverAlert([])}
                            focusCancelBtn
                        >
                            <p>Email is failed to send. Please contact the administrator.</p>
                        </SweetAlert>
                    ]);
                    return;
                }
            })
        ;
    }, [request, isApproval, approvalData, checkMinDate]);

    const sendEmailToRecruitment = useCallback(() => {
        api.post(`recruitmentrequest/notify/recruitment?id=${request?.recruitmentRequestId ?? 0}`)
            .then(() => {
                setLoading(false);
                history.push(isApproval ? "/lrfapproval" : "/lrflist");
            })
            .catch((error) => {
                console.error({error});
                setApproverAlert([
                    <SweetAlert
                        error
                        showCancel={false}
                        confirmBtnText="Ok"
                        confirmBtnBsStyle="danger"
                        title={<h4 className="mb-0">System Error</h4>}
                        onConfirm={() => setApproverAlert([])}
                        focusCancelBtn
                    >
                        <p>Email is failed to send. Please contact the administrator.</p>
                    </SweetAlert>
                ]);
                setLoading(false);
            })
        ;
    }, [request, isApproval, approvalData, checkMinDate]);

    const saveRequest = useCallback((currentStatus = null, checkDetails = false, isRejection = false, approvalStatus = null) => {
        setLoading(true);
        if (!validate(checkDetails, currentStatus === "Draft")) {
            setLoading(false);
            return;
        }

        if (!workorderAlert?.confirmed && !validateWorkorderNumber(currentStatus, checkDetails, isRejection, approvalStatus))
        {
            setLoading(false);
            return;
        }

        // console.log(request);

        let newStatusLookup = currentStatus ? lookups.approvalStatus.filter(f => f.lookupValue === currentStatus)[0] : {};
        const formData = new FormData();
        formData.append("StatusId", newStatusLookup.lookupId);

        if (newOnBoardWorkorder)
            formData.append("newOnBoardWorkorder", newOnBoardWorkorder);

        for (let key in newStatusLookup)
            formData.append(`Status.${key}`, newStatusLookup[key] == null ? "" : newStatusLookup[key]);

        console.log('', { newStatusLookup });

        for (let key in request) {
            // console.log(key);
            if (key !== "requestDetails" && key !== "deletedRequestDetails" && key !== "recruitmentRequestAttachments" && key !== "deletedRecruitmentRequestAttachments") {
                // console.log({data: request[key]});

                if (typeof request[key] === "object") {
                    for (let dkey in request[key]) {
                        if (request[key][dkey])
                            formData.append(`${key}.${dkey}`, request[key][dkey]);
                    }
                    continue;
                }

                if (request[key] !== undefined)
                    formData.append(`${key}`, request[key]);

                continue;
            }

            if (key === "requestDetails") {
                // console.log(request.requestDetails);
                request.requestDetails.forEach((d, idx) => {
                    for (let dkey in d) {
                        if (typeof d[dkey] === "object") {
                            if (dkey === "requestDetailRehires" && d.requestDetailRehires?.length) {
                                // console.log({dkey, dt: d[dkey]});
                                d.requestDetailRehires.forEach((r, ridx) => {
                                    for (let ckey in r) {
                                        // console.log({dkey, dt: d[dkey], rt: r[ckey], strkey: `${key}[${idx}].${dkey}[${ridx}].${ckey}`});
                                        if (ckey === "requestDetailId") {
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, uuidValidate(d.requestDetailId) ? 0 : d.requestDetailId);
                                            continue;
                                        }

                                        if (typeof r[ckey] === "object")
                                            continue;

                                        if (r[ckey])
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, r[ckey]);
                                    }
                                });

                                continue;
                            }

                            if (dkey === "requestDetailItsystemAccesses" && d.requestDetailItsystemAccesses?.length) {
                                // console.log({dkey, dt: d[dkey]});
                                d.requestDetailItsystemAccesses.forEach((r, ridx) => {
                                    for (let ckey in r) {
                                        // console.log({dkey, dt: d[dkey], rt: r[ckey], strkey: `${key}[${idx}].${dkey}[${ridx}].${ckey}`});
                                        if (ckey === "requestDetailId") {
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, uuidValidate(d.requestDetailId) ? 0 : d.requestDetailId);
                                            continue;
                                        }

                                        if (typeof r[ckey] === "object")
                                            continue;

                                        if (r[ckey])
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, r[ckey]);
                                    }
                                });

                                continue;
                            }
                            if (dkey === "selectedRosterPositions" && d.selectedRosterPositions?.length) {
                                // console.log({dkey, dt: d[dkey]});
                                d.selectedRosterPositions.forEach((r, ridx) => {
                                    for (let ckey in r) {
                                        // console.log({dkey, dt: d[dkey], rt: r[ckey], strkey: `${key}[${idx}].${dkey}[${ridx}].${ckey}`});
                                        if (ckey === "requestDetailId") {
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, uuidValidate(d.requestDetailId) ? 0 : d.requestDetailId);
                                            continue;
                                        }

                                        if (typeof r[ckey] === "object")
                                            continue;

                                        if (r[ckey])
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, r[ckey]);
                                    }
                                });

                                continue;
                            }

                            if (dkey === "recruitmentCurrentEmployees" && d.recruitmentCurrentEmployees?.length) {
                                // console.log({dkey, dt: d[dkey]});
                                d.recruitmentCurrentEmployees.forEach((r, ridx) => {
                                    for (let ckey in r) {
                                        // console.log({dkey, dt: d[dkey], rt: r[ckey], strkey: `${key}[${idx}].${dkey}[${ridx}].${ckey}`});
                                        if (ckey === "requestDetailId") {
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, uuidValidate(d.requestDetailId) ? 0 : d.requestDetailId);
                                            continue;
                                        }

                                        if (ckey === "recruitmentCurrentEmployeeId") {
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, uuidValidate(r.recruitmentCurrentEmployeeId) ? 0 : r.recruitmentCurrentEmployeeId);
                                            continue;
                                        }

                                        if (typeof r[ckey] === "object" || typeof r[ckey] === "array")
                                            continue;

                                        if (r[ckey])
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, r[ckey]);
                                    }
                                });

                                continue;
                            }

                            if (dkey === "recruitmentCurrentBenefits" && d.recruitmentCurrentBenefits?.length) {
                                // console.log({dkey, dt: d[dkey]});
                                d.recruitmentCurrentBenefits.forEach((r, ridx) => {
                                    for (let ckey in r) {
                                        // console.log({dkey, dt: d[dkey], rt: r[ckey], strkey: `${key}[${idx}].${dkey}[${ridx}].${ckey}`});
                                        if (ckey === "requestDetailId") {
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, uuidValidate(d.requestDetailId) ? 0 : d.requestDetailId);
                                            continue;
                                        }

                                        if (ckey === "recruitmentCurrentBenefitId") {
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, uuidValidate(r.recruitmentCurrentBenefitId) ? 0 : r.recruitmentCurrentBenefitId);
                                            continue;
                                        }

                                        if (typeof r[ckey] === "object" || typeof r[ckey] === "array")
                                            continue;

                                        if (r[ckey])
                                            formData.append(`${key}[${idx}].${dkey}[${ridx}].${ckey}`, r[ckey]);
                                    }
                                });

                                continue;
                            }

                            if (dkey === "deletedRecruitmentCurrentBenefitIds" && d.deletedRecruitmentCurrentBenefitIds?.length) {
                                // console.log({dkey, dt: d[dkey]});
                                d.deletedRecruitmentCurrentBenefitIds.forEach((r, ridx) => {
                                    formData.append(`${key}[${idx}].${dkey}[${ridx}]`, r);
                                });

                                continue;
                            }

                            if (dkey === "recruitmentRequest")
                                continue;

                            for (let ckey in d[dkey])
                                if (d[dkey][ckey])
                                    formData.append(`${key}[${idx}].${dkey}.${ckey}`, d[dkey][ckey]);

                            continue;
                        }

                        if (dkey === "requestDetailId") {
                            formData.append(`${key}[${idx}].${dkey}`, uuidValidate(d.requestDetailId) ? 0 : d.requestDetailId);
                            continue;
                        }

                        if (d[dkey])
                            formData.append(`${key}[${idx}].${dkey}`, d[dkey]);
                    }
                })
                continue;
            }

            if (key === "recruitmentRequestAttachments" && request.recruitmentRequestAttachments) {
                // console.log(request.recruitmentRequestAttachments);
                request.recruitmentRequestAttachments.forEach((d, idx) => {
                    // console.log(d);
                    formData.append(`${key}[${idx}].recruitmentAttachmentId`, d.recruitmentAttachmentId);
                    formData.append(`${key}[${idx}].recruitmentRequestId`, d.recruitmentRequestId);

                    if (d.file) {
                        // console.log(d.file);
                        formData.append(`${key}[${idx}].file`, d.file);
                        formData.append(`${key}[${idx}].fileContent`, null);
                        formData.append(`${key}[${idx}].fileType`, "");
                        formData.append(`${key}[${idx}].fileSizeInBytes`, "");
                        formData.append(`${key}[${idx}].filename`, "");
                        return;
                    }

                    // console.log(d.file);
                    formData.append(`${key}[${idx}].fileContent`, d.fileContent);
                    formData.append(`${key}[${idx}].fileType`, d.fileType);
                    formData.append(`${key}[${idx}].fileSizeInBytes`, d.fileSizeInBytes);
                    formData.append(`${key}[${idx}].filename`, d.filename);
                })
                continue;
            }

            if (key === "deletedRequestDetails" && request.deletedRequestDetails) {
                // console.log(request.deletedRequestDetails);
                request.deletedRequestDetails.forEach((d, idx) => {
                    formData.append(`${key}[${idx}]`, d);
                })
                continue;
            }

            if (key === "deletedRecruitmentRequestAttachments" && request.deletedRecruitmentRequestAttachments) {
                // console.log(request.deletedRecruitmentRequestAttachments);
                request.deletedRecruitmentRequestAttachments.forEach((d, idx) => {
                    formData.append(`${key}[${idx}]`, d);
                })
                continue;
            }
        }

        // const payload = {
        //     ...request,
        //     StatusId: newStatusLookup.lookupId,
        //     Status: newStatusLookup,
        //     requestDetails: request.requestDetails && request.requestDetails.length > 0 &&
        //         request.requestDetails.map((detail) => ({
        //             ...detail,
        //             requestDetailId: uuidValidate(detail.requestDetailId) ? 0 : detail.requestDetailId,
        //         }))
        // };

        const url = `${process.env.REACT_APP_WEB_API_BASE_URI}/recruitmentrequest${request.recruitmentRequestId > 0 ? `/${request.recruitmentRequestId}` : ""}`;
        const method = request.recruitmentRequestId > 0 ? "PUT" : "POST";

        api({
            url,
            method,
            // data: JSON.stringify(payload),
            data: formData,
        })
            .then(response => {
                if (response.status !== 204 && response.status !== 201)
                    return;

                // console.log({isApproval, currentStatus, isRejection, approvalStatus});

                if (!isApproval)
                {
                    if (currentStatus === "Ready" || currentStatus === PNM_StatusPending)
                    {
                        if (!checkMinDate())
                        {
                            setShowDateWarning(true);
                            setLoading(false);
                            return;
                        }
                    }

                    if (currentStatus === approvedStatusName)
                    {
                        sendEmailToRecruitment();
                        return;
                    }

                    setLoading(false);
                    history.push(isApproval ? "/lrfapproval" : "/lrflist");
                    return;
                }
                
                
                if (isRejection) {
                    setRejectionStatus(approvalStatus);
                    setShowRejectReasonDialog(true);
                    setLoading(false);
                    return;
                }
                
                if (!isRejection) {
                    updateStatus(approvalStatus);
                    return;
                }
            })
            .catch((error) => {
                console.error({ error });
                const info = error?.response?.data;
                setLoading(false);
                if (info && info.project && info.location) {
                    setApproverAlert([<MissingApproverAlert
                        onConfirm={() => setApproverAlert([])}
                        message={`Labour Request Approver not set for Project: ${info.project.projectName}, Location: ${info.location.locationName}`}
                    />]);
                    setLoading(false);
                    return;
                }
                
                if (info && info.failedEmails)
                {
                    setApproverAlert([
                        <SweetAlert
                            
                            error
                            showCancel={false}
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            title={<h4 className="mb-0">System Error</h4>}
                            onConfirm={() => setApproverAlert([])}
                            focusCancelBtn
                        >
                            <p>Email is failed to send. Please contact the administrator.</p>
                        </SweetAlert>
                    ]);
                    setLoading(false);
                    return;
                }
                
                if (info && info.definitivResponse)
                {
                    setApproverAlert([
                        <SweetAlert
                            
                            error
                            showCancel={false}
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            title={<h4 className="mb-0">Invalid Employee Replacement Data</h4>}
                            onConfirm={() => setApproverAlert([])}
                            focusCancelBtn
                        >
                            <p>{info.definitivResponse}</p>
                        </SweetAlert>
                    ]);
                    setLoading(false);
                    return;
                }
                //
            })
        ;
    }, [lookups, request, validationAlert, history, isApproval, checkMinDate, updateStatus, workorderAlert, validate, validateWorkorderNumber, newOnBoardWorkorder, sendEmailToRecruitment]);

    const canEnterEditMode = (req) => {

        if (!req.projectId || !req.recruitmentTypeId) {
            setAlert([
                <Col sm={12}>
                    <PleaseCompleteAlert
                        key={0}
                        onConfirm={() => setAlert(alert.splice(1))}
                        fields={[
                            ...[req.projectId ? null : "Project"],
                            ...[req.recruitmentTypeId ? null : "Recruitment Type"],
                        ]}
                    />
                </Col>
            ]);
            return false;
        }
        return true;
    }

    const handleAddClick = React.useCallback((event) => {
        if (canEnterEditMode(request)) {
            const employmentCategory = lookups.employmentCategories.filter(e => e.lookupValue.toLowerCase().includes(request.recruitmentType.lookupValue.toLowerCase().includes("white") ? "white collar" : "blue collar"))[0];

            // console.log({employmentCategory, request, recTypeName: request.recruitmentType.lookupValue.toLowerCase()});
            
            if (!employmentCategory)
                return;
            
            const newDetail = { requestDetailId: v4(), daysPerWeek: 31, projectId: request?.projectId ?? 0, employmentCategory, employmentCategoryId: employmentCategory.lookupId, location: request?.location, locationId: request?.locationId };
            const details = [];

            if (request.requestDetails?.length)
                request.requestDetails.forEach(d => {
                    details.push({ ...d });
                });

            details.push({ ...newDetail });
            setItemInEdit({ ...request, requestDetails: [...details], mode: "new", detail: { ...newDetail } });
        }
    }, [request, lookups])

    const handleEditClick = React.useCallback((event, detail) => {

        if (canEnterEditMode(request)) {
            const employmentCategory = lookups.employmentCategories.filter(e => e.lookupValue.toLowerCase().includes(request?.recruitmentType.lookupValue.toLowerCase().includes("white") ? "white collar" : "blue collar"))[0];
            setItemInEdit({ ...request, mode: "edit", detail: { ...detail, projectId: request?.projectId ?? 0, employmentCategory, employmentCategoryId: employmentCategory?.lookupId, location: request?.location, locationId: request?.locationId } });
        }
    }, [request])

    const handleCopyClick = (event, detail) => {
        setItemInEdit({ mode: "new", detail: { ...detail, requestDetailId: v4(), projectId: request?.projectId ?? 0 } });
    }
    const handleTextChanged = ({ target: { name, value } }) => {
        setRequest({ ...request, [name]: value });
    }

    const handleDeleteClick = useCallback((event, detail) => {
        const newDetails = [...request.requestDetails];
        const itemIndex = _.findIndex(newDetails, (o) => o.requestDetailId === detail.requestDetailId);
        newDetails.splice(itemIndex, 1);

        let deletedItems = request.deletedRequestDetails ? [...request.deletedRequestDetails] : [];
        if (_.isInteger(detail.requestDetailId * 1)) {
            deletedItems.push(detail.requestDetailId * 1);
        }

        setRequest({ ...request, requestDetails: newDetails, deletedRequestDetails: deletedItems });
    }, [request]);

    const handleSave = (item, mode) => {

        const requestDetails = request.requestDetails && request.requestDetails.length > 0 ? request.requestDetails : []

        if (mode === "new") {
            setRequest({ ...request, requestDetails: [{ ...item }, ...requestDetails] });
        } else if (mode === "edit") {
            const newDetails = [...requestDetails];
            const itemIndex = _.findIndex(requestDetails, (o) => o.requestDetailId === item.requestDetailId);
            newDetails.splice(itemIndex, 1, { ...item });
            setRequest({ ...request, requestDetails: newDetails });
        }

        setItemInEdit(null);
    }

    const columnSizing = {
        sm: 12,
        md: {
            size: 4,
            //offset: 3
        }
    }

    const checkBoxColumnSizing = {
        sm: 12,
        md: {
            size: 4,
            //offset: 3
        },
        lg: {
            size: 3,
            //offset: 3
        },
        xl: {
            size: 2,
            //offset: 3
        }
    }

    const renderBadge = (variant) => {
        if (variant.checkResult) {
            return <Badge className="badge-circle" color="success">
                <i className="ni ni-check-bold text-white"></i>
            </Badge>
        }

        if (variant.type.key === checkType[0].key)
            return null;

        if (variant.type.key === checkType[1].key)
            return < Badge className="badge-circle" color="warning">
                <i className="fas fa-exclamation text-black"></i>
            </Badge>;

        return <Badge className="badge-circle" color="danger">
            <i className="fas fa-times text-white"></i>
        </Badge>;
    }

    const requestDetails = React.useMemo(() => {

        return (
            <LabourRequestDetailListV2
                handleAddClick={handleAddClick}
                handleEditClick={handleEditClick}
                handleCopyClick={handleCopyClick}
                handleDeleteClick={handleDeleteClick}
                details={request.requestDetails}
                status={request.status}
                request={request}
                isApproval={isApproval}
            />
        )
    }, [handleAddClick, handleEditClick, handleCopyClick, handleDeleteClick, request, request.status, request.requestDetails]);

    const deleteRequest = React.useCallback(() => {

        setLoading(true)
        api({
            url: `${process.env.REACT_APP_WEB_API_BASE_URI}/recruitmentrequest/${request.recruitmentRequestId}`,
            method: 'DELETE',
        })
            .then(response => {
                history.push("/lrflist");
            })
            .catch((error) => {

            })
            .finally(() => setLoading(false));
    });

    const downloadAttachment = (fileInfo) => {
        if (fileInfo.file) {
            fileDownload(fileInfo.file, fileInfo.file.name);
        } else {
            setLoading(true);
            let url = `recruitmentrequest/download/${fileInfo.recruitmentAttachmentId}`;
            api.get(url, { responseType: 'blob' })
                .then(blob => {
                    fileDownload(blob.data, fileInfo.filename);
                }).catch(error => {
                    console.error(error)
                }).finally(() => setLoading(false))
        }
    }

    const removeAttachment = useCallback((fileInfo) => {
        const newAttachments = [...request.recruitmentRequestAttachments];
        const removedItem = newAttachments.filter(a => a.recruitmentAttachmentId === fileInfo.recruitmentAttachmentId)[0];
        const removedIndex = newAttachments.indexOf(removedItem);
        newAttachments.splice(removedIndex, 1);
        // console.log({removedItem, removedIndex});

        if (removedItem.recruitmentAttachmentId <= 0) {
            setRequest({ ...request, recruitmentRequestAttachments: [...newAttachments] });
            return;
        }

        const deletedAttachments = request.deletedRecruitmentRequestAttachments && request.deletedRecruitmentRequestAttachments.length ? [...request.deletedRecruitmentRequestAttachments] : [];
        deletedAttachments.push(removedItem.recruitmentAttachmentId);
        // console.log({deletedAttachments});
        setRequest({ ...request, recruitmentRequestAttachments: [...newAttachments], deletedRecruitmentRequestAttachments: [...deletedAttachments] });
    }, [request]);

    const files = React.useMemo(() => {
        if (request?.recruitmentRequestAttachments?.length > 0)
            return <MultipleDropzoneList
                idField="recruitmentAttachmentId"
                nameField="filename"
                sizeField="fileSizeInBytes"
                download={downloadAttachment}
                remove={removeAttachment}
                data={request.recruitmentRequestAttachments}
                disabled={(isApproval && (!approvalData || (request?.status?.lookupValue !== pendingBudgetSightingStatusName && request?.status?.lookupValue !== pendingRedZoneStatusName) || user?.username.toLowerCase() !== approvalData.approver?.email?.toLowerCase())) || (request?.status && (request.status.lookupValue === approvedStatusName || request.status.lookupValue === 'In Progress' || request.status.lookupValue === "Void" || request.status.lookupValue === completedStatusName))}
            />;

        return null;
    }, [request, isApproval, approvalData, user]);

    const checkRosterAvailability = useCallback(() => {
        if (!request?.recruitmentRequestId || !request?.requestDetails?.length)
            return;

        if (!request?.rosterId)
        {
            saveRequest(pendingStatusName, true, false, approvedStatusName);
            return;
        }

        setLoading(true);

        api.get(`recruitmentrequest/roster/check/${request.recruitmentRequestId}`)
            .then((response) => {
                setLoading(false);
                const rosterRequired = [];
                const {data} = response;

                request.requestDetails.forEach(rd => {
                    const currentData = data.filter(d => d.requestDetailId == rd.requestDetailId)[0];

                    if (currentData?.actualQuantityRequired === rd.quantityRequired)
                        return;

                    if (currentData)
                    {
                        rosterRequired.push({
                            ...currentData
                        });
                        return;
                    }

                    rosterRequired.push({...rd, quantityRequired: 0, actualQuantityRequired: 0});
                });

                if (!rosterRequired.length)
                {
                    saveRequest(pendingStatusName, true, false, approvedStatusName);
                    return;
                }

                setActualRosterRequired(rosterRequired);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [request?.recruitmentRequestId, request?.requestDetails, saveRequest]);

    useEffect(() => {
        if (!readyToSave)
            return;

        setReadyToSave(false);
        saveRequest("Ready", true);
    }, [readyToSave]);

    const checkApprover = useCallback(() => {
        const approverErrorCheck = (data) => {
            const checkResult = data !== null && (data?.approver || data?.autoApproved);
            setIsApproverExists(checkResult);
                // console.log(data);
            const errors = [...validationAlert];
            const itemKey = "approver";
            const itemType = `approver`;
            const exists = _.some(errors, (item) => item.key === itemKey && item.type === itemType);
            if (!checkResult && !exists)
                errors.push({ key: itemKey, type: itemType, message: (
                    <span>Incomplete approver list for the selected Project and Recruitment Type. Please contact HRIS Administrator to go to Maintenance {">"} Project to setup the approver list or click <u className="cursor-pointer" onClick={() => window.open(`/maintenance`, '_blank')}>here</u> if you have Administrator access.</span>
                ) });

            if (checkResult && exists)
                _.remove(errors, (item) => item.key === itemKey && item.type === itemType);

            setValidationAlert([...errors]);
            setRequestValidation(true);

            if (checkResult)
            {
                setInitialApprover(data.autoApproved ? `${user.username} (auto approved)` : data.approver.email);

                if (request.approverName && (request.status?.lookupValue.toLowerCase().includes("rejected") || request.status?.lookupValue.toLowerCase().includes("declined")))
                    setRequest({...request, approverName: null});
            }
        };

        setInitialApprover(null);

        if (!request?.projectId || !request?.locationId || !request?.recruitmentTypeId || (request.recruitmentType.lookupValue.toLowerCase().includes("white") && !request?.recruitmentCategoryId))
        {
            setIsApproverExists(false);
            return;
        }

        setLoading(true);
        api.get(`recruitmentrequest/approval/check?projectId=${request?.projectId}&recruitmentTypeId=${request?.recruitmentTypeId}${request?.recruitmentCategoryId ? `&recruitmentCategoryId=${request.recruitmentCategoryId}` : ""}`)
            .then((response) => {
                const {data} = response;
                // console.log({response, data});
                approverErrorCheck(data);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [request, validationAlert]);

    useEffect(() => {
        checkApprover();
    }, [request?.rosterId, request?.projectId, request?.recruitmentTypeId, request?.recruitmentCategoryId]);

    useEffect(() => {
        if (!request?.recruitmentCategoryId)
            setRequest({...request, isBudgeted: null, isLikeForLike: null});
    }, [request?.recruitmentCategoryId]);

    useEffect(() => {
        if (!request?.recruitmentCategoryId)
        {
            setAttachmentCaption(defaultAttachmentCaption);
            return;
        }

        if (request.recruitmentCategory.lookupValue === "New Role")
        {
            if (request?.isBudgeted === false)
            {
                setAttachmentCaption(<span>Attach Justification (Click <b className="cursor-pointer" onClick={() => window.open(lookups.lrfJustificationTemplateUrl?.systemConfigValue, "_blank")}><u>here</u></b> to download the template)<b className=" text-danger">*</b></span>);
                return;
            }
            
            if (request?.isBudgeted === true)
            {
                setAttachmentCaption(<span>Attach Proof of Budget File<b className="text-danger">*</b></span>);
                return;
            }

            setAttachmentCaption(defaultAttachmentCaption);
            return;
        }

        if (request.recruitmentCategory.lookupValue === "Replacement")
        {
            if (request?.isLikeForLike === false)
            {
                setAttachmentCaption(<span>Attach Justification (Click <b className="cursor-pointer" onClick={() => window.open(lookups.lrfJustificationTemplateUrl?.systemConfigValue, "_blank")}><u>here</u></b> to download the template)<b className="text-danger">*</b></span>);
                return;
            }

            setAttachmentCaption(defaultAttachmentCaption);
            return;
        }
    }, [request?.recruitmentCategoryId, request?.isBudgeted, request?.isLikeForLike]);

    useEffect(() => {
        if (!workorderAlert?.confirmed)
            return;

        saveRequest(workorderAlert.currentStatus, workorderAlert.checkDetails, workorderAlert.isRejection, workorderAlert.approvalStatus);
    }, [workorderAlert]);

    return (
        <section className="main">
            {(loading) && <Loader />}
            {approverAlert.length > 0 && approverAlert}
            <Container fluid>
                <Row className="m-0 p-0">
                    <Col xs={10}>
                        <Row>
                            <Col {...columnSizing}>
                                <FormGroup className="">
                                    <Label>
                                        Client / Company
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <ComboBox
                                        minLength={0}
                                        endpoint="/client?take=20"
                                        //accessToken={token?.accessToken}
                                        selectedItem={request.client}
                                        onChange={(item) => {
                                            const newRequest = {...request};

                                            if (item?.lookupId == newRequest?.clientId)
                                                return;

                                            setRequest({ ...request, client: item, clientId: item ? item.lookupId : null, project: null, projectId: null, onBoardWorkorder: null, onBoardWorkorderId: null });
                                            setNewOnBoardWorkorder(null);
                                            setRefreshInputValue(true);
                                        }}
                                        disabled={request.status?.lookupValue === approvedStatusName || isApproval || request?.rosterId}
                                    />
                                </FormGroup>
                            </Col>
                            <Col {...columnSizing}>
                                <FormGroup className="">
                                    <Label>
                                        Site / Project
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <ComboBox
                                        minLength={0}
                                        endpoint={`/project?take=20${request.clientId ? `&clientId=${request.clientId}` : ""}`}
                                        //accessToken={token?.accessToken}
                                        selectedItem={request.project}
                                        onChange={(item) => {
                                            const newRequest = {...request};

                                            if (item?.lookupId == newRequest?.projectId)
                                                return;

                                            setRequest({ ...request, project: item, projectId: item ? item.lookupId : null, onBoardWorkorder: null, onBoardWorkorderId: null });
                                            setNewOnBoardWorkorder(null);
                                            setRefreshInputValue(true);
                                        }}
                                        disabled={(request.client ? false : true) || request.status?.lookupValue === approvedStatusName || isApproval || request?.rosterId}
                                    />
                                </FormGroup>
                            </Col>
                            {/* <Col { ...columnSizing }>
                                <FormGroup className="">
                                    <Label>
                                        Payroll Default Cost Code
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <ComboBox 
                                        minLength={2}
                                        disabled={!request.projectId || request.status?.lookupValue === approvedStatusName}
                                        endpoint={`/workorder/${request.projectId}`}
                                        //accessToken={token?.accessToken}
                                        selectedItem={request.workorder}
                                        onChange={(item) => {
                                            setRequest( { ...request, workorder: item, workorderId: item ? item.lookupId : null } );
                                        }}
                                    />
                                </FormGroup>
                            </Col> */}
                            <Col {...columnSizing}>
                                <FormGroup>
                                    <Label>
                                        Location
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <ComboBox
                                        minLength={0}
                                        // endpoint={`/location?take=20${request.projectId ? `&projectId=${request.projectId}` : ""}`}
                                        endpoint="/location?take=20"
                                        //accessToken={token?.accessToken}
                                        selectedItem={request.location}
                                        disabled={/*(request.project ? false : true) || */request.status?.lookupValue === approvedStatusName || isApproval}
                                        onChange={(item) => {
                                            setRequest({ ...request, location: item, locationId: item ? item.lookupId : null });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col {...columnSizing}>
                                <FormGroup>
                                    <Label>
                                        Onboarding Cost Code
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <ComboBox
                                        minLength={0}
                                        endpoint={`/workorder/${request.projectId}?take=20`}
                                        //accessToken={token?.accessToken}
                                        selectedItem={request.onBoardWorkorder}
                                        disabled={!request?.projectId || request.status?.lookupValue === approvedStatusName || isApproval}
                                        onChange={(item) => {
                                            setRequest({ ...request, onBoardWorkorder: item ?? null, onBoardWorkorderId: item ? item.lookupId : null });

                                            if (item)
                                                setNewOnBoardWorkorder(null);
                                        }}
                                        onInputValueChange={(value) => {
                                            if (!value || value === "")
                                            {
                                                if (newOnBoardWorkorder)
                                                    setNewOnBoardWorkorder(null);

                                                return;
                                            }
    
                                            // console.log(value);
                                            setNewOnBoardWorkorder(value);
                                        }}
                                        refreshInputValue={refreshInputValue}
                                        setRefreshInputValue={setRefreshInputValue}
                                    />
                                    {/* <DropdownBox 
                                        data={lookups.costCodes}
                                        idField="lookupId"
                                        valueField="lookupValue"
                                        selectedItem={request.costCode}
                                        onChange={(item) => {
                                            setRequest( { ...request, costCode: item, costCodeId: item ? item.lookupId : null } );
                                        }}
                                        disabled={request.status?.lookupValue === approvedStatusName}
                                    /> */}
                                    {/* <Input
                                        name="costCode"
                                        id="costCode"
                                        type="text"
                                        required
                                        value={request.costCode}
                                        onChange={handleTextChanged}
                                    /> */}
                                </FormGroup>
                            </Col>
                            {/* <Col { ...columnSizing }>
                                <FormGroup>
                                    <Label>
                                        Department
                                        <span className="text-danger">*</span>
                                    </Label> */}
                            {/* <ComboBox 
                                        minLength={2}
                                        endpoint="/department"
                                        //accessToken={token?.accessToken}
                                        selectedItem={request.department}
                                        onChange={(item) => {
                                            setRequest( { ...request, department: item, departmentId: item ? item.lookupId : null } );
                                        }}
                                    /> */}
                            {/* <DropdownBox 
                                        data={lookups.departments ?? []}
                                        idField="lookupId"
                                        valueField="lookupValue"
                                        selectedItem={request.department}
                                        onChange={(item) => {
                                            setRequest( { ...request, department: item, departmentId: item ? item.lookupId : null } );
                                        }}
                                        disabled={request.status?.lookupValue === approvedStatusName}
                                    />
                                </FormGroup>
                            </Col> */}
                            <Col {...columnSizing}>
                                <FormGroup>
                                    <Label>
                                        Recruitment Type
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <DropdownBox
                                        data={lookups.recruitmentTypes}
                                        idField="lookupId"
                                        valueField="lookupValue"
                                        selectedItem={request.recruitmentType}
                                        onChange={(item) => {
                                            const newRequest = { ...request };

                                            if (item !== request.recruitmentType)
                                            {
                                                newRequest.recruitmentType = item;
                                                newRequest.recruitmentTypeId = item?.lookupId ?? null;
                                                newRequest.recruitmentCategory = null;
                                                newRequest.recruitmentCategoryId = null;
                                                newRequest.isBudgeted = null;
                                                newRequest.isLikeForLike = null;
                                            }

                                            if (newRequest.requestDetails?.length > 0 && item)
                                            {
                                                const selectedCategory = lookups.employmentCategories.filter(c => c.lookupValue.toLowerCase().includes(item.lookupValue.toLowerCase().includes("white") ? "white collar" : "blue collar"))[0];

                                                if (selectedCategory)
                                                    newRequest.requestDetails.forEach(r => {
                                                        r.employmentCategory = selectedCategory;
                                                        r.employmentCategoryId = selectedCategory.lookupId;
                                                    });
                                            }

                                            // console.log({newRequest});
                                            setRequest({...newRequest});
                                        }}
                                        disabled={request.status?.lookupValue === approvedStatusName || isApproval || request?.rosterId}
                                    />
                                </FormGroup>
                            </Col>
                        {
                            request?.recruitmentType?.lookupValue?.toLowerCase().includes("white")  &&
                            <Col {...columnSizing}>
                                <FormGroup>
                                    <Label>
                                        Recruitment Category
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <DropdownBox
                                        data={lookups.recruitmentCategories}
                                        idField="lookupId"
                                        valueField="lookupValue"
                                        selectedItem={request.recruitmentCategory}
                                        onChange={(item) => {
                                            const newRequest = { ...request };

                                            if (item !== request.recruitmentType)
                                            {
                                                newRequest.recruitmentCategory = item;
                                                newRequest.recruitmentCategoryId = item?.lookupId ?? null;
                                                newRequest.isBudgeted = null;
                                                newRequest.isLikeForLike = null;
                                            }

                                            setRequest({...newRequest});
                                        }}
                                        disabled={request.status?.lookupValue === approvedStatusName || isApproval}
                                    />
                                </FormGroup>
                            </Col>
                        }
                            {/* <Col {...columnSizing}>
                                <FormGroup>
                                    <Label>
                                        Company
                                    </Label>
                                    <ComboBox
                                        minLength={0}
                                        endpoint={`/lrfcompany`}
                                        selectedItem={request?.company}
                                        disabled={request.status?.lookupValue === approvedStatusName}
                                        onChange={(item) => {
                                            setRequest({ ...request, company: item, companyId: item ? item.lookupId : null });
                                        }}
                                    />
                                </FormGroup>
                            </Col> */}
                        {
                            request?.recruitmentCategory?.lookupValue === "New Role" &&
                            <Col {...columnSizing}>
                                <FormGroup>
                                    <Label>
                                        Is Budgeted
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <DropdownBox
                                        data={yesNoOptions}
                                        idField="lookupId"
                                        valueField="lookupValue"
                                        selectedItem={yesNoOptions.filter(o => o.lookupId === request.isBudgeted)[0] ?? null}
                                        onChange={(item) => {
                                            const newRequest = { ...request, isBudgeted: item ? item.lookupId : null };
                                            setRequest({...newRequest});
                                        }}
                                        disabled={request.status?.lookupValue === approvedStatusName || isApproval}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            request?.recruitmentCategory?.lookupValue === "Replacement" &&
                            <Col {...columnSizing}>
                                <FormGroup>
                                    <Label>
                                        Like for Like?
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <DropdownBox
                                        data={yesNoOptions}
                                        idField="lookupId"
                                        valueField="lookupValue"
                                        selectedItem={yesNoOptions.filter(o => o.lookupId === request.isLikeForLike)[0] ?? null}
                                        onChange={(item) => {
                                            const newRequest = { ...request, isLikeForLike: item ? item.lookupId : null };
                                            setRequest({...newRequest});
                                        }}
                                        disabled={request.status?.lookupValue === approvedStatusName || isApproval}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            request?.rosterId > 0 &&
                            <Col {...columnSizing}>
                                <FormGroup>
                                    <Label>
                                        Dispatch Unit
                                    </Label>
                                    <Input
                                        className="w-100"
                                        disabled={true}
                                        value={request?.roster?.dispatchUnit?.dispatchUnitName ?? ""}
                                    />
                                </FormGroup>
                            </Col>
                        }
                            <Col xs={12} className="m-0 p-0">
                                <Row className="m-0 p-0">
                                    <Col {...checkBoxColumnSizing}>
                                        <FormGroup className="d-flex flex-wrap justify-content-start align-items-start">
                                            <IndeterminateCheckbox
                                                id="isConfidential"
                                                type="checkbox"
                                                onChange={() => {
                                                    setRequest({ ...request, isConfidential: !(request.isConfidential ?? false) })
                                                }}
                                                checked={request?.isConfidential ?? false}
                                                disabled={isApproval}
                                            />
                                            <Label className="ml-1">
                                                Confidential Request
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col {...checkBoxColumnSizing}>
                                        <FormGroup className="d-flex flex-wrap justify-content-start align-items-start">
                                            <IndeterminateCheckbox
                                                id="isUrgent"
                                                type="checkbox"
                                                onChange={() => {
                                                    setRequest({ ...request, isUrgent: !(request.isUrgent ?? false) })
                                                }}
                                                checked={request?.isUrgent ?? false}
                                                disabled={isApproval}
                                            />
                                            <Label className="ml-1">
                                                Urgent Request
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>
                                        {attachmentCaption}
                                    </Label>
                                    <FileDropzone
                                        files={files}
                                        onDrop={([file]) => {
                                            const newAttachments = request?.recruitmentRequestAttachments ? [...request.recruitmentRequestAttachments] : [];
                                            const newIds = newAttachments?.filter(a => a.recruitmentAttachmentId <= 0);
                                            if (newIds.length)
                                                newIds.sort((a, b) => parseInt(b.recruitmentAttachmentId) - parseInt(a.recruitmentAttachmentId));

                                            const newId = newIds.length ? newIds[0].recruitmentAttachmentId - 1 : 0;
                                            const newData = {
                                                recruitmentRequestId: request.recruitmentRequestId ?? 0,
                                                recruitmentAttachmentId: newId,
                                                file
                                            };
                                            newAttachments.push(newData);
                                            // console.log({file, newData, newAttachments});

                                            setRequest({ ...request, recruitmentRequestAttachments: [...newAttachments] });
                                        }}
                                        maxFiles={0}
                                        // disabled={(isApproval && !request?.status?.lookupValue.includes("Pending")) || (request?.status && (request.status.lookupValue === approvedStatusName || request.status.lookupValue === 'In Progress' || request.status.lookupValue === "Void" || request.status.lookupValue === completedStatusName))}
                                        disabled={(isApproval && (!approvalData || (request?.status?.lookupValue !== pendingBudgetSightingStatusName && request?.status?.lookupValue !== pendingRedZoneStatusName) || user?.username.toLowerCase() !== approvalData.approver?.email?.toLowerCase())) || (request?.status && (request.status.lookupValue === approvedStatusName || request.status.lookupValue === 'In Progress' || request.status.lookupValue === "Void" || request.status.lookupValue === completedStatusName))}
                                    />
                                </FormGroup>
                            </Col>
                            {
                                !!alert && alert.length > 0 && alert
                            }
                            {
                                validationAlert && validationAlert.length > 0 &&
                                <Col sm={12}>
                                    <Alert color="warning" className="pa-0 d-flex justify-content-between">
                                        <span className="alert-text">
                                            <ul className="mb-0">
                                                {
                                                    validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                                                }
                                            </ul>
                                        </span>
                                    </Alert>
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col xs={2} className="bg-secondary lrfstatusbox">
                        <Row className="m-0 p-0">
                            <Col xs={12} className="m-0 p-0">
                                <Row className="m-0 p-0">
                                    <Col xs={12} className="m-0 p-0 text-left pb-1">
                                        <Row className="m-0 mt-2 p-0">
                                            <Col xs={12} className="m-0 p-0">
                                                <span>Status:</span>
                                            </Col>
                                            <Col xs={12} className="m-0 p-0">
                                                <span>{request.status ? request.status.lookupValue : 'New'}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                {
                                    request?.declinedReason ?
                                    <Col xs={12} className="m-0 p-0 text-left pb-1">
                                        <Row className="m-0 mt-2 p-0">
                                            <Col xs={12} className="m-0 p-0">
                                                <span>Reject Reason:</span>
                                            </Col>
                                            <Col xs={12} className="m-0 p-0">
                                                <span>{request.declinedReason}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    :
                                    null
                                }
                                {
                                    request?.approverName ?
                                    <Col xs={12} className="m-0 p-0 text-left pb-1">
                                        <Row className="m-0 mt-2 p-0">
                                            <Col xs={12} className="m-0 p-0">
                                                <span>Approver:</span>
                                            </Col>
                                            <Col xs={12} className="m-0 p-0">
                                                <span>{request.approverName}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    :
                                    null
                                }
                                {
                                    initialApprover && !request?.approverName ?
                                    <Col xs={12} className="m-0 p-0 text-left pb-1">
                                        <Row className="m-0 mt-2 p-0">
                                            <Col xs={12} className="m-0 p-0">
                                                <span>Approver:</span>
                                            </Col>
                                            <Col xs={12} className="m-0 p-0">
                                                <span>{initialApprover}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    :
                                    null
                                }
                                {
                                    request?.requestor?.email || request?.createdBy ?
                                    <Col xs={12} className="m-0 p-0 text-left pb-1">
                                        <Row className="m-0 mt-2 p-0">
                                            <Col xs={12} className="m-0 p-0">
                                                <span>Requested by:</span>
                                            </Col>
                                            <Col xs={12} className="m-0 p-0">
                                                <span>{request?.requestor?.email ?? request?.createdBy}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    :
                                    null
                                }
                                    <Col xs={12} className="m-0 p-0 text-left pb-1 mb-4">
                                        <Row className="m-0 mt-2 p-0">
                                            <Col xs={12} className="m-0 p-0">
                                                <span>Last Modified:</span>
                                            </Col>
                                            <Col xs={12} className="m-0 p-0">
                                                <span>{request.modifiedDate ? moment(request.modifiedDate).format("DD MMM YYYY HH:mm") : null}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                {
                                    request.projectId &&
                                    <>
                                        <Col xs={12}
                                            className="m-0 p-0 text-left pb-1 cursor-pointer"
                                            onClick={() => {
                                                if (request.projectId)
                                                    window.open(`/tna/${request.projectId}`, '_blank')
                                            }}
                                        >
                                            {renderBadge({ checkResult: projectStatus?.tna ? true : false, type: checkType[2] })}{" "}
                                            <span className="ml-1"><u>Training Needs Analysis</u></span>
                                        </Col>
                                        <Col xs={12}
                                            className="m-0 p-0 text-left pb-1 cursor-pointer"
                                            onClick={() => {
                                                if (request.projectId)
                                                    window.open(`/pct/${request.projectId}`, '_blank')
                                            }}
                                        >
                                            {renderBadge({ checkResult: projectStatus?.ct ? true : false, type: checkType[1] })}{" "}
                                            <span className="ml-1"><u>Contract Template</u></span>
                                        </Col>
                                        <Col xs={12} className="m-0 p-0 text-left pb-1 cursor-pointer"
                                            onClick={() => {
                                                if (request.projectId)
                                                    window.open(`/rates/${request.projectId}`, '_blank')
                                            }}
                                        >
                                            {renderBadge({ checkResult: projectStatus?.rs && rateCompleted ? true : false, type: checkType[2] })}{" "}
                                            <span className="ml-1"><u>Rate Sheet</u></span>
                                        </Col>
                                    {
                                        request?.projectId && request?.locationId && request?.recruitmentTypeId && (request?.recruitmentType.lookupValue.toLowerCase().includes("blue") || request?.recruitmentCategoryId) &&
                                        <Col xs={12} className="m-0 p-0 text-left pb-1 cursor-pointer" onClick={() => window.open(`/maintenance`, '_blank')}>
                                            {renderBadge({ checkResult: isApproverExists, type: checkType[2] })}{" "}
                                            <span className="ml-1"><u>Approver</u></span>
                                        </Col>
                                    }
                                    </>
                                }
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <div className="container-table-oversize mb-4 pt-3">
                <div className="px-5">
                    <Row>
                        <Col xs={12} className="py-2">
                            {
                                requestDetails
                            }
                            {/* <LabourRequestDetailList 
                            handleAddClick={handleAddClick}
                            handleEditClick={handleEditClick}
                            handleCopyClick={handleCopyClick}
                            handleDeleteClick={handleDeleteClick}
                            details={request.requestDetails}
                        /> */}
                        </Col>
                    </Row>
                </div>
            </div>
            <Container>
                <Row>
                    <Col xs={12} className="d-flex justify-content-between mb-4">
                        <Row className="w-100">
                            <Col xs={3} className="d-flex flex-wrap justify-content-start">
                                <Button
                                    color="info"
                                    onClick={() => history.push(isApproval ? "/lrfapproval" : "/lrflist")}
                                    type="button"
                                >
                                    Back
                                </Button>
                            </Col>
                        {
                            (!isApproval && (!request || !request.status || ((request.status.lookupValue !== pendingStatusName || request.userIsHrDirector) && request.status.lookupValue !== 'In Progress' && request.status.lookupValue !== "Void" && request.status.lookupValue !== completedStatusName))) &&
                            <Col xs={9} className="d-flex flex-wrap justify-content-end">
                            {
                                (
                                    (isUserAdmin || request?.status?.lookupValue !== PNM_StatusPending) &&
                                    request?.status?.lookupValue !== PNM_StatusApproved &&
                                    request?.status?.lookupValue !== approvedStatusName &&
                                    request?.status?.lookupValue !== "In Progress" &&
                                    request?.status?.lookupValue !== "Void" &&
                                    request?.status?.lookupValue !== completedStatusName
                                ) &&
                                <>
                                {
                                    request?.recruitmentRequestId &&
                                    request?.status?.lookupValue !== PNM_StatusPending &&
                                    !request?.status?.lookupValue.includes(rejectedStatusName) &&
                                    !request?.status?.lookupValue.includes("Declined") &&
                                    !request?.status?.lookupValue.includes("Pending") ?
                                    <Button
                                        color="danger"
                                        onClick={() => {
                                            deleteRequest()
                                        }}
                                        type="button"
                                    >
                                        Delete
                                    </Button>
                                    :
                                    null
                                }
                                    <Button
                                        color="info"
                                        onClick={() => {
                                            saveRequest(request?.status?.lookupValue ?? "Draft");
                                        }}
                                        type="button"
                                    >
                                        Save
                                    </Button>
                                </>
                            }
                            {
                                (isUserAdmin && request?.status?.lookupValue === PNM_StatusPending)
                                &&
                                <>
                                    <Button
                                        color="info"
                                        onClick={() => {
                                            saveRequest(completedStatusName, true);
                                        }}
                                        type="button"
                                        disabled={(
                                            (projectStatus && !projectStatus.tna)
                                        )}
                                    >
                                        Set to Complete
                                    </Button>
                                    <Button
                                        color="info"
                                        onClick={() => {
                                            if (request?.rosterId)
                                            {
                                                checkRosterAvailability();
                                                return;
                                            }

                                            saveRequest(approvedStatusName, true);
                                        }}
                                        type="button"
                                        disabled={(
                                            (projectStatus && !projectStatus.tna)
                                        )}
                                    >
                                        Send to Recruitment
                                    </Button>
                                </>
                            }
                            {
                                (request && (request?.status?.lookupValue !== pendingStatusName && request?.status?.lookupValue !== PNM_StatusPending && request?.status?.lookupValue !== approvedStatusName && request?.status?.lookupValue !== 'In Progress' && request?.status?.lookupValue !== "Void" && request?.status?.lookupValue !== completedStatusName && request?.status?.lookupValue !== pendingRedZoneStatusName && request?.status?.lookupValue !== pendingBudgetSightingStatusName)) &&
                                <Button
                                    color="info"
                                    onClick={() => {
                                        saveRequest('Ready', true);
                                    }}
                                    type="button"
                                    disabled={(
                                        (projectStatus && !projectStatus.tna) ||
                                        !isApproverExists
                                    )}
                                >
                                    Submit for Approval
                                </Button>
                            }
                            </Col>
                        }
                        {
                            (isApproval && request?.status?.lookupValue === pendingStatusName && approvalData && (user?.username.toLowerCase() === approvalData.approver?.email?.toLowerCase() || isUserAdmin)) &&
                            <Col xs={9} className="d-flex flex-wrap justify-content-end">
                                <Button
                                    color="warning"
                                    onClick={() => {
                                        saveRequest(pendingStatusName, true, true, rejectedStatusName);
                                    }}
                                    type="button"
                                    disabled={(
                                        (projectStatus && !projectStatus.tna)
                                    )}
                                >
                                    Reject
                                </Button>
                                <Button
                                    color="default"
                                    onClick={() => {
                                        if (request?.rosterId)
                                        {
                                            checkRosterAvailability();
                                            return;
                                        }

                                        saveRequest(pendingStatusName, true, false, approvedStatusName);
                                    }}
                                    type="button"
                                    disabled={(
                                        (projectStatus && !projectStatus.tna)
                                    )}
                                >
                                    Approve
                                </Button>
                            </Col>
                        }
                        {
                            (isApproval && request?.status?.lookupValue === pendingRedZoneStatusName && approvalData && (user?.username.toLowerCase() === approvalData.approver?.email?.toLowerCase() || isUserAdmin)) &&
                            <Col xs={9} className="d-flex flex-wrap justify-content-end">

                                <Button
                                    color="info"
                                    onClick={() => {
                                        saveRequest(pendingRedZoneStatusName);
                                    }}
                                    type="button" >
                                    Save
                                </Button>

                                <Button
                                    color="warning"
                                    onClick={() => {
                                        saveRequest(pendingRedZoneStatusName, true, true, hrDirectorRejectionStatusName);
                                    }}
                                    type="button"
                                    disabled={(
                                        (projectStatus && !projectStatus.tna)
                                    )}
                                >
                                    Reject by HR
                                </Button>
                            {
                                ((request?.recruitmentCategory?.lookupValue === "New Role" && request?.isBudgeted === false) || (request?.recruitmentCategory?.lookupValue === "Replacement" && request?.isLikeForLike === false)) &&
                                <Button
                                    color="warning"
                                    onClick={() => {
                                        saveRequest(pendingRedZoneStatusName, true, true, redZoneRejectionStatusName);
                                    }}
                                    type="button"
                                    disabled={(
                                        (projectStatus && !projectStatus.tna)
                                    )}
                                >
                                    Reject by Red Zone
                                </Button>
                            }
                                <Button
                                    color="default"
                                    onClick={() => {
                                        saveRequest(approvedStatusName, true, false, approvedStatusName);
                                    }}
                                    type="button"
                                    disabled={(
                                        (projectStatus && !projectStatus.tna)
                                    )}
                                >
                                    Approve
                                </Button>
                            </Col>
                        }
                        {
                            (isApproval && request?.status?.lookupValue === pendingBudgetSightingStatusName && approvalData && (user?.username.toLowerCase() === approvalData.approver?.email?.toLowerCase() || isUserAdmin)) &&
                            <Col xs={9} className="d-flex flex-wrap justify-content-end">

                                <Button
                                    color="info"
                                    onClick={() => {
                                        saveRequest(pendingBudgetSightingStatusName);
                                    }}
                                    type="button" >
                                    Save
                                </Button>

                                <Button
                                    color="warning"
                                    onClick={() => {
                                        saveRequest(pendingBudgetSightingStatusName, true, true, budgetSightingRejectionStatusName);
                                    }}
                                    type="button"
                                    disabled={(
                                        (projectStatus && !projectStatus.tna)
                                    )}
                                >
                                    Reject
                                </Button>
                                <Button
                                    color="default"
                                    onClick={() => {
                                        saveRequest(pendingBudgetSightingStatusName, true, false, approvedStatusName);
                                    }}
                                    type="button"
                                    disabled={(
                                        (projectStatus && !projectStatus.tna)
                                    )}
                                >
                                    Approve
                                </Button>
                            </Col>
                        }
                        </Row>
                    </Col>
                </Row>
            </Container>
        {
            !!itemInEdit &&
            <LabourRequestDetailModal
                item={itemInEdit}
                close={() => setItemInEdit(null)}
                save={handleSave}
                recruitmentType={request.recruitmentType.lookupValue}
                lookups={lookups}
                status={request.status}
                isApproval={isApproval}
            />
        }
        {
            actualRosterRequired.length > 0 &&
            <SweetAlert
                title="Quantity Required Adjustment Confirmation"
                warning
                showCancel
                cancelBtnText="No"
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                onConfirm={() => {
                    setRequest({
                        ...request,
                        requestDetails: request.requestDetails.map(r => {
                            const currentActual = actualRosterRequired.filter(a => a.requestDetailId === r.requestDetailId)[0];

                            return {
                                ...r,
                                quantityRequired: currentActual?.actualQuantityRequired ?? r.quantityRequired,
                            };
                        })
                    });

                    setActualRosterRequired([]);
                    setReadyToSave(true);
                }}
                onCancel={() => setActualRosterRequired([])}
            >
                <Container fluid>
                    <Row>
                        <Col lg="12" className="my-3">
                            <h5 className="text-justify">
                                There is a difference between requested quantity and actual quantity required. The requested quantity will be adjusted according to the actual quantity required. Continue?
                            </h5>
                        </Col>
                    </Row>
                </Container>
            </SweetAlert>
        }
        {
            showRejectReasonDialog &&
            <Modal
                isOpen={true}
                className="modal-lg"
                modalClassName="db-example-modal-lg"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Reject{rejectionStatus !== rejectedStatusName ? ` by ${rejectionStatus === hrDirectorRejectionStatusName ? "HR" : (rejectionStatus === redZoneRejectionStatusName ? "Red Zone" : (rejectionStatus === budgetSightingRejectionStatusName ? "HR" : ""))}` : ""} Dialog
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={() => {
                            setRejectedByRedZone(false);
                            setShowRejectReasonDialog(false);
                        }}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <FormGroup>
                        <Label>
                            Reason
                            <span className="text-danger">*</span>
                        </Label>
                        <Input
                            className="w-100"
                            value={rejectReason ?? ""}
                            onChange={(e) => setRejectReason(e.target.value)}
                            type="textarea"
                            rows={3}
                        />
                    </FormGroup>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={() => {
                            setRejectedByRedZone(false);
                            setShowRejectReasonDialog(false);
                        }}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button
                        color="warning"
                        onClick={() => updateStatus(rejectionStatus, rejectReason, () => {
                            setRejectedByRedZone(false);
                            setShowRejectReasonDialog(false);
                        })}
                        type="button"
                        disabled={!rejectReason}
                    >
                        Reject
                    </Button>
                </div>
            </Modal>
        }
        {
            showDateWarning &&
            <SweetAlert
                title="Recruitment Team Notes"
                info
                confirmBtnText="Ok"
                confirmBtnBsStyle="default"
                confirmBtnStyle={{width: "50%"}}
                onConfirm={() => {
                    history.push(isApproval ? "/lrfapproval" : "/lrflist");
                }}
            >
                <Container fluid>
                    <Row>
                        <Col lg="12" className="my-3">
                            <h6 className="text-justify">
                                Thank you for your LRF submission.
                                Please note that the recruitment process can take up to 15 business days to complete.
                                Should your request be actionable in a lesser period we will update you accordingly.<br /><br />
                                <br/>
                                Thank you<br/>
                                Altrad Recruitment
                            </h6>
                        </Col>
                    </Row>
                </Container>
            </SweetAlert>
        }
        {
            workorderAlert && workorderAlert.title && workorderAlert.message && !workorderAlert.confirmed ?
            <SweetAlert
                title={workorderAlert.title}
                warning
                showCancel
                cancelBtnText="No"
                confirmBtnText="Yes"
                confirmBtnBsStyle="default"
                onConfirm={() => {
                    setWorkorderAlert({
                        ...workorderAlert,
                        confirmed: true,
                    });
                }}
                onCancel={() => setWorkorderAlert(null)}
            >
                <Container fluid>
                    <Row>
                        <Col lg="12" className="my-3">
                            <h6 className="text-justify">
                            {
                                workorderAlert.message
                            }
                            </h6>
                        </Col>
                    </Row>
                </Container>
            </SweetAlert>
            :
            null
        }
        </section>
    );
}

export default LabourRequestForm;