/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTable, useRowSelect, useFlexLayout, useFilters } from 'react-table';
import {
    Container, Row, Col, Card, CardBody, CardTitle
} from "reactstrap";
import api from "../../../services/api";
import { TrainingMaster } from "./TrainingMaster";
import CrudList from "../../crud/CrudList";
import { Lookup } from "./Lookup";
import Loader from "../../loaders";
import TrainingCategory from "./TrainingCategory";
import { MilestoneTemplate } from "./MilestoneTemplate";
import { ContractTemplate } from "./ContractTemplate";
import ApprovalMaster from "./ApprovalMaster";
import EmailTemplate from "./EmailTemplate";
import { RecruitmentPeople } from "./RecruitmentPerson";
import JobDescription from "./JobDescription";
import { DefaultColumnFilter } from "../../react-table/filters";
import _ from "lodash";
import { TooltipButton } from "../../inputs";
import ProjectList from "./Client/ProjectList";
import LocationList from "./Project/LocationList";
import HrispositionList from "./HrispositionMaster/HrispositionList";
import RosterAttributeProjectList from "./RosterAttribute/ProjectList";
import MessagesMaster from "./MessagesMaster";
import { ProjectFeedbackQuestionList } from "./ProjectFeedbackQuestion";
import UserGroupMemberList from "./UserGroup/MemberList";
import RecruitmentApprovalFlowList from "./RecruitmentApprovalFlow";
import { useEffect } from "react";
import qs from "qs";
import { IsInteger, IsNullOrWhiteSpace } from "../../../helpers/StringHelper";

export default function Maintenance(props) {

    const Components = {
        TrainingMaster: TrainingMaster,
        TrainingCategory: TrainingCategory,
        MilestoneTemplate: MilestoneTemplate,
        ContractTemplate: ContractTemplate,
        ApprovalMaster: ApprovalMaster,
        EmailTemplate: EmailTemplate,
        RecruitmentPerson: RecruitmentPeople,
        JobDescription: JobDescription,
        ProjectFeedbackQuestion: ProjectFeedbackQuestionList,
        TrainingGroup: () => {
            return <CrudList
                title="Training Groups"
                endpoint="traininggroup"
                primaryKey="trainingGroupId"
                columns={[{ accessor: "trainingGroupName", title: "Name" }]}
            />
        },
        RateComponent: () => {
            return <CrudList
                title="Rate Component"
                endpoint="ratecomponent"
                primaryKey="rateComponentId"
                columns={[{ accessor: "rateComponentName", title: "Name" }, { accessor: "rateComponentDescription", title: "Description" }]}
            />
        },
        ApprovalType: () => {
            return <CrudList
                title="Approval Type"
                endpoint="approvaltype"
                primaryKey="approvalTypeId"
                columns={[{ accessor: "approvalTypeName", title: "Name" }]}
            />
        },
        EmploymentType: () => {
            return <CrudList
                title="Employment Type"
                endpoint="employmenttype"
                primaryKey="employmentTypeId"
                columns={[{ accessor: "employmentTypeName", title: "Name" }]}
            />
        },
        FulfillmentType: () => {
            return <CrudList
                title="Fulfillment Type"
                endpoint="fulfillmenttype"
                primaryKey="fulfillmentTypeId"
                columns={[{ accessor: "fulfillmentTypeName", title: "Name" }]}
            />
        },
        FundingSource: () => {
            return <CrudList
                title="Funding Source"
                endpoint="fundingsource"
                primaryKey="fundingSourceId"
                columns={[{ accessor: "fundingSourceName", title: "Name" }]}
            />
        },
        AlternativeLocation: () => {
            return <CrudList
                title="Alternative Location"
                endpoint="alternativeLocation"
                primaryKey="alternativeLocationId"
                columns={[{ accessor: "alternativeLocationName", title: "Name" }]}
            />
        },
        JobOrderMilestoneMaster: () => {
            return <CrudList
                title="Job Order Milestone Master"
                endpoint="jobordermilestonemaster"
                primaryKey="jobOrderMilestoneMasterId"
                columns={[
                    { accessor: "jobOrderMilestoneMasterName", title: "Name" },
                    { accessor: "userGroupName", accessorEntity: "groupOwner", isComboBox: true, accessorIdOnParent: "groupOwnerId", accessorId: "userGroupId", accessorValue: "userGroupName", endpoint: "/jobordermilestonemaster/user-group-list", title: "Group Owner", minLength: 0 },
                    { accessor: "requireSms", title: "Require SMS", inputType: "checkbox", checkedValue: true, uncheckedValue: null, width: 50, disableFilters: false }
                    //{ accessor: "airportName", accessorEntity: "airport", isComboBox: true, accessorId: "airportId", accessorValue: "airportName", endpoint: "/airport/search-maintenance", title: "Airport Name" }
                ]}
                customValidation={(item, setComponentLoading, callback) => {
                    if (!item) {
                        callback(false, "Data cannot be empty.");
                        return;
                    }



                    if (!item.jobOrderMilestoneMasterName) {
                        setComponentLoading(false);
                        callback(false, "Name cannot be empty");
                        return;
                    }


                    if (!item.groupOwnerId) {
                        setComponentLoading(false);
                        callback(false, "Group Owner cannot be empty");
                        return;
                    }

                    if (!item.requireSms) {
                        setComponentLoading(false);
                        callback(true, "Ok");
                        return;
                    }


                    const queryString = qs.stringify({ name: item.jobOrderMilestoneMasterName }, { allowDots: true });

                    api.get(`/jobordermilestonemaster/check-for-template?${queryString}`)
                        .then((response) => {
                            var foundTemplate = response.data;
                            console.log('foundTemplate', foundTemplate);


                            // Check if sms template not found
                            console.log({ item, response, result: foundTemplate.length > 0 });
                            callback(foundTemplate.length > 0, `Message Master template not found. Please open Maintenance -> Messages Master -> Add -> ${item.jobOrderMilestoneMasterName} -> Save.`);
                        })
                        .catch(() => callback(false, "System error."))
                        .finally(() => setComponentLoading(false))
                        ;
                }}
            />
        },
        HealthProvider: () => {
            return <CrudList
                title="Health Provider"
                endpoint="healthprovider"
                primaryKey="healthProviderId"
                columns={[{ accessor: "healthProviderName", title: "Name" }
                    , { accessor: "healthProviderDescription", title: "Description" }
                    , { accessor: "healthProviderAddress", title: "Address" }
                    , { accessor: "contactPerson", title: "Contact Person" }
                    , { accessor: "contactPersonEmail", title: "Contact Person Email" }
                    , { accessor: "contactPersonPhone", title: "Contact Person Phone" }]}
            />
        },
        TrainingProvider: () => {
            return <CrudList
                title="Training Provider"
                endpoint="trainingprovider"
                primaryKey="trainingProviderId"
                columns={[{ accessor: "trainingProviderName", title: "Name" }
                    , { accessor: "trainingProviderDescription", title: "Description" }
                    , { accessor: "contactPerson", title: "Contact Person" }
                    , { accessor: "contactPersonEmail", title: "Contact Person Email" }
                    , { accessor: "contactPersonPhone", title: "Contact Person Phone" }
                ]}
            />
        },
        MessagesMaster: MessagesMaster,
        // MessagesMaster: () => {        
        //     return <CrudList 
        //         title="Messages Master" 
        //         endpoint="messagesmaster"
        //         primaryKey="messagesMasterId"
        //         columns={[
        //             {accessor:"title", title:"Title"}
        //             ,{accessor:"message", title:"Message", inputType: "textarea", rows: 4} 
        //         ]}
        //     />
        // },
        Accommodation: () => {
            return <CrudList
                title="Accommodation"
                endpoint="accommodation"
                primaryKey="accommodationId"
                columns={[{ accessor: "accommodationName", title: "Name", isRequired: true }
                    , { accessor: "accommodationAddress", title: "Address", isRequired: true }
                    , { accessor: "phoneNumber", title: "Phone Number", isRequired: true }
                    , { accessor: "roomQty", title: "Room(s)", isRequired: true, inputType: "number", min: 1 }
                ]}

                customValidation={(item, setComponentLoading, callback) => {
                    if (!item) {
                        callback(false, "Data cannot be empty.");
                        return;
                    }

                    // Check if accommodationName is empty
                    if (IsNullOrWhiteSpace(item.accommodationName)) {
                        callback(false, "Name cannot be empty.");
                        return;
                    }

                    // Check if accommodationAddress is empty
                    if (IsNullOrWhiteSpace(item.accommodationAddress)) {
                        callback(false, "Address cannot be empty.");
                        return;
                    }

                    // Check if phoneNumber is empty
                    if (IsNullOrWhiteSpace(item.phoneNumber)) {
                        callback(false, "Phone Number cannot be empty.");
                        return;
                    }

                    // Check if roomQty is not integer
                    if (!IsInteger(item.roomQty)) {
                        callback(false, "Room(s) must be an integer.");
                        return;
                    }

                    // Check if roomQty is less than 1
                    if (item.roomQty < 1) {
                        callback(false, "Room(s) cannot be less than 1.");
                        return;
                    }


                    setComponentLoading(true);
                    const queryString = qs.stringify({ ...item }, { allowDots: true });

                    api.get(`/accommodation/getduplicates?${queryString}`)
                        .then((response) => {
                            var duplicatedData = response.data;
                            console.log('foundTemplate', duplicatedData);

                            // Find for duplicate accommodation name
                            var duplicatedCode = duplicatedData.find(x => x.accommodationName && item.accommodationName && x.accommodationName.toLowerCase() === item.accommodationName.toLowerCase());
                            if (duplicatedCode) {
                                callback(false, `Duplicate accommodation (${item.accommodationName}) found.`);
                                return;
                            }

                            // Find for duplicate accommodation name
                            console.log({ item, response, result: duplicatedData.length === 0 });
                            callback(duplicatedData.length === 0, "Duplicate accommodation found.");
                        })
                        .catch(() => callback(false, "System error."))
                        .finally(() => setComponentLoading(false))
                        ;
                }}
            />
        },
        Agency: () => {
            return <CrudList
                title="Agency"
                endpoint="agency"
                primaryKey="agencyId"
                columns={[{ accessor: "agencyName", title: "Name" }]}
            />
        },
        Airport: () => {
            return <CrudList
                title="Airport"
                endpoint="airport"
                primaryKey="airportId"
                columns={[{ accessor: "airportCode", title: "Code" }
                    , { accessor: "airportName", title: "Name" }
                    , { accessor: "airportCity", title: "City" }
                    , { accessor: "airportCounty", title: "Country" }
                ]}

                customValidation={(item, setComponentLoading, callback) => {
                    if (!item) {
                        callback(false, "Data cannot be empty.");
                        return;
                    }
                    //console.log('customValidation', { item, setComponentLoading, callback });
                    //return;
                    setComponentLoading(true);

                    const queryString = qs.stringify({ ...item }, { allowDots: true });

                    api.get(`/airport/getduplicates?${queryString}`)
                        .then((response) => {
                            var duplicatedData = response.data;
                            console.log('foundTemplate', duplicatedData);

                            // Find for duplicate airport code
                            var duplicatedCode = duplicatedData.find(x => x.airportCode && item.airportCode && x.airportCode.toLowerCase() === item.airportCode.toLowerCase());
                            if (duplicatedCode) {
                                callback(false, `Duplicate airport code (${item.airportCode}) found.`);
                                return;
                            }

                            // Find for duplicate airport name and city and country
                            console.log({ item, response, result: duplicatedData.length === 0 });
                            callback(duplicatedData.length === 0, "Duplicate airport found.");
                        })
                        .catch(() => callback(false, "System error."))
                        .finally(() => setComponentLoading(false))
                        ;
                }}
            />
        },
        AirportTerminal: () => {
            return <CrudList
                title="Airport Terminal"
                endpoint="airportterminal"
                primaryKey="airportTerminalId"
                columns={[{ accessor: "airportName", accessorEntity: "airport", isComboBox: true, accessorId: "airportId", accessorValue: "airportName", endpoint: "/airport/search-maintenance", title: "Airport Name" }
                    , { accessor: "terminalName", title: "Name" }
                    , { accessor: "baggageAllocation", title: "Baggage Allocation" }
                ]}

                customValidation={(item, setComponentLoading, callback) => {
                    if (!item) {
                        callback(false, "Data cannot be empty.");
                        return;
                    }
                    //console.log('customValidation', { item, setComponentLoading, callback });
                    //return;
                    setComponentLoading(true);

                    const queryString = qs.stringify({ ...item }, { allowDots: true });

                    api.get(`/airportterminal/getduplicates?${queryString}`)
                        .then((response) => {
                            var duplicatedData = response.data;
                            console.log('foundTemplate', duplicatedData);

                            // Find for duplicate airport terminal 
                            console.log({ item, response, result: duplicatedData.length === 0 });
                            callback(duplicatedData.length === 0, "Duplicate airport found.");
                        })
                        .catch(() => callback(false, "System error."))
                        .finally(() => setComponentLoading(false))
                        ;
                }}
            />
        },
        RecruitmentType: () => {
            return <CrudList
                title="Recruitment Type"
                endpoint="recruitmenttype"
                primaryKey="recruitmentTypeId"
                columns={[{ accessor: "recruitmentTypeName", title: "Name" }
                    , { accessor: "recruitmentTypeDescription", title: "Description", inputType: "textarea", rows: 4 }
                ]}
            />
        },
        Project: ({ setStateList }) => {
            return <CrudList
                title="Project"
                endpoint="project"
                endpointAdditionalParameters="/true"
                primaryKey="projectId"
                columns={[{ accessor: "projectName", title: "Name", readonly: false }
                    //,{accessor:"contractOwner", title:"Contract Owner", readonly: true}
                    , { accessor: "nameAndEmail", accessorEntity: "contractOwner", isComboBox: true, isLookup: false, accessorId: "contractOwnerId", accessorValue: "nameAndEmail", endpoint: "/lookup/users", title: "Project Manager" }
                    , { accessor: "nameAndEmail", accessorEntity: "generalManager", isComboBox: true, isLookup: false, accessorId: "generalManagerId", accessorValue: "nameAndEmail", endpoint: "/lookup/users", title: "General Manager" }
                    , { accessor: "nameAndEmail", accessorEntity: "executiveGeneralManager", isComboBox: true, isLookup: false, accessorId: "executiveGeneralManagerId", accessorValue: "nameAndEmail", endpoint: "/lookup/users", title: "Executive General Manager" }
                    , { accessor: "lookupValue", accessorEntity: "milestoneTemplate", isComboBox: true, isLookup: true, accessorId: "milestoneTemplateId", accessorValue: "lookupValue", endpoint: "/milestonetemplate", title: "Milestone Template", minLength: 0 }
                    , { accessor: "confirmationDetails", title: "Confirmation Details", inputType: "editor", width: 175 }
                    //, { accessor: "themeColor", title: "Theme Color", inputType: "colorpicker", width: 100 }
                    , { accessor: "viewOnly", title: "Hide", inputType: "checkbox", checkedValue: true, uncheckedValue: false, width: 50, disableFilters: false }
                ]}
                customSort={[
                    { id: "milestoneTemplate.lookupValue", desc: false },
                    { id: "viewOnly", desc: false },
                ]}
                alerts={[
                    { location: "editor", type: "warning", message: "Project Color Theme is deprecated. Please use Candidate Type Color Theme instead." },
                    { location: "list", type: "warning", message: "Project Color Theme is deprecated. Please use Candidate Type Color Theme instead." },
                ]}
                colEditorFill={null}
                colEditorEmpty={null}
                customCommandColumns={[
                    (original) => (
                        <TooltipButton
                            id={`location_list_${original?.projectId}`}
                            key={`location_list_${original?.projectId}`}
                            title="Location List"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setStateList.filter(s => s.key === "projectLocation")[0].callback(original.projectId);
                            }}
                        >
                            <i className="fas fa-list-ul pt-1"></i>
                        </TooltipButton>
                    )
                ]}
                customValidation={(item, setComponentLoading, callback) => {
                    if (!item) {
                        callback(false, "Data cannot be empty.");
                        return;
                    }

                    if (!item.projectId) {
                        callback(true);
                        return;
                    }

                    setComponentLoading(true);
                    api.get(`/project/lrf/approver/checkactive/${item.projectId}`)
                        .then((response) => {
                            // console.log({ item, response, result: response.data });
                            setComponentLoading(false);
                            callback(
                                !response.data,
                                "Project has active approvals. Change the approvers to these new approvers?",
                                true,
                                (option) => {
                                    setComponentLoading(true);
                                    api.put(`/project/${item.projectId}?updateLrfApprovers=${option.selectedOption}`, JSON.stringify(item))
                                        .then(() => option.callback())
                                        .catch(error => {
                                            console.error({ error })
                                        })
                                        .finally(() => setComponentLoading(false))
                                        ;
                                }
                            );
                        })
                        .catch(() => {
                            callback(false, "System error.");
                            setComponentLoading(false);
                        })
                }}
            />
        },
        Position: () => {
            return <CrudList
                title="Position"
                endpoint="position"
                primaryKey="positionId"
                columns={[{ accessor: "positionName", title: "Name", readonly: !canUpdatePosition }
                ]}
                readOnly={!canUpdatePosition}
                syncEnabled={true}
                syncEndpoint={"sync/position"}
            />
        },
        Client: ({ setStateList }) => {
            return <CrudList
                title="Client"
                endpoint="client"
                primaryKey="clientId"
                columns={[{ accessor: "clientNumber", title: "Client Number" }
                    , { accessor: "clientName", title: "Client Name" }
                ]}
                customCommandColumns={[
                    (original) => (
                        <TooltipButton
                            id={`project_list_${original?.clientId}`}
                            key={`project_list_${original?.clientId}`}
                            title="Project List"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setStateList.find(s => s.key === "clientProject").callback(original.clientId);
                            }}
                        >
                            <i className="fas fa-list-ul pt-1"></i>
                        </TooltipButton>
                    )
                ]}
            />
        },
        UserGroup: ({ setStateList }) => {
            return <CrudList
                title="User Group"
                endpoint="usergroup"
                primaryKey="userGroupId"
                columns={[
                    { accessor: "userGroupName", title: "User Group Name" }
                ]}
                customCommandColumns={[
                    (original) => (
                        <TooltipButton
                            id={`project_list_${original?.userGroupId}`}
                            key={`project_list_${original?.userGroupId}`}
                            title="Member List"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setStateList.find(s => s.key === "userGroupMember").callback(original.userGroupId);
                            }}
                        >
                            <i className="fas fa-list-ul pt-1"></i>
                        </TooltipButton>
                    )
                ]}
            />
        },
        Location: () => {
            return <CrudList
                title="Location"
                endpoint="location"
                primaryKey="locationId"
                dropdownDataEndpoints={[{ id: 1, endpoint: api.get("/alternativelocation/search") }]}
                columns={[{ accessor: "country", title: "Country" }
                    , { accessor: "state", title: "State" }
                    , { accessor: "locationName", title: "Location Name" }
                    , { accessor: "alternativeLocationName", accessorEntity: "alternativeLocation", isDropdown: true, endpointId: 1, isLookup: false, accessorId: "alternativeLocationId", accessorValue: "alternativeLocationName", title: "Alternative Location" }
                ]}

                customValidation={(item, setComponentLoading, callback) => {
                    if (!item) {
                        callback(false, "Data cannot be empty.");
                        return;
                    }
                    //console.log('customValidation', { item, setComponentLoading, callback });
                    //return;
                    setComponentLoading(true);

                    const queryString = qs.stringify({ ...item }, { allowDots: true });

                    api.get(`/location/getduplicates?${queryString}`)
                        .then((response) => {
                            console.log({ item, response, result: response.data.length === 0 });
                            callback(response.data.length === 0, "Duplicate location found.");
                        })
                        .catch(() => callback(false, "System error."))
                        .finally(() => setComponentLoading(false))
                        ;
                }}
            />
        },
        SystemConfig: () => {
            return <CrudList
                title="System Config"
                endpoint="systemconfig"
                primaryKey="systemConfigId"
                columns={[{ accessor: "systemConfigName", title: "System Config Name", isRequired: true }
                    , { accessor: "systemConfigValue", title: "Value 1", isRequired: true }
                    , { accessor: "systemConfigValue2", title: "Value 2", isRequired: false }
                ]}

                customValidation={(item, setComponentLoading, callback) => {
                    if (!item) {
                        callback(false, "Data cannot be empty.");
                        return;
                    }
                    if (IsNullOrWhiteSpace(item.systemConfigValue)) {
                        callback(false, "Value 1 cannot be empty.");
                        return;
                    }
                    //console.log('customValidation', { item, setComponentLoading, callback });
                    //return;
                    setComponentLoading(true);

                    const queryString = qs.stringify({ ...item }, { allowDots: true });

                    api.get(`/systemconfig/getduplicates?${queryString}`)
                        .then((response) => {
                            console.log({ item, response, result: response.data.length === 0 });

                            if (response.data.length)
                                setComponentLoading(false);

                            callback(
                                response.data.length === 0,
                                "Duplicate system config found.",
                                false,
                                () => {
                                    if (response.data.length)
                                        return;

                                    api.get(`/systemconfig/lrf/approver/checkactive/${item.systemConfigId}/${item.systemConfigValue}`)
                                        .then((caResponse) => {
                                            // console.log({ item, response, result: response.data });
                                            setComponentLoading(false);
                                            callback(
                                                !caResponse.data,
                                                "The last managing director has active approvals. Change the approver to the new approver?",
                                                true,
                                                (caOption) => {
                                                    setComponentLoading(true);
                                                    api.put(`/systemconfig/${item.systemConfigId}/md?updateLrfApprovers=${caOption.selectedOption}`, JSON.stringify(item))
                                                        .then(() => caOption.callback())
                                                        .catch(error => {
                                                            console.error({ error })
                                                        })
                                                        .finally(() => setComponentLoading(false))
                                                        ;
                                                }
                                            );
                                        })
                                        .catch(() => {
                                            callback(false, "System error.");
                                            setComponentLoading(false);
                                        });
                                }
                            );
                        })
                        .catch(() => {
                            callback(false, "System error.");
                            setComponentLoading(false);
                        });
                }}
            />
        },
        CandidateType: () => {
            return <CrudList
                title="Candidate Type"
                endpoint="candidateType"
                primaryKey="candidateTypeId"
                columns={[
                    { accessor: "value", title: "Candidate Type Name" },
                    { accessor: "label", title: "Candidate Type Description" },
                    { accessor: "enabled", title: "Enabled", inputType: "checkbox", checkedValue: true, uncheckedValue: false, width: 50, disableFilters: false },
                    { accessor: "themeColor", title: "Theme Color", inputType: "colorpicker", width: 100, disableFilters: true, disableSortBy: true }
                ]}
            />
        },
        ContractingCompany: () => {
            return <CrudList
                title="Contracting Company"
                endpoint="contractingCompany"
                primaryKey="contractingCompanyId"
                columns={[
                    { accessor: "contractingCompanyName", title: "Contracting Company Name" }

                ]}
            />
        },
        ProjectAttribute: () => {
            return <CrudList
                title="Project Specific Attribute"
                endpoint="projectattribute"
                primaryKey="projectAttributeId"
                dropdownDataEndpoints={[{ id: 1, endpoint: api.get("/lookup/projectattributetype") }]}
                columns={[{ accessor: "lookupValue", accessorEntity: "project", isComboBox: true, isLookup: true, accessorId: "projectId", accessorValue: "lookupValue", endpoint: "/project", title: "Project Name" }
                    , { accessor: "projectAttributeName", title: "Attribute Name" }
                    , { accessor: "lookupValue", accessorEntity: "projectAttributeType", isDropdown: true, endpointId: 1, isLookup: true, accessorId: "projectAttributeTypeId", accessorValue: "lookupValue", title: "Attribute Type" }
                ]}
            />
        },
        Hrisposition: () => {
            return <CrudList
                title="HRIS Position"
                endpoint="hrisposition"
                searchEndpoint="hrisposition/maintenance"
                primaryKey="hrispositionId"
                columns={[{ accessor: "lookupValue", accessorEntity: "position", isComboBox: true, isLookup: true, accessorId: "positionId", accessorValue: "lookupValue", endpoint: "/position", title: "Employed Position Name" }
                    , { accessor: "hrispositionName", title: "Position Name" }
                ]}
            />
        },
        HrispositionMaster: ({ setStateList }) => {
            return <CrudList
                title="HRIS Position Master"
                endpoint="hrispositionMaster"
                primaryKey="hrispositionMasterId"
                columns={[{ accessor: "hrispositionMasterName", title: "Position Name" }
                ]}
                customCommandColumns={[
                    (original) => (
                        <TooltipButton
                            id={`hrisposition_list_${original?.hrispositionMasterId}`}
                            key={`hrisposition_list_${original?.hrispositionMasterId}`}
                            title="HRIS Position List"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setStateList.filter(s => s.key === "hrispositionMasterPosition")[0].callback(original.hrispositionMasterId);
                            }}
                        >
                            <i className="fas fa-list-ul pt-1"></i>
                        </TooltipButton>
                    )
                ]}
                customValidation={(item, setComponentLoading, callback) => {
                    if (!item) {
                        callback(false, "Data cannot be empty.");
                        return;
                    }

                    setComponentLoading(true);
                    api.get(`/hrispositionmaster/getduplicates?name=${item.hrispositionMasterName}${item.hrispositionMasterId ? `&id=${item.hrispositionMasterId}` : ""}`)
                        .then((response) => {
                            // console.log({ item, response, result: response.data.length === 0 });
                            callback(response.data.length === 0, "Duplicate name found.");
                        })
                        .catch(() => callback(false, "System error."))
                        .finally(() => setComponentLoading(false))
                        ;
                }}
            />
        },
        RosterAttribute: ({ setStateList }) => {
            return <CrudList
                title="Roster Specific Attribute"
                endpoint="rosterattribute"
                primaryKey="rosterAttributeId"
                dropdownDataEndpoints={[{ id: 1, endpoint: api.get("/lookup/projectattributetype") }]}
                columns={[{ accessor: "attributeOrderIndex", title: "No.", disableFilters: true, inputType: "number", disableSortBy: true, readonly: true }
                    , { accessor: "rosterAttributeName", title: "Attribute Name", disableSortBy: true }
                    , { accessor: "lookupValue", accessorEntity: "rosterAttributeType", disableFilters: false, isDropdown: true, endpointId: 1, isLookup: true, accessorId: "rosterAttributeTypeId", accessorValue: "lookupValue", title: "Attribute Type", disableSortBy: true }
                    , { accessor: "allProjects", disableFilters: false, title: "All Projects", checkedValue: true, uncheckedValue: false, inputType: "checkbox", disableSortBy: true }
                ]}
                customCommandColumns={[
                    (original) => (
                        <TooltipButton
                            id={`roster_spc_list_${original?.rosterAttributeId}`}
                            key={`roster_spc_list_${original?.rosterAttributeId}`}
                            title="Project List"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setStateList.filter(s => s.key === "rosterSpecificProject")[0].callback(original.rosterAttributeId);
                            }}
                            disabled={original?.allProjects}
                        >
                            <i className="fas fa-list-ul pt-1"></i>
                        </TooltipButton>
                    ),
                    (original, totalRecords, loadData, setLoading) => (
                        <TooltipButton
                            id={`roster_spc_up_${original?.rosterAttributeId}`}
                            key={`roster_spc_up_${original?.rosterAttributeId}`}
                            title="Move Up"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setLoading(true);
                                api.put(`rosterattribute/move/${original?.rosterAttributeId}/-1`)
                                    .then(() => {
                                        setLoading(false);
                                        loadData();
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        console.error(error);
                                    })
                            }}
                            disabled={original?.attributeOrderIndex === 1}
                        >
                            <i className="fas fa-chevron-up pt-1"></i>
                        </TooltipButton>
                    ),
                    (original, totalRecords, loadData, setLoading) => {
                        // console.log({ original, totalRecords, loadData });
                        return (
                            <TooltipButton
                                id={`roster_spc_down_${original?.rosterAttributeId}`}
                                key={`roster_spc_down_${original?.rosterAttributeId}`}
                                title="Move Down"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => {
                                    setLoading(true);
                                    api.put(`rosterattribute/move/${original?.rosterAttributeId}/1`)
                                        .then(() => {
                                            setLoading(false);
                                            loadData();
                                        })
                                        .catch((error) => {
                                            setLoading(false);
                                            console.error(error);
                                        })
                                }}
                                disabled={original?.attributeOrderIndex === totalRecords}
                            >
                                <i className="fas fa-chevron-down pt-1"></i>
                            </TooltipButton>
                        )
                    }
                ]}
            />
        },
        RosterSequence: () => {
            return <CrudList
                title="Roster Sequence"
                endpoint="rostersequence"
                primaryKey="rosterSequenceId"
                columns={[{ accessor: "daysOn", title: "Days On", isRequired: true, inputType: "number" }
                    , { accessor: "daysOff", title: "Days Off", isRequired: true, inputType: "number" }
                    , { accessor: "description", title: "Description", isRequired: true }
                ]}
                customSort={[
                    { id: "daysOn", desc: false },
                    { id: "daysOff", desc: false },
                    { id: "description", desc: false },
                ]}

                customValidation={(item, setComponentLoading, callback) => {
                    console.log('customValidation');
                    console.log('item', item);
                    if (!item) {
                        callback(false, "Data cannot be empty.");
                        return;
                    }
                    if (IsNullOrWhiteSpace(item.daysOn)) {
                        callback(false, "Days On cannot be empty.");
                        return;
                    }
                    if (IsNullOrWhiteSpace(item.daysOff)) {
                        callback(false, "Days Off cannot be empty.");
                        return;
                    }
                    if (IsNullOrWhiteSpace(item.description)) {
                        callback(false, "Description cannot be empty.");
                        return;
                    }
                    //console.log('customValidation', { item, setComponentLoading, callback });
                    //return;
                    setComponentLoading(true);

                    const queryString = qs.stringify({ ...item }, { allowDots: true });

                    api.get(`/rostersequence/getduplicates?${queryString}`)
                        .then((response) => {
                            var duplicatedData = response.data;
                            console.log('foundTemplate', duplicatedData);

                            // Find for duplicate airport terminal 
                            console.log({ item, response, result: duplicatedData.length === 0 });
                            callback(duplicatedData.length === 0, "Duplicate Roster Sequence found.");
                        })
                        .catch(() => callback(false, "System error."))
                        .finally(() => setComponentLoading(false))
                        ;
                }}
            />
        },
        RecruitmentApprovalFlow: RecruitmentApprovalFlowList,
        Lookup: Lookup,
    }


    const [data, setData] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState({});
    const [loading, setLoading] = React.useState({});
    const [selectedClientId, setSelectedClientId] = React.useState(null);
    const [selectedUserGroupId, setSelectedUserGroupId] = React.useState(null);
    const [selectedProjectId, setSelectedProjectId] = React.useState(null);
    const [selectedHrispositionMasterId, setSelectedHrispositionMasterId] = React.useState(null);
    const [selectedRosterAttributeId, setSelectedRosterAttributeId] = React.useState(null);
    const [canUpdatePosition, setCanUpdatePosition] = React.useState(false);

    const { selected, mode, id } = useParams();
    const { location } = props;

    React.useEffect(() => {
        setLoading(true);

        api.get('maintenance/lookups')
            .then((response) => {
                //var newData = response.data.map(m => ({ ...m,  }));
                setData(response.data);
                if (selected) {
                    setSelectedType(_.find(response.data, o => o.name.toLowerCase() === selected.toLowerCase()) ?? response.data[0]);
                } else {
                    setSelectedType(response.data[0]);
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));

        api.get('maintenance/candidate-configuration')
            .then((response) => {
                setCanUpdatePosition(response.data.canUpdatePosition);
            })
            .catch((error) => console.error(error))
            .finally(() => { });
    }, [selected]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue).replace(/[/-]/g, "")
                            .toLowerCase()
                            .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Type",
                //accessor: "name",
                accessor: (row) => !row.keywords ? row.name : `${row.name};${row.keywords}`,
                Cell: ({ row }) => row.original.name,
                id: "name",
                Filter: DefaultColumnFilter,
                filter: "text",
            },
        ]
    }, []);

    const defaultColumn = React.useMemo(
        () => ({
            width: 190,
        }), [])

    const initialState = useMemo(() => {
        const defaultState = {
            "selectedRowIds": {
                "0": true
            },
            "hiddenColumns": []
        }

        if (!data?.length || !selectedType?.type)
            return defaultState;

        const selectedData = data.filter(d => d.name.toLowerCase() === selectedType.name.toLowerCase())[0];

        if (!selectedData)
            return defaultState;

        const selectedId = data.indexOf(selectedData);

        const initialFilter = [{
            id: 'name',
            value: selected ?? "",
        }];

        return {
            "selectedRowIds": {
                [selectedId]: true
            },
            "hiddenColumns": [],
            "filters": initialFilter
        };
    }, [selectedType, data, selected]);

    useEffect(() => {
        console.log('initialState', initialState);
    }, [initialState]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        toggleAllRowsSelected,
        toggleRowSelected,
        setFilter,
        state: { filters },

    } = useTable(
        {
            columns,
            data,
            initialState,
            filterTypes,
            defaultColumn
        },
        useFilters,
        useRowSelect,
        useFlexLayout
    );


    //useEffect(() => {
    //    console.log('filters', filters);
    //}, [filters])


    //useEffect(() => {
    //    setFilter('name', selected ?? "");
    //}, [selected, setFilter])

    const typeList = useMemo(() => {
        return (
            <div {...getTableProps()} className="table table-hover mb-3">
                <div className="thead">
                    {headerGroups.map(headerGroup => (
                        <div {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map(column => (
                                <div {...column.getHeaderProps()} className="th">
                                    <span>
                                        {column.render('Header')}
                                    </span>

                                    <div className="mt-1">
                                        {column.canFilter ? column.render('Filter') : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div {...getTableBodyProps()} style={{ height: "calc(100vh - 340px)", overflowY: "scroll" }}>
                    {rows.map((row, i) => {
                        prepareRow(row);

                        return (
                            <div {...row.getRowProps()} className={`tr ${initialState.selectedRowIds[row.id] ? "selected" : ""} cursor-pointer`}
                                onClick={() => {
                                    toggleAllRowsSelected(false);
                                    toggleRowSelected(row.id);
                                    setSelectedType(row.original)
                                }}
                                key={`module-list-${i}`}
                            >
                                {row.cells.map((cell, j) => {
                                    return <div {...cell.getCellProps()} className="td" key={`module-list-child-${j}`}>{cell.render('Cell')}</div>
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }, [getTableProps, headerGroups, getTableBodyProps, rows, prepareRow, toggleAllRowsSelected, toggleRowSelected, state, initialState, selected]);

    const setStateList = [
        { key: "clientProject", callback: setSelectedClientId },
        { key: "userGroupMember", callback: setSelectedUserGroupId },
        { key: "projectLocation", callback: setSelectedProjectId },
        { key: "hrispositionMasterPosition", callback: setSelectedHrispositionMasterId },
        { key: "rosterSpecificProject", callback: setSelectedRosterAttributeId }
    ];

    return (
        <section className="main">
            {loading && <Loader />}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <Row>
                                    <Col sm={12} md={6} lg={3} style={{ overflowX: "scroll", paddingLeft: 5, paddingRight: 0 }}>
                                        {
                                            typeList
                                        }
                                    </Col>
                                    <Col sm={12} md={6} lg={9} style={{ paddingLeft: 0, paddingRight: 5 }}>

                                        {typeof Components[selectedType.type] !== "undefined"
                                            ? React.createElement(Components[selectedType.type], { setStateList, selectedType, mode, id, location })
                                            : React.createElement(() => <div className="d-flex align-items-center justify-content-center w-100 h-100"><span>The {selectedType.name} component has not been created yet.</span></div>, {})
                                        }
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                selectedClientId &&
                <ProjectList
                    clientId={selectedClientId}
                    setSelectedClientId={setSelectedClientId}
                />
            }
            {
                selectedUserGroupId &&
                <UserGroupMemberList
                    userGroupId={selectedUserGroupId}
                    setSelectedUserGroupId={setSelectedUserGroupId}
                />
            }
            {
                selectedProjectId &&
                <LocationList
                    projectId={selectedProjectId}
                    setSelectedProjectId={setSelectedProjectId}
                />
            }
            {
                selectedHrispositionMasterId &&
                <HrispositionList
                    hrispositionMasterId={selectedHrispositionMasterId}
                    setSelectedHrispositionMasterId={setSelectedHrispositionMasterId}
                />
            }
            {
                selectedRosterAttributeId &&
                <RosterAttributeProjectList
                    rosterAttributeId={selectedRosterAttributeId}
                    setSelectedRosterAttributeId={setSelectedRosterAttributeId}
                />
            }
        </section>
    )
}