/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from "../../../../services/api";
import axios from "axios";
import qs from "qs";
import {
    Button, Modal, Row, Col, TabContent, TabPane, Nav, NavLink, NavItem, Card, Alert, FormGroup, Label, Input, Container
} from "reactstrap";
import Loader from "../../../loaders";
import { formatDate } from "../../../../utils";
import { DateTimePicker } from "../../../date-pickers";
import { ComboBox } from "../../../dropdowns";
import HotTable, { HotColumn } from '@handsontable/react';
import debounce from 'lodash.debounce';
import { TooltipButton } from '../../../inputs';
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import CandidateConfirmationEditor from './CandidateConfirmationEditor';
import LogisticFlightConfirmEditor from './LogisticFlightConfirmEditor';
import LogisticAccommodationConfirmEditor from './LogisticAccommodationConfirmEditor';
import LogisticSummaryConfirmEditor from './LogisticSummaryConfirmEditor';
import EditorSMS from '../../Candidates/EditorSMS';
import FlightSearchEditor from './FlightSearchEditor';
import { ErrorNotification } from '../../../alerts';
import { MakeWords } from '../../../../helpers/StringHelper';
import _ from 'lodash';


const tabs = [{ key: 1, title: "Accommodation" }, { key: 2, title: "Flight" }];
const dateTimeFormat = "DD/MM/YYYY hh:mm A";
const dateFormat = "DD/MM/YYYY";
const timeFormat = "hh:mm A";

function SearchButton(props) {
    return (
        <div className="cursor-pointer w-100 h-100 py-1 pr-1 m-0 d-flex flex-wrap justify-content-center">
            <i className="fas fa-search"></i>
        </div>
    )
}

export default function LogisticEditor(props) {
    const {
        project,
        dispatchUnits,
        rosters,
        startDate,
        endDate,
        rosterCandidates,
        rosterAccommodationStatuses,
        rosterFlightTypes,
        rosterFlightStatuses,
        onClose,
        onSaved,
        selectedRows
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [validationAlert, setValidationAlert] = React.useState([]);
    const [accommodations, setAccommodations] = React.useState([]);
    const [accommodationTableColumns, setAccommodationTableColumns] = useState(null);
    const [accommodationSelectedCell, setAccommodationSelectedCell] = useState(null);
    const [flights, setFlights] = React.useState([]);
    const [flightTableColumns, setFlightTableColumns] = useState(null);
    const [flightSelectedCell, setFlightSelectedCell] = useState(null);
    const [flightSearchRowId, setFlightSearchRowId] = useState(null);
    const [activeTab, setActiveTab] = React.useState(tabs[1]);
    const [selectedDispatchUnitId, setSelectedDispatchUnitId] = useState(null);
    const [selectedRosterId, setSelectedRosterId] = useState(null);
    const [title, setTitle] = useState(null);
    const [candidateTitle, setCandidateTitle] = useState(null);
    const [saveBeforeMessage, setSaveBeforeMessage] = useState(false);
    const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [showMessageConfirmation, setShowMessageConfirmation] = useState(false);
    const [showRosterSummary, setShowRosterSummary] = useState(false);
    const [showFlightMobeConfirmation, setShowFlightMobeConfirmation] = useState(false);
    const [showFlightDemobeConfirmation, setShowFlightDemobeConfirmation] = useState(false);
    const [showAccommodationConfirmation, setShowAccommodationConfirmation] = useState(false);
    const [showConfirmationSummary, setShowConfirmationSummary] = useState(false);
    const [failedMessages, setFailedMessages] = useState(null);
    const [saved, setSaved] = useState(false);
    const [selectedRosterCandidates, setSelectedRosterCandidates] = useState([]);
    const [selectedMobeFlights, setSelectedMobeFlights] = useState([]);
    const [selectedDemobeFlights, setSelectedDemobeFlights] = useState([]);
    const [selectedAccommodations, setSelectedAccommodations] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openFlightOptions, setOpenFlightOptions] = useState(false);
    const [dateGroups, setDateGroups] = useState([]);

    const apiurl = "rostercandidateaccommodation";
    const accommodationTableComponent = useRef(null);
    const flightTableComponent = useRef(null);


    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Logistic Editor`);

    const loadCandidateData = useCallback(() => {
        const refType = rosterCandidates && rosterCandidates.length > 0 ? "RC" : (rosters && rosters.length > 0 ? "R" : (dispatchUnits && dispatchUnits.length > 0 ? "D" : ""));

        if (refType === "")
            return;

        const refIds = [];

        if (refType === "RC")
            rosterCandidates.forEach(rc => {
                refIds.push(rc.rosterCandidateId);
            });

        if (refType === "R")
            rosters.forEach(rc => {
                refIds.push(rc.rosterId);
            });

        if (refType === "D")
            dispatchUnits.forEach(rc => {
                refIds.push(rc.dispatchUnitId);
            });

        if (refIds.length === 0)
            return;

        setLoading(true);

        const refIdStr = qs.stringify({ refIds }, { allowDots: true });

        const apiCalls = [];

        if (refType === "RC")
            apiCalls.push(api.get(`rostercandidate/candidates?${refIdStr}`));

        if (refType === "R")
            apiCalls.push(api.get(`roster/candidates?${refIdStr}`));

        if (refType === "D")
            apiCalls.push(api.get(`dispatchunit/candidates?${refIdStr}`));

        Promise.all(apiCalls)
            .then((response) => {
                if (apiCalls.length)
                    setCandidates(response[0].data.filter(rc => rc.candidateId).map(rc => {
                        const { candidate } = rc;
                        // console.log({rc, candidate});
                        return {
                            ...candidate,
                            currentProjectName: project.projectName,
                            projectName: project.projectName,
                            dispatchUnitId: rc.roster?.dispatchUnitId,
                            dispatchUnitName: rc.roster?.dispatchUnit?.dispatchUnitName,
                            quoteNumber: rc.roster?.dispatchUnit?.quoteNumber,
                            rosterId: rc.rosterId,
                            rosterName: rc.roster?.rosterName,
                            locationId: rc.roster?.dispatchUnit?.locationId,
                            locationName: rc.roster?.dispatchUnit?.location?.lookupValue,
                            dispatchStartDate: moment(rc.roster?.dispatchUnit?.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                            dispatchEndDate: moment(rc.roster?.dispatchUnit?.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                            startDate: moment(rc.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                            endDate: moment(rc.endDate, "YYYY-MM-DD").format("DD-MM-YYYY")
                        };
                    }));
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => {
                setLoading(false);
            });
    }, [project, dispatchUnits, rosters, rosterCandidates]);

    const loadData = React.useCallback(() => {
        setAccommodationTableColumns([
            "Start Date",
            "End Date",
            "Name",
            "Availability",
            "Address",
            "Phone Number",
            "Comments",
            "Status"
        ]);
        setFlightTableColumns([
            "Search",
            "Flight Number",
            "From",
            "Terminal",
            "To",
            "Terminal",
            "Flight Date",
            "Flight Time",
            "AM/PM",
            "Arrival Date",
            "Arrival Time",
            "Comments",
            "Flight Type",
            "Status"
        ]);

        // console.log({dispatchUnits, rosters, rosterCandidates});
        if (!dispatchUnits && !rosters && !rosterCandidates)
            return;

        setLoading(true);
        const queryString = qs.stringify({ filters: [{ id: "title", value: "Accommodation" }] }, { allowDots: true });

        const refIds = [];
        const apiCalls = [
            api.get(`/messagesmaster?${queryString}`),
        ];

        if (rosterCandidates && rosterCandidates.length) {
            const refType = "RC";
            rosterCandidates.forEach(rc => refIds.push(rc.rosterCandidateId));
            const accmdnQs = qs.stringify({ refType, refIds }, { allowDots: true });
            apiCalls.push(api.get(`/rostercandidateaccommodation/accommodations?${accmdnQs}`));
            apiCalls.push(api.get(`/rostercandidateaccommodation/flights?${accmdnQs}`));
            apiCalls.push(api.get(`/rostercandidateschedule/waiting-schedules?${accmdnQs}`));
        }

        Promise.all(apiCalls)
            .then((response) => {
                // console.log(response);
                //var alteredRca = response[1].data.map(x => {
                //    ...x,
                //    availability: `${x.roomTaken + 1} of ${x.roomQty}`
                //});


                setConfirmationMessage(response[0].data?.data[0]);

                if (response[3].data)
                {
                    const newDateGroups = _(response[3].data)
                        .groupBy("rosterCandidateId")
                        .map((rosterCandidateData, keyValue) => {
                            return {
                                rosterCandidateId: keyValue,
                                dates: rosterCandidateData,
                            }
                        })
                        .value();

                    setDateGroups(newDateGroups);
                }
                else
                    setDateGroups([]);

                const propAccmdns = [];
                const propFlights = [];

                if (rosterCandidates && rosterCandidates.length > 0) {
                    //accommodations
                    const firstRC = rosterCandidates.sort((a, b) => {
                        return a.rosterCandidateId > b.rosterCandidateId;
                    })[0];
                    const firstRCAccommodations = response[1].data.filter(rc => rc.rosterCandidateId === firstRC.rosterCandidateId);
                    // console.log(firstRCAccommodations);

                    if (firstRCAccommodations && firstRCAccommodations.length > 0) {
                        const checkAccommodations = firstRCAccommodations.map(x => {
                            return {
                                ...x,
                                startDate: x.startDate && x.startDate !== "" ? moment(x.startDate, "YYYY-MM-DD").format("DD/MM/YYYY") : null,
                                endDate: x.startDate && x.startDate !== "" ? moment(x.endDate, "YYYY-MM-DD").format("DD/MM/YYYY") : null,
                                accommodationName: x.accommodation?.accommodationName,
                                accommodationAddress: x.accommodation?.accommodationAddress,
                                phoneNumber: x.accommodation?.phoneNumber,
                                accommodationStatusName: x.accommodationStatus?.lookupValue,
                                accommodationNote: x.accommodationNote
                            };
                        });

                        let checkResult = true;
                        const restRC = rosterCandidates.filter(x => x.rosterCandidateId !== firstRC.rosterCandidateId);

                        for (let i = 0; i < restRC.length && checkResult; i++) {
                            const restRCAccommodations = response[1].data.filter(rc => rc.rosterCandidateId === restRC[i].rosterCandidateId);

                            if (!restRCAccommodations || restRCAccommodations.length !== checkAccommodations.length) {
                                checkResult = false;
                                continue;
                            }

                            for (let j = 0; j < checkAccommodations.length && checkResult; j++) {
                                if (
                                    restRCAccommodations.filter(x => {
                                        return moment(x.startDate, "YYYY-MM-DD").format("DD/MM/YYYY") === checkAccommodations[j].startDate
                                            && moment(x.endDate, "YYYY-MM-DD").format("DD/MM/YYYY") === checkAccommodations[j].endDate
                                            && x.accommodationId === checkAccommodations[j].accommodationId
                                            && x.accommodationStatusId === checkAccommodations[j].accommodationStatusId;
                                    }
                                    ).length === 0
                                )
                                    checkResult = false;
                            }
                        }

                        if (checkResult)
                            checkAccommodations.forEach(x => {
                                propAccmdns.push(x);
                            });
                    }

                    const firstRCSchedules = firstRC.rosterCandidateSchedules?.filter(x => x.rosterScheduleType.rosterScheduleTypeCode === "M" || x.rosterScheduleType.rosterScheduleTypeCode === "D");
                    if (firstRCSchedules && firstRCSchedules.length > 0) {
                        // console.log(firstRCSchedules, firstRC);
                        const checkFlights = [];

                        firstRCSchedules.forEach(rcs => {
                            checkFlights.push(
                                ...response[2].data.filter(f => f.rosterCandidateScheduleId === rcs.rosterCandidateScheduleId).map(f => {
                                    return {
                                        ...f,
                                        flightFromName: f.flightFrom?.airportName,
                                        flightToName: f.flightTo?.airportName,
                                        flightStatusName: f.flightStatus?.lookupValue,
                                        rosterFlightTypeName: f.rosterFlightType?.lookupValue,
                                        airportTerminalName: f.airportTerminal?.terminalName,
                                        arrivalTerminalName: f.arrivalTerminal?.terminalName,
                                        flightDate: moment(f.flightDateTime, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                                        flightTime: moment(f.flightDateTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
                                        arrivalDate: moment(f.arrivalTime, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                                        arrivalTime: moment(f.arrivalTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
                                        canSearch: f.flightFrom && f.flightTo
                                    }
                                })
                            );
                        });

                        let checkResult = firstRCSchedules.length > 0;
                        const restRC = rosterCandidates.filter(x => x.rosterCandidateId !== firstRC.rosterCandidateId);

                        for (let i = 0; i < restRC.length && checkResult; i++) {
                            const restRCSchedules = restRC[i].rosterCandidateSchedules?.filter(x =>
                                firstRCSchedules.filter(y =>
                                    y.date === x.date
                                    && y.rosterScheduleType.rosterScheduleTypeCode === x.rosterScheduleType.rosterScheduleTypeCode
                                ).length > 0
                            );

                            if (!restRCSchedules || restRCSchedules.length !== firstRCSchedules.length) {
                                checkResult = false;
                                continue;
                            }

                            const restFlights = [];

                            restRCSchedules.forEach(rcs => {
                                restFlights.push(
                                    ...response[2].data.filter(f => f.rosterCandidateScheduleId === rcs.rosterCandidateScheduleId).map(f => {
                                        return {
                                            ...f,
                                            flightDate: moment(f.flightDateTime, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                                            flightTime: moment(f.flightDateTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
                                            arrivalDate: moment(f.arrivalTime, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                                            arrivalTime: moment(f.arrivalTime, "YYYY-MM-DDTHH:mm").format("HH:mm")
                                        }
                                    })
                                );
                            });

                            // console.log(restFlights, checkFlights);

                            if (checkFlights.length === 0 && restFlights.length === 0)
                                continue;

                            if (restFlights.length !== checkFlights.length) {
                                checkResult = false;
                                continue;
                            }

                            for (let j = 0; j < checkFlights.length && checkResult; j++) {
                                // console.log(restFlights, checkFlights);
                                if (
                                    restFlights.filter(x => {
                                        // console.log(
                                        //     x.flightDate === checkFlights[j].flightDate
                                        //     , x.flightTime === checkFlights[j].flightTime
                                        //     , x.arrivalDate === checkFlights[j].arrivalDate
                                        //     , x.arrivalTime === checkFlights[j].arrivalTime
                                        //     , x.flightNumber === checkFlights[j].flightNumber
                                        //     , x.flightFromId === checkFlights[j].flightFromId
                                        //     , x.flightToId === checkFlights[j].flightToId
                                        //     , x.flightStatusId === checkFlights[j].flightStatusId
                                        //     , x.flightComment === checkFlights[j].flightComment
                                        //     , x.rosterFlightTypeId === checkFlights[j].rosterFlightTypeId
                                        //     , x.checkinTerminal === checkFlights[j].checkinTerminal
                                        //     , x.airportTerminalId === checkFlights[j].airportTerminalId
                                        // );
                                        return x.flightDate === checkFlights[j].flightDate
                                            && x.flightTime === checkFlights[j].flightTime
                                            && x.arrivalDate === checkFlights[j].arrivalDate
                                            && x.arrivalTime === checkFlights[j].arrivalTime
                                            && x.flightNumber === checkFlights[j].flightNumber
                                            && x.flightFromId === checkFlights[j].flightFromId
                                            && x.flightToId === checkFlights[j].flightToId
                                            && x.flightStatusId === checkFlights[j].flightStatusId
                                            && x.flightComment === checkFlights[j].flightComment
                                            && x.rosterFlightTypeId === checkFlights[j].rosterFlightTypeId
                                            && x.checkinTerminal === checkFlights[j].checkinTerminal
                                            && x.airportTerminalId === checkFlights[j].airportTerminalId
                                            && x.arrivalTerminalId === checkFlights[j].arrivalTerminalId
                                            && x.ampm === checkFlights[j].ampm
                                            ;
                                    }
                                    ).length === 0
                                )
                                    checkResult = false;
                            }
                        }

                        // console.log(checkResult, checkFlights);

                        if (checkResult) {
                            if (checkFlights && checkFlights.length > 0)
                                checkFlights.forEach(x => {
                                    propFlights.push(x);
                                });

                            firstRCSchedules.filter(x => {
                                // console.log({x}, !propFlights?.length || !propFlights.map(p => p.rosterCandidateScheduleId).includes(x.rosterCandidateScheduleId));
                                return !propFlights?.length || !propFlights.map(p => p.rosterCandidateScheduleId).includes(x.rosterCandidateScheduleId);
                            }).forEach(x => {
                                const flightType = rosterFlightTypes.filter(y => y.lookupValue === (x.rosterScheduleType.rosterScheduleTypeCode === "M" ? "Mobilisation" : "Demobilisation"))[0];
                                const flightStatus = rosterFlightStatuses.filter(y => y.lookupValue === "Pending")[0];
                                propFlights.push({
                                    flightId: 0,
                                    flightDate: moment(x.date, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                                    flightTime: moment(x.date, "YYYY-MM-DDTHH:mm").format("HH:mm"),
                                    arrivalDate: moment(x.date, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                                    arrivalTime: moment(x.date, "YYYY-MM-DDTHH:mm").format("HH:mm"),
                                    flightNumber: null,
                                    flightFromId: null,
                                    flightFrom: null,
                                    flightToId: null,
                                    flightTo: null,
                                    flightStatusId: flightStatus?.lookupId,
                                    flightStatus: flightStatus,
                                    flightStatusName: flightStatus?.lookupValue,
                                    flightComment: null,
                                    rosterFlightTypeId: flightType?.lookupId,
                                    rosterFlightType: flightType,
                                    rosterFlightTypeName: flightType?.lookupValue,
                                    checkinTerminal: null,
                                    airportTerminalId: null,
                                    airportTerminal: null,
                                    arrivalTerminalId: null,
                                    arrivalTerminal: null,
                                    flightFromName: null,
                                    flightToName: null,
                                    airportTerminalName: null,
                                    arrivalTerminalName: null,
                                    ampm: null,
                                    canSearch: false
                                });
                            });
                        }
                    }

                    // if (propFlights.length === 0 && firstRC.rosterCandidateSchedules?.length > 0)
                    // {
                    //     console.log(rosterFlightTypes);
                    //     const accmdnDates = firstRC.rosterCandidateSchedules.filter(x => x.rosterScheduleType.rosterScheduleTypeCode === "M" || x.rosterScheduleType.rosterScheduleTypeCode === "D");
                    //     accmdnDates.sort((a, b) => moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD"));
                    //     console.log(rosterFlightTypes);
                    //     accmdnDates.forEach(x => {
                    //         const flightType = rosterFlightTypes.filter(y => y.lookupValue === (x.rosterScheduleType.rosterScheduleTypeCode === "M" ? "Mobilisation" : "Demobilisation"))[0];
                    //         const flightStatus = rosterFlightStatuses.filter(y => y.lookupValue === "Pending")[0];
                    //         console.log(flightType);
                    //         const value = {
                    //             flightId: 0,
                    //             flightDate: moment(x.date, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                    //             flightTime: moment(x.date, "YYYY-MM-DDTHH:mm").format("HH:mm"),
                    //             arrivalDate: moment(x.date, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                    //             arrivalTime: moment(x.date, "YYYY-MM-DDTHH:mm").format("HH:mm"),
                    //             flightNumber: null,
                    //             flightFromId: null,
                    //             flightFrom: null,
                    //             flightToId: null,
                    //             flightTo: null,
                    //             flightStatusId: flightStatus?.lookupId,
                    //             flightStatus: flightStatus,
                    //             flightStatusName: flightStatus?.lookupValue,
                    //             flightComment: null,
                    //             rosterFlightTypeId: flightType?.lookupId,
                    //             rosterFlightType: flightType,
                    //             rosterFlightTypeName: flightType?.lookupValue,
                    //             checkinTerminal: null,
                    //             airportTerminalId: null,
                    //             airportTerminal: null,
                    //             flightFromName: null,
                    //             flightToName: null,
                    //             airportTerminalName: null,
                    //         };
                    //         console.log(value);
                    //         propFlights.push(value);
                    //     });
                    // }

                    setAccommodations(propAccmdns);


                    propFlights.sort((a, b) => a.flightDate && b.flightDate ? (moment(a.flightDate, "DD/MM/YYYY") < moment(b.flightDate, "DD/MM/YYYY") ? -1 : 1) : (!a.flightDate ? -1 : 1));
                    setFlights(propFlights);
                    // setRosterCandidateData(rosterCandidates);
                    setLoading(false);
                    loadCandidateData();
                    return;
                }

                setAccommodations(propAccmdns);
                setFlights(propFlights);
                setLoading(false);
                loadCandidateData();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;

        let currentTitle = " - Multiple";

        if (dispatchUnits) {
            currentTitle = ` - ${dispatchUnits.length === 1 ? dispatchUnits[0].dispatchUnitName : "Multiple"}`;

            if (rosters)
                currentTitle = `${currentTitle} - ${rosters.length === 1 ? rosters[0].rosterName : "Multiple"}`;
        }

        setTitle(currentTitle);

        if (rosterCandidates && rosterCandidates.length > 0) {
            let currentCandidateTitle = "";

            rosterCandidates.forEach(x => {
                currentCandidateTitle = `${currentCandidateTitle}${currentCandidateTitle !== "" ? "," : ""} ${x.candidateName}`;
            });

            setCandidateTitle(currentCandidateTitle);
        }
    }, [dispatchUnits, rosters, rosterCandidates, rosterFlightTypes, rosterFlightStatuses, loadCandidateData]);

    useEffect(() => {
        if (!dispatchUnits && !rosters && !rosterCandidates)
            return;

        loadData();
    }, [dispatchUnits, rosters, rosterCandidates, rosterFlightTypes, rosterFlightStatuses]);


    // Validate candidate and hris position master 
    useEffect(() => {
        if (!saveBeforeMessage || !selectedRows?.length)
            return;

        // Check if there is empty candidate slot
        // console.log('selectedRows', selectedRows);
        // console.log('saveBeforeMessage', saveBeforeMessage);

        var emptyCandidateSlot = selectedRows.filter(x => !x.candidateId);
        var emptyHrisPosMasterSlot = selectedRows.filter(x => !x.hrispositionMasterId);
        // console.log('emptyCandidateSlot', emptyCandidateSlot);
        // console.log('emptyHrisPosMasterSlot', emptyHrisPosMasterSlot);
        if (emptyCandidateSlot?.length)
        {

            var slotNos = emptyCandidateSlot.map(x => x.slot.toString());
            var slotNosStr = MakeWords(slotNos);
            // console.log('slotNosStr', slotNosStr);
            setErrorMessage(`Please enter Employee Name for slot no. ${slotNosStr}`);
            setSaveBeforeMessage(false);

        }
        else if (emptyHrisPosMasterSlot?.length)
        {

            var slotNos = emptyHrisPosMasterSlot.map(x => x.slot.toString());
            var slotNosStr = MakeWords(slotNos);
            // console.log('slotNosStr', slotNosStr);
            setErrorMessage(`Please enter Roster Position for slot no. ${slotNosStr}`);
            setSaveBeforeMessage(false);
        }




    }, [saveBeforeMessage, selectedRows?.length])

    // useEffect(() => {
    //     console.log('candidateTitle', candidateTitle);
    // }, [candidateTitle]);


    const updateData = (oldData, rowNum, value, entityName, dataNameField, dataIdField, entityNameField, entityIdField, url, setState, parallelFunction = null) => {
        setLoading(true);
        const newData = [...oldData];
        const selectedData = newData[rowNum];

        // console.log('updateData', updateData);
        // console.log('selectedData', selectedData);

        if (!value || value === "") {
            selectedData[dataIdField] = null;
            selectedData[entityName] = null;
            selectedData[entityNameField] = null;
            newData.splice(
                newData.indexOf(selectedData),
                1,
                selectedData
            );
            setState(newData);
            setLoading(false)
            return;
        }

        const splittedStr = value.split(" - ");
        const id = parseInt(splittedStr[splittedStr.length - 1]);

        if (!id) {
            selectedData[dataIdField] = null;
            selectedData[entityName] = null;
            selectedData[entityNameField] = null;
            newData.splice(
                newData.indexOf(selectedData),
                1,
                selectedData
            );
            setState(newData);
            setLoading(false);
            return;
        }

        if (parallelFunction != null) {
            parallelFunction(id);
        }

        api.get(`${url}/${id}`)
            .then((response) => {
                selectedData[entityName] = response.data;
                const name = `${response.data[entityNameField]} - ${response.data[entityIdField]}`;
                selectedData[dataNameField] = name;
                selectedData[dataIdField] = response.data[entityIdField];
                newData.splice(
                    newData.indexOf(selectedData),
                    1,
                    selectedData
                );
                // console.log(newData);
                setState(newData);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false));
    };

    const searchData = useCallback(
        debounce((query, process, url, nameField, idField, additionalQueries = []) => {
            setLoading(true);
            let queryString = null;
            const filters = [
                ...additionalQueries
            ];

            if (query && query !== "")
                filters.push({ id: "name", value: query });

            queryString = qs.stringify({ filters: filters }, { allowDots: true });

            api.get(`${url}${queryString ? `?${queryString}` : ""}`)
                .then((response) => {
                    if (!response.data || response.data.length === 0) {
                        // console.log(`${url} empty`);
                        process([]);
                        return;
                    }

                    process(response.data.map(x => {
                        return `${x[nameField]} - ${x[idField]}`;
                    }));
                })
                .catch((error) => console.error({error}))
                .finally(() => setLoading(false))
                ;
        }, 200)
        , []);

    const addAccommodationRow = useCallback((rowIdx) => {
        const hotTable = accommodationTableComponent.current.hotInstance;

        if (!hotTable)
            return;

        const newData = [...accommodations];
        const accmdnStatus = rosterAccommodationStatuses?.filter(x => x.lookupValue === "Pending")[0];

        if (dateGroups.length > 0)
        {
            const currentData = accommodations[rowIdx - 1];
            const dateGroup = dateGroups[0];
            const dateGroupItem = currentData ? dateGroup.dates.filter(d => d.startDate && d.endDate && d.startDate > moment(currentData.startDate, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss"))[0] : dateGroup.dates[0];

            if (dateGroupItem)
            {
                newData.splice(
                    rowIdx,
                    0,
                    {
                        rosterCandidateAccommodationId: 0,
                        accommodationId: null,
                        accommodation: null,
                        accommodationStatusId: accmdnStatus?.lookupId,
                        accommodationStatus: accmdnStatus,
                        accommodationStatusName: accmdnStatus?.lookupValue,
                        startDate: moment(dateGroupItem.startDate).format("DD/MM/YYYY"),
                        endDate: moment(dateGroupItem.endDate).format("DD/MM/YYYY"),
                        accommodationNote: null,
                    }
                );
                setAccommodations(newData);
                return;
            }
        }

        const mobeDates = rosterCandidates.map(rc => rc.mobilisationDate);
        mobeDates.sort((a, b) => new Date(a) - new Date(b));
        const startDate = mobeDates[0] ? moment(mobeDates[0]).format("DD/MM/YYYY") : null;
        const demobeDates = rosterCandidates.map(rc => rc.demobilisationDate);
        demobeDates.sort((a, b) => new Date(b) - new Date(a));
        const endDate = demobeDates[0] ? moment(demobeDates[0]).format("DD/MM/YYYY") : null;

        // console.log({rosterCandidates, mobeDates, startDate, demobeDates, endDate});

        newData.splice(
            rowIdx,
            0,
            {
                rosterCandidateAccommodationId: 0,
                accommodationId: null,
                accommodation: null,
                accommodationStatusId: accmdnStatus?.lookupId,
                accommodationStatus: accmdnStatus,
                accommodationStatusName: accmdnStatus?.lookupValue,
                startDate: startDate ?? null,
                endDate: endDate ?? null,
                accommodationNote: null,
            }
        );
        setAccommodations(newData);
    }, [rosterCandidates, rosterAccommodationStatuses, accommodationTableComponent, accommodations, dateGroups]);

    const removeAccommodationRow = useCallback(() => {
        if (!accommodations || accommodations.length === 0 || !accommodationSelectedCell)
            return;

        const newAccommodations = [...accommodations];
        newAccommodations.splice(accommodationSelectedCell.row, 1);
        setAccommodations(newAccommodations);
    }, [accommodations, accommodationSelectedCell]);

    useEffect(() => {
        const hotTable = accommodationTableComponent.current?.hotInstance;

        if (!hotTable)
            return;


    }, [accommodations, accommodationTableComponent]);

    const accommodationTable = useMemo(() => {
        return (
            <HotTable
                ref={accommodationTableComponent}
                id="accommodationtable"
                colHeaders={accommodationTableColumns}
                data={accommodations}
                licenseKey="5c26d-2087e-1df71-d6238-dfc22"
                rowHeaders={true}
                stretchH={'all'}
                autoColumnSize={true}
                autoRowSize={false}
                filters={true}
                outsideClickDeselects={false}
                manualRowMove={true}
                manualColumnResize={true}
                manualColumnFreeze={true}
                bindRowsWithHeaders={true}
                height={'calc(100vh - 500px)'}
                contextMenu={
                    {
                        items: {
                            row_below: {
                                name: 'Add row',
                                callback(key, selection, clickEvent) {
                                    addAccommodationRow(selection[0].start.row + 1);
                                }
                            },
                            remove_row: {
                                name: 'Remove row',
                                callback() {
                                    removeAccommodationRow();
                                }
                            }
                        }
                    }
                }
                afterSetDataAtCell={(changes, source) => {
                    if (changes[0][1] === "accommodationName") {
                        // console.log('changes', changes);
                        // console.log('source', source);

                        //const queryString = qs.stringify({ ... }, { allowDots: true });

                        var selectedData = accommodations[changes[0][0]];
                        var startDate = selectedData?.startDate;
                        var endDate = selectedData?.endDate;

                        var startDateStr = '';
                        var endDateStr = '';

                        if (startDate && startDate !== "")
                            startDateStr = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");

                        if (endDate && endDate !== "")
                            endDateStr = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

                        
                        //var startDateStr = 

                        updateData(accommodations, changes[0][0], changes[0][3], "accommodation", "accommodationName", "accommodationId", "accommodationName", "accommodationId", `/accommodation/with-availability/${startDateStr}/${endDateStr}`, (val) => {
                            val[changes[0][0]].accommodationAddress = val[changes[0][0]]?.accommodation?.accommodationAddress;
                            val[changes[0][0]].phoneNumber = val[changes[0][0]]?.accommodation?.phoneNumber;
                            val[changes[0][0]].availability = val[changes[0][0]]?.accommodation?.availability;
                            val[changes[0][0]].availabilityPlusOne = val[changes[0][0]]?.accommodation?.availabilityPlusOne;
                            //val[changes[0][0]].availability = "-- Please wait --";
                            // console.log(val);
                            setAccommodations(val);
                        });
                        return;
                    }

                    if (changes[0][1] === "accommodationStatusName") {
                        updateData(accommodations, changes[0][0], changes[0][3], "accommodationStatus", "accommodationStatusName", "accommodationStatusId", "lookupValue", "lookupId", "/lookup/id", setAccommodations);
                        return;
                    }
                }}
                afterSelection={(row, column, row2, column2) => {
                    setAccommodationSelectedCell({ row: row, column: column });
                }}
            >
                <HotColumn data={'startDate'} type="date" width={100} />
                <HotColumn data={'endDate'} type="date" width={100} />
                <HotColumn data={'accommodationName'}
                    type="dropdown"
                    strict={true}
                    source={(query, process) => {
                        searchData(query, process, "/accommodation/search", "accommodationName", "accommodationId");
                    }}
                    width={150}
                />
                <HotColumn data={'availabilityPlusOne'} readOnly={true} width={100} />
                <HotColumn data={'accommodationAddress'} readOnly={true} className="light-yellow" width={150} />
                <HotColumn data={'phoneNumber'} readOnly={true} className="light-yellow" width={100} />
                <HotColumn data={'accommodationNote'} width={150} />
                <HotColumn data={'accommodationStatusName'}
                    type="dropdown"
                    strict={true}
                    source={(query, process) => {
                        searchData(query, process, "/lookup/accommodationstatus", "lookupValue", "lookupId");
                    }}
                    width={100}
                />
            </HotTable>
        );
    }, [accommodationTableComponent, accommodationTableColumns, accommodations, selectedDispatchUnitId, selectedRosterId, addAccommodationRow, removeAccommodationRow]);

    const addFlightRow = useCallback((rowIdx) => {
        const hotTable = flightTableComponent.current.hotInstance;

        if (!hotTable)
            return;

        const newData = [...flights];
        const flightStatus = rosterFlightStatuses?.filter(x => x.lookupValue === "Pending")[0];
        newData.splice(
            rowIdx,
            0,
            {
                flightId: 0,
                flightDate: null,
                flightTime: null,
                arrivalDate: null,
                arrivalTime: null,
                flightNumber: null,
                flightFromId: null,
                flightFrom: null,
                flightToId: null,
                flightTo: null,
                flightStatusId: flightStatus?.lookupId,
                flightStatus: flightStatus,
                flightStatusName: flightStatus?.lookupValue,
                flightComment: null,
                rosterFlightTypeId: null,
                rosterFlightType: null,
                checkinTerminal: null,
                airportTerminalId: null,
                airportTerminal: null,
                arrivalTerminalId: null,
                arrivalTerminal: null,
                ampm: null,
                canSearch: false
            }
        );
        setFlights(newData);
    }, [rosterFlightStatuses, flightTableComponent, flights]);

    const removeFlightRow = useCallback(() => {
        if (!flights || flights.length === 0 || !flightSelectedCell)
            return;

        const newFlights = [...flights];
        newFlights.splice(flightSelectedCell.row, 1);
        setFlights(newFlights);
    }, [flights, flightSelectedCell]);

    // BEGIN ERROR MESSAGE HANDLER -----------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        // console.log('errorMessage', errorMessage);
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);
    // END ERROR MESSAGE HANDLER -------------------------------------------------------------------------------------------------------------------------------------------

    const flightTable = useMemo(() => {
        return (
            <HotTable
                ref={flightTableComponent}
                id="flightTable"
                colHeaders={flightTableColumns}
                data={flights}
                licenseKey="5c26d-2087e-1df71-d6238-dfc22"
                rowHeaders={true}
                stretchH={'all'}
                autoColumnSize={true}
                autoRowSize={false}
                filters={true}
                outsideClickDeselects={false}
                manualRowMove={true}
                manualColumnResize={true}
                manualColumnFreeze={true}
                bindRowsWithHeaders={true}
                height={'calc(100vh - 500px)'}
                contextMenu={
                    {
                        items: {
                            row_below: {
                                name: 'Add row',
                                callback(key, selection, clickEvent) {
                                    addFlightRow(selection[0].start.row + 1);
                                }
                            },
                            remove_row: {
                                name: 'Remove row',
                                callback() {
                                    removeFlightRow();
                                }
                            }
                        }
                    }
                }
                afterSetDataAtCell={(changes, source) => {

                    const hotTable = flightTableComponent.current?.hotInstance;

                    //console.log('afterSetDataAtCell', { changes, source, hotTable })
                    if (!hotTable)
                        return;

                    const rowData = hotTable.getSourceDataAtRow(changes[0][0]);
                    const canSearchCol = hotTable.propToCol("canSearch");

                    if (changes[0][1] === "flightNumber") {
                        setLoading(true);
                        // console.log(`${process.env.REACT_APP_AIRLABS_URL}flight?flight_iata=${changes[0][3]}&api_key=${process.env.REACT_APP_AIRLABS_KEY}`);
                        axios.get(`${process.env.REACT_APP_AIRLABS_URL}flight?flight_iata=${changes[0][3]}&api_key=${process.env.REACT_APP_AIRLABS_KEY}`)
                            .then(({ data: { response } }) => {
                                if (!response) {
                                    hotTable.setDataAtCell(changes[0][0], 2, null);
                                    hotTable.setDataAtCell(changes[0][0], 3, null);
                                    hotTable.setDataAtCell(changes[0][0], 4, null);
                                    hotTable.setDataAtCell(changes[0][0], 5, null);
                                    return;
                                }

                                // console.log('Flight: ', {  arr_iata, arr_terminal, dep_iata, dep_terminal })
                                const { arr_iata, arr_terminal, dep_iata, dep_terminal } = response;

                                const queryString = qs.stringify({
                                    depAirportCode: dep_iata,
                                    arrAirportCode: arr_iata,
                                    depTerminalName: dep_terminal,
                                    arrTerminalName: arr_terminal
                                }, { allowDots: true });

                                api.get(`/rostergrid/flight?${queryString}`)
                                    .then(({ data }) => {
                                        //console.log('data: ', data)
                                        hotTable.setDataAtCell(changes[0][0], 2, data.depAirport);
                                        hotTable.setDataAtCell(changes[0][0], 3, data.depTerminal);
                                        hotTable.setDataAtCell(changes[0][0], 4, data.arrAirport);
                                        hotTable.setDataAtCell(changes[0][0], 5, data.arrTerminal);
                                    })
                                    .catch((error) => {
                                        console.error({error})
                                    })
                                    .finally(() => {
                                        setLoading(false)
                                    })
                            })
                            .catch((error) => {
                                console.error({error})
                                setLoading(false)
                            })
                    }

                    if (changes[0][1] === "flightFromName") {
                        updateData(flights, changes[0][0], changes[0][3], "flightFrom", changes[0][1], "flightFromId", "airportName", "airportId", "/airport", setFlights);
                        hotTable.setDataAtCell(changes[0][0], canSearchCol, changes[0][3] && changes[0][3] !== "" && rowData.flightTo !== null);
                        return;
                    }

                    if (changes[0][1] === "airportTerminalName") {
                        updateData(flights, changes[0][0], changes[0][3], "airportTerminal", changes[0][1], "airportTerminalId", "terminalName", "airportTerminalId", "/airportterminal", setFlights);
                        return;
                    }

                    if (changes[0][1] === "flightToName") {
                        updateData(flights, changes[0][0], changes[0][3], "flightTo", changes[0][1], "flightToId", "airportName", "airportId", "/airport", setFlights);
                        hotTable.setDataAtCell(changes[0][0], canSearchCol, changes[0][3] && changes[0][3] !== "" && rowData.flightFrom !== null);
                        return;
                    }

                    if (changes[0][1] === "arrivalTerminalName") {
                        updateData(flights, changes[0][0], changes[0][3], "arrivalTerminal", changes[0][1], "arrivalTerminalId", "terminalName", "airportTerminalId", "/airportterminal", setFlights);
                        return;
                    }

                    if (changes[0][1] === "rosterFlightTypeName") {
                        updateData(flights, changes[0][0], changes[0][3], "rosterFlightType", changes[0][1], "rosterFlightTypeId", "lookupValue", "lookupId", "/lookup/id", setFlights);
                        return;
                    }

                    if (changes[0][1] === "flightStatusName") {
                        updateData(flights, changes[0][0], changes[0][3], "flightStatus", changes[0][1], "flightStatusId", "lookupValue", "lookupId", "/lookup/id", setFlights);
                        return;
                    }

                    if (changes[0][1] === "flightTime") {
                        const ampmColIndex = hotTable.propToCol("ampm");
                        const departureTime = moment(changes[0][3], "HH:mm");
                        const separationTime = moment("12:00", "HH:mm");

                        if (separationTime <= departureTime)
                            hotTable.setDataAtCell(changes[0][0], ampmColIndex, "PM");

                        if (separationTime > departureTime)
                            hotTable.setDataAtCell(changes[0][0], ampmColIndex, "AM");

                        return;
                    }
                }}
                afterSelection={(row, column, row2, column2) => {
                    setFlightSelectedCell({ row: row, column: column });
                }}
                afterOnCellMouseUp={(e, coord) => {
                    const { col, row } = coord;
                    const hotTable = flightTableComponent.current?.hotInstance;

                    if (!hotTable)
                        return;

                    if (col === hotTable.propToCol('canSearch')) {
                        const rowData = hotTable.getSourceDataAtRow(row);

                        if (!rowData)
                            return;

                        // console.log(row);
                        setSelectedRowData(rowData);
                        setFlightSearchRowId(row);
                        setOpenFlightOptions(true);

                        return;
                    }
                }}
            >
                <HotColumn data={`canSearch`} readOnly={true} className='cursor-pointer'>
                    <SearchButton hot-renderer />
                </HotColumn>
                <HotColumn data={'flightNumber'} width={150} />
                <HotColumn data={'flightFromName'}
                    type="dropdown"
                    strict={true}
                    source={(query, process) => {
                        searchData(query, process, "/airport/search", "airportName", "airportId");
                    }}
                    width={200}
                />
                <HotColumn data={'airportTerminalName'}
                    type="dropdown"
                    strict={true}
                    source={(query, process) => {
                        if (!flightSelectedCell)
                            return;

                        const airportId = flights[flightSelectedCell.row]?.flightFrom?.airportId;
                        // console.log(flights[flightSelectedCell.row], airportId);
                        const additionalFilters = [{ id: "airportId", value: (airportId ? airportId : 0) }];
                        searchData(query, process, "/airportterminal/search", "terminalName", "airportTerminalId", additionalFilters);
                    }}
                    width={100}
                />
                <HotColumn data={'flightToName'}
                    type="dropdown"
                    strict={true}
                    source={(query, process) => {
                        searchData(query, process, "/airport/search", "airportName", "airportId");
                    }}
                    width={200}
                />
                <HotColumn data={'arrivalTerminalName'}
                    type="dropdown"
                    strict={true}
                    source={(query, process) => {
                        if (!flightSelectedCell)
                            return;

                        const airportId = flights[flightSelectedCell.row]?.flightTo?.airportId;
                        // console.log(flights[flightSelectedCell.row], airportId);
                        const additionalFilters = [{ id: "airportId", value: (airportId ? airportId : 0) }];
                        searchData(query, process, "/airportterminal/search", "terminalName", "airportTerminalId", additionalFilters);
                    }}
                    width={100}
                />
                <HotColumn data={'flightDate'} type="date" correctFormat={true} dateFormat='DD/MM/YYYY' width={100} />
                <HotColumn data={'flightTime'} type="time" correctFormat={true} timeFormat="HH:mm" width={100} />
                <HotColumn data={'ampm'}
                    type="dropdown"
                    strict={true}
                    source={["AM", "PM"]}
                    width={60}
                />
                <HotColumn data={'arrivalDate'} type="date" correctFormat={true} dateFormat='DD/MM/YYYY' width={100} />
                <HotColumn data={'arrivalTime'} type="time" correctFormat={true} timeFormat="HH:mm" width={100} />
                <HotColumn data={'flightComment'} width={150} />
                <HotColumn data={'rosterFlightTypeName'}
                    type="dropdown"
                    strict={true}
                    source={(query, process) => {
                        searchData(query, process, "/lookup/rosterflighttype", "lookupValue", "lookupId");
                    }}
                    width={120}
                />
                <HotColumn data={'flightStatusName'}
                    type="dropdown"
                    strict={true}
                    source={(query, process) => {
                        searchData(query, process, "/lookup/flightstatus", "lookupValue", "lookupId");
                    }}
                    width={100}
                />
            </HotTable>
        );
    }, [flightTableComponent, flightTableColumns, flights, flightSelectedCell, addFlightRow, removeFlightRow, selectedRowData]);

    const validateData = useCallback(() => {
        const errors = [];
        setLoading(true);

        if (!accommodations && !flights) {
            errors.push({ key: "nodata", type: "data", message: "No data to be saved." });
            return errors;
        }

        if (accommodations && accommodations.filter(x => !x.accommodationId).length > 0) {
            errors.push({ key: "invalidaccommodation", type: "data", message: "Accommodation Name cannot be empty." });
            return errors;
        }

        if (flights && flights.filter(x => !x.rosterFlightTypeId).length > 0) {
            errors.push({ key: "invalidflight", type: "data", message: "Flight Type cannot be empty." });
            return errors;
        }

        const refType = (rosterCandidates && rosterCandidates.length > 0 ? "RosterCandidate" : (rosters && rosters.length > 0 ? "Roster" : (dispatchUnits && dispatchUnits.length > 0 ? "DispatchUnit" : "")));

        if (refType === "") {
            errors.push({ key: "system", type: "system", message: "System error." });
            return errors;
        }

        return errors;
    }, [dispatchUnits, rosters, rosterCandidates, accommodations, flights]);

    const handleSave = React.useCallback(() => {
        setLoading(true);
        setValidationAlert(null);
        const errors = validateData();

        if (errors.length > 0) {
            if (showSaveConfirmation)
                setShowSaveConfirmation(false);

            if (saveBeforeMessage)
                setSaveBeforeMessage(false);

            setValidationAlert(errors);
            setLoading(false);
            return;
        }

        const refType = (rosterCandidates && rosterCandidates.length > 0 ? "RosterCandidate" : (rosters && rosters.length > 0 ? "Roster" : (dispatchUnits && dispatchUnits.length > 0 ? "DispatchUnit" : "")));
        const body = {
            refType: refType,
            refIds: (
                refType === "RosterCandidate" ? rosterCandidates?.map(x => x.rosterCandidateId)
                    :
                    (
                        refType === "Roster" ? rosters?.map(x => x.rosterId)
                            :
                            (
                                refType === "DispatchUnit" ? dispatchUnits?.map(x => x.dispatchUnitId) : null
                            )
                    )
            ),
            accommodations: accommodations.map(x => {
                return {
                    ...x,
                    startDate: x.startDate && x.startDate !== "" ? `${moment(x.startDate, "DD/MM/YYYY").format("YYYY-MM-DD")}` : null,
                    endDate: x.endDate && x.endDate !== "" ? `${moment(x.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")}` : null
                }
            }),
            flights: flights.map(x => {
                return {
                    ...x,
                    flightDateTime: x.flightDate && x.flightDate !== "" ? `${moment(x.flightDate, "DD/MM/YYYY").format("YYYY-MM-DD")}T${x.flightTime && x.flightTime !== "" ? moment(x.flightTime, "HH:mm").format("HH:mm") : "00:00"}` : null,
                    arrivalTime: x.arrivalDate && x.arrivalDate !== "" ? `${moment(x.arrivalDate, "DD/MM/YYYY").format("YYYY-MM-DD")}T${x.arrivalTime && x.arrivalTime !== "" ? moment(x.arrivalTime, "HH:mm").format("HH:mm") : "00:00"}` : null
                }
            }),
            startDate: moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
            endDate: moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        };

        // console.log(body);

        // api.post("/rostercandidateaccommodation", body)
        api.post("/rostercandidateaccommodation/save", body)
            .then(() => {
                if (saveBeforeMessage)
                    setSaved(true);

                if (showSaveConfirmation)
                    onSaved();
            })
            .catch((error => {
                console.error({error});
                setValidationAlert([{ key: "system", type: "system", message: "System error" }]);
            }))
            .finally(() => {
                if (showSaveConfirmation)
                    setShowSaveConfirmation(false);

                if (saveBeforeMessage) {
                    setSaveBeforeMessage(false);
                    setShowRosterSummary(true);
                }

                setLoading(false);
            });
    }, [dispatchUnits, rosters, rosterCandidates, accommodations, flights, startDate, endDate, showSaveConfirmation, saveBeforeMessage]);

    const handleSendConfirmation = useCallback((message, loadingCallback, setFailedMessagesCallback, templateId) => {
        if (loadingCallback)
            loadingCallback(true);
        else
            setLoading(true);

        if (!message || message === "") {
            setShowMessageConfirmation(false);
            setValidationAlert([{ key: "invalidMessage", type: "message", message: "Confirmation message cannot be empty." }])

            if (loadingCallback)
                loadingCallback(false);
            else
                setLoading(false);

            return;
        }

        // console.log(selectedRosterCandidates);

        const refType = "RosterCandidate";
        const flightIds = [];

        if (selectedMobeFlights?.length)
            selectedMobeFlights.forEach(m => m.flightIds?.forEach(i => flightIds.push(i)));

        if (selectedDemobeFlights?.length)
            selectedDemobeFlights.forEach(m => m.flightIds?.forEach(i => flightIds.push(i)));

        const accommodationIds = [];

        if (selectedAccommodations?.length)
            selectedAccommodations.forEach(m => m.rosterCandidateAccommodationIds?.forEach(i => accommodationIds.push(i)));

        // console.log({flightIds, selectedMobeFlights, selectedDemobeFlights});
        const body = {
            refType: refType,
            startDate: moment(selectedRosterCandidates[0].startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            endDate: moment(selectedRosterCandidates[0].endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            message: encodeURI(message),
            refIds: selectedRosterCandidates?.map(x => x.rosterCandidateId),
            rosterCandidateAccommodationIds: accommodationIds,
            flightIds: flightIds,
            messagesMasterId: templateId
        };

        // console.log(body);

        api.post("/rostercandidateaccommodation/accommodation-confirm", body)
            .then((response) => {
                if (response.data?.failed?.length > 0) {
                    setFailedMessages(response.data.failed);

                    if (setFailedMessagesCallback)
                        setFailedMessagesCallback(response.data.failed);

                    return;
                }

                setFailedMessages(null);
                setShowMessageConfirmation(false);
                onSaved();
            })
            .catch((error => {
                console.error({error});
                setValidationAlert([{ key: "system", type: "system", message: "System error" }]);
            }))
            .finally(() => {
                if (loadingCallback)
                    loadingCallback(false);
                else
                    setLoading(false);
            });
    }, [dispatchUnits, rosters, rosterCandidates, selectedRosterCandidates, selectedMobeFlights, selectedDemobeFlights, selectedAccommodations, onSaved]);

    return (
        <>
            {errorNotification?.length && errorNotification}
            <Modal
                isOpen={true}
                className="modal-xl"
                modalClassName="db-example-modal-xl"
            >
                {
                    (loading) && <Loader />
                }
                <div
                    className="modal-header"
                >
                    <Container fluid>
                        <Row>
                            <Col lg={9}>
                                <h5
                                    className="modal-title"
                                >
                                    Logistic{title}
                                </h5>
                            </Col>
                            <Col lg={3}>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    onClick={onClose}
                                    type="button"
                                >
                                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                                </button>
                            </Col>
                        </Row>
                        {
                            candidateTitle && candidateTitle != "" && candidateTitle.trim() != 'null' ?
                                <Row>
                                    <Col lg={12}>
                                        <h6
                                            className="modal-title"
                                        >
                                            {candidateTitle}
                                        </h6>
                                    </Col>
                                </Row>
                                :
                                <></>
                        }
                    </Container>
                </div>
                <div
                    className="modal-body"
                >
                    {
                        validationAlert && validationAlert.length > 0 &&
                        <Alert color="warning" className="pa-0 d-flex justify-content-between">
                            <span className="alert-text">
                                <ul className="mb-0">
                                    {
                                        validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                                    }
                                </ul>
                            </span>
                        </Alert>
                    }
                    {
                        (showSaveConfirmation || (saveBeforeMessage && selectedRows?.length)) &&
                        <SweetAlert
                            title="Confirmation"
                            warning
                            showCancel
                            cancelBtnText="No"
                            confirmBtnText="Yes"
                            confirmBtnBsStyle="danger"
                            onConfirm={() => handleSave()}
                            onCancel={() => {
                                if (showSaveConfirmation)
                                    setShowSaveConfirmation(false);

                                if (saveBeforeMessage)
                                    setSaveBeforeMessage(false);

                                setFailedMessages(null);
                            }}
                        >
                            The changes you have made will replace the old data. Continue?
                        </SweetAlert>
                    }
                    <Modal
                        isOpen={showRosterSummary || showFlightMobeConfirmation || showFlightDemobeConfirmation || showAccommodationConfirmation || showConfirmationSummary || (flightSearchRowId >= 0 && openFlightOptions)}
                        className="modal-xl"
                        modalClassName="db-example-modal-xl"
                    >
                        {
                            showRosterSummary &&
                            <CandidateConfirmationEditor
                                project={project}
                                dispatchUnits={dispatchUnits}
                                rosters={rosters}
                                rosterCandidates={rosterCandidates}
                                selected={selectedRosterCandidates}
                                confirmationMessage={confirmationMessage}
                                preSelectDataId={[...selectedRosterCandidates]}
                                onClose={() => {
                                    setSelectedRosterCandidates([]);
                                    setSelectedMobeFlights([]);
                                    setSelectedDemobeFlights([]);
                                    setSelectedAccommodations([]);
                                    setShowRosterSummary(false);
                                }}
                                singleSelection={true}
                                customConfirm={{
                                    callback: (data) => {
                                        setSelectedRosterCandidates(data);
                                        setShowRosterSummary(false);
                                        setShowFlightMobeConfirmation(true);
                                    },
                                    confirmButtonTitle: "Next"
                                }}
                            />
                        }
                        {
                            showFlightMobeConfirmation && selectedRosterCandidates?.length &&
                            <LogisticFlightConfirmEditor
                                rosterCandidates={selectedRosterCandidates}
                                selected={selectedMobeFlights}
                                flightType="M"
                                title={title}
                                candidateTitle={candidateTitle}
                                preSelectDataId={[...selectedMobeFlights]}
                                onClose={() => setShowFlightMobeConfirmation(false)}
                                customCallback={{
                                    onCancel: () => setShowFlightMobeConfirmation(false),
                                    onReturn: () => {
                                        //setSelectedMobeFlights([]);
                                        setShowFlightMobeConfirmation(false);
                                        setShowRosterSummary(true);
                                    },
                                    onContinue: (data) => {
                                        // console.log(data);
                                        setSelectedMobeFlights(data);
                                        setShowFlightMobeConfirmation(false);
                                        setShowFlightDemobeConfirmation(true);
                                    }
                                }}
                            />
                        }
                        {
                            showFlightDemobeConfirmation &&
                            <LogisticFlightConfirmEditor
                                rosterCandidates={selectedRosterCandidates}
                                selected={selectedDemobeFlights}
                                flightType="D"
                                title={title}
                                candidateTitle={candidateTitle}
                                onClose={() => setShowFlightDemobeConfirmation(false)}
                                preSelectDataId={[...selectedDemobeFlights]}
                                customCallback={{
                                    onCancel: () => setShowFlightDemobeConfirmation(false),
                                    onReturn: () => {
                                        //setSelectedDemobeFlights([]);
                                        setShowFlightDemobeConfirmation(false);
                                        setShowFlightMobeConfirmation(true);
                                    },
                                    onContinue: (data) => {
                                        setSelectedDemobeFlights(data);
                                        setShowFlightDemobeConfirmation(false);
                                        setShowAccommodationConfirmation(true);
                                    }
                                }}
                            />
                        }
                        {
                            showAccommodationConfirmation &&
                            <LogisticAccommodationConfirmEditor
                                rosterCandidates={selectedRosterCandidates}
                                selected={selectedAccommodations}
                                title={title}
                                candidateTitle={candidateTitle}
                                preSelectDataId={[...selectedAccommodations]}
                                onClose={() => setShowAccommodationConfirmation(false)}
                                customCallback={{
                                    onCancel: () => setShowAccommodationConfirmation(false),
                                    onReturn: () => {
                                        //setSelectedAccommodations([]);
                                        setShowAccommodationConfirmation(false);
                                        setShowFlightDemobeConfirmation(true);
                                    },
                                    onContinue: (data) => {
                                        setSelectedAccommodations(data);
                                        setShowAccommodationConfirmation(false);
                                        setShowConfirmationSummary(true);
                                    }
                                }}
                            />
                        }
                        {
                            showConfirmationSummary &&
                            <LogisticSummaryConfirmEditor
                                rosterCandidates={selectedRosterCandidates}
                                title={title}
                                candidateTitle={candidateTitle}
                                mobeFlights={selectedMobeFlights}
                                demobeFlights={selectedDemobeFlights}
                                accommodations={selectedAccommodations}
                                onClose={() => setShowConfirmationSummary(false)}
                                customCallback={{
                                    onCancel: () => setShowConfirmationSummary(false),
                                    onReturn: () => {
                                        setShowConfirmationSummary(false);
                                        setShowAccommodationConfirmation(true);
                                    },
                                    onContinue: () => {
                                        setShowConfirmationSummary(false);
                                        setShowMessageConfirmation(true);
                                    }
                                }}
                            />
                        }
                        {
                            flightSearchRowId >= 0 && openFlightOptions &&
                            <FlightSearchEditor
                                data={selectedRowData}
                                onClose={() => setOpenFlightOptions(false)}
                                onSaved={(flight) => {
                                    setOpenFlightOptions(false);
                                    const newFlights = [...flights];
                                    const editedRow = {
                                        ...newFlights[flightSearchRowId],
                                        ...flight,
                                        flightFromName: flight.flightFrom?.airportName,
                                        flightToName: flight.flightTo?.airportName,
                                        airportTerminalName: flight.airportTerminal?.terminalName,
                                        arrivalTerminalName: flight.arrivalTerminal?.terminalName,
                                        flightDate: moment(flight.flightDateTime, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                                        flightTime: moment(flight.flightDateTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
                                        arrivalDate: moment(flight.arrivalTime, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY"),
                                        arrivalTime: moment(flight.arrivalTime, "YYYY-MM-DDTHH:mm").format("HH:mm"),
                                    };
                                    newFlights.splice(flightSearchRowId, 1, editedRow);
                                    setFlights([...newFlights]);
                                }}
                            />
                        }
                    </Modal>
                    {
                        confirmationMessage && showMessageConfirmation &&
                        <EditorSMS
                            items={selectedRosterCandidates}
                            onClose={() => {
                                setShowMessageConfirmation(false);
                                if (failedMessages?.length > 0)
                                    setFailedMessages(null);
                                onClose();
                            }}
                            onCustomSave={(message, loadingCallback, setFailedMessagesCallback, templateId) => handleSendConfirmation(message, loadingCallback, setFailedMessagesCallback, templateId)}
                            lookups={{
                                ...props.lookups
                            }}
                            defaultMessage={confirmationMessage}
                            sourcePage="roster"
                        />
                    }
                    <Row>
                        <Col lg={12}>
                            <Nav
                                tabs
                            // role="tablist"
                            >
                                <NavItem className="p-0 text-center">
                                    <NavLink
                                        className={activeTab.key === 2 ? "active" : ""}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedDispatchUnitId(null);
                                            setSelectedRosterId(null);
                                            setActiveTab(tabs[1]);
                                        }}
                                    >
                                        {tabs[1].title}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="p-0 text-center">
                                    <NavLink
                                        className={activeTab.key === 1 ? "active" : ""}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedDispatchUnitId(null);
                                            setSelectedRosterId(null);
                                            setActiveTab(tabs[0]);
                                        }}
                                    >
                                        {tabs[0].title}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card className="card-plain no-transition">
                                <TabContent className="mt-2" activeTab={activeTab.key}>
                                    <TabPane tabId={tabs[0].key}>
                                        <Row className="mb-2">
                                            <Col lg={12}>
                                                <TooltipButton
                                                    id="addaccommodationrow"
                                                    title="Add Row"
                                                    className="btn-icon"
                                                    type="button"
                                                    size="sm"
                                                    onClick={() => {
                                                        addAccommodationRow((accommodations?.length ?? 0));
                                                    }}
                                                >
                                                    <i className="fas fa-plus"></i>
                                                </TooltipButton>
                                                <TooltipButton
                                                    id="removeaccommodationrow"
                                                    title="Remove Row"
                                                    className="btn-icon"
                                                    type="button"
                                                    size="sm"
                                                    onClick={() => {
                                                        removeAccommodationRow();
                                                    }}
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </TooltipButton>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} style={{ overflowX: "scroll" }}>
                                                {
                                                    accommodationTable
                                                }
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId={tabs[1].key}>
                                        <Row className="mb-2">
                                            <Col lg={12}>
                                                <TooltipButton
                                                    id="addflightrow"
                                                    title="Add Row"
                                                    className="btn-icon"
                                                    type="button"
                                                    size="sm"
                                                    onClick={() => {
                                                        addFlightRow((flights?.length ?? 0) + 1);
                                                    }}
                                                >
                                                    <i className="fas fa-plus"></i>
                                                </TooltipButton>
                                                <TooltipButton
                                                    id="removeflightrow"
                                                    title="Remove Row"
                                                    className="btn-icon"
                                                    type="button"
                                                    size="sm"
                                                    onClick={() => {
                                                        removeFlightRow();
                                                    }}
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </TooltipButton>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} style={{ overflowX: "scroll" }}>
                                                {
                                                    flightTable
                                                }
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={() => {
                            if (saved)
                                onSaved();

                            onClose();
                        }}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button
                        color="info"
                        onClick={() => setShowSaveConfirmation(true)}
                        type="button"
                    >
                        Save Changes
                    </Button>
                    <Button
                        color="success"
                        onClick={() => {
                            setSaveBeforeMessage(true);
                        }}
                        type="button"
                    >
                        Send Confirmation Message
                    </Button>
                </div>
            </Modal>
        </>
    );
}
